import React, { FC } from 'react';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared';
import { AptunoButton } from '../Buttons';
import {
  FileUploaderViewerContainer,
  FileUploaderViewerDescription,
  FileUploaderViewerImagePreview,
  FileUploaderViewerImagePreviewContainer,
  FileUploaderViewerPreview,
  FileUploaderViewerPreviewButton,
} from './FileUploaderViewer.styled';

type ImagePreviewProps = {
  align: string;
};

type FileUploaderViewerProps = {
  files: File[];
  onFileRemoved: (files: File[], removed: File) => void;
  imagePreview?: boolean;
  imagePreviewProps?: ImagePreviewProps;
};

const FileUploaderViewer: FC<FileUploaderViewerProps> = ({
  files,
  onFileRemoved,
  imagePreview,
  imagePreviewProps,
}: FileUploaderViewerProps) => {
  const onFileSeletedToRemove = (index: number) => {
    const remainingFiles = files.filter((_, idx) => idx !== index);
    const removedFile = files[index];
    onFileRemoved(remainingFiles, removedFile);
  };

  const createImageUrl = (file: File): string => URL.createObjectURL(file);

  return (
    <FileUploaderViewerContainer align={imagePreviewProps?.align}>
      {files.map((file, idx) =>
        imagePreview ? (
          <FileUploaderViewerImagePreviewContainer key={`image-preview-${idx}`} width={80} height={80}>
            <FileUploaderViewerImagePreview src={createImageUrl(file)} />
            <FileUploaderViewerPreviewButton>
              <AptunoButton
                size={SIZES.MEDIUM}
                appearance={VARIANTS.SECONDARY}
                onClick={() => onFileSeletedToRemove(idx)}
                isCircular
              >
                <Icon icon="closeAlt" />
              </AptunoButton>
            </FileUploaderViewerPreviewButton>
          </FileUploaderViewerImagePreviewContainer>
        ) : (
          <FileUploaderViewerPreview key={idx}>
            <Icon icon="document" />
            <FileUploaderViewerDescription>{file.name}</FileUploaderViewerDescription>
            <FileUploaderViewerPreviewButton>
              <AptunoButton
                size={SIZES.MEDIUM}
                appearance={VARIANTS.SECONDARY}
                onClick={() => onFileSeletedToRemove(idx)}
                isCircular
              >
                <Icon icon="closeAlt" />
              </AptunoButton>
            </FileUploaderViewerPreviewButton>
          </FileUploaderViewerPreview>
        )
      )}
    </FileUploaderViewerContainer>
  );
};

export { FileUploaderViewer, ImagePreviewProps };
