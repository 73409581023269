import { hex2rgba } from '../../shared/utils';
import styled from 'styled-components';

type CarouselWrapperProps = {
  padding?: string;
  margin?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const CarouselWrapper = styled.div<CarouselWrapperProps>`
  position: relative;
  padding: ${props => props.padding || '0px 0px'};
  margin: ${props => props.margin || '0px 0px'};
  max-width: 100%;
  display: flex;
  align-items: center;
  height: 100%;

  button {
    display: none;
  }
  &:hover button {
    display: flex;
  }
`;

type CarouselTrackProps = {
  numberOfCols: number;
  colWidth: number;
  gap: number;
  maxheight?: number;
} & React.HTMLAttributes<HTMLOListElement>;

const CarouselTrack = styled.ol<CarouselTrackProps>`
  display: grid;
  gap: ${props => `${props.gap}px`};
  grid-template-columns: repeat(${props => props.numberOfCols}, ${props => props.colWidth}px);
  grid-template-rows: auto;
  overflow-x: auto;
  overflow-y: hidden;

  counter-reset: item;
  scroll-snap-type: x mandatory;
  overscroll-behavior: contain auto;
  scroll-behavior: smooth;
  list-style-type: none;
  scroll-snap-stop: always;

  padding: 0;
  margin: 0;

  -webkit-overflow-scrolling: touch;

  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  & {
    -ms-overflow-style: none;
  }
  ${props => props.maxheight && `
    max-height: ${props.maxheight}px;

    & img {
      width: auto;
      height: 100%;
    }
  `}
`;

const CarouselItem = styled.li`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
`;

type CounterBadgeProps = {
  alignment: string;
} & React.HTMLAttributes<HTMLDivElement>;
const CounterBadge = styled.div`
  position: absolute;
  width: 3.125rem;
  height: 1.5rem;
  right: 1rem;
  bottom: 2rem;
  background-color: ${props => hex2rgba(props.theme.colors.bgCarouselBadge, 0.64)} !important;
  color: ${props => props.theme.colors.textPrimaryBtn} !important;
  padding: .25rem 1rem;
  border-radius: .5rem;
  font-size:${props => props.theme.typography.size.sm1}px;
  line-height: ${props => props.theme.typography.size.sm3}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { CarouselWrapper, CarouselTrack, CarouselItem, CounterBadge };
