import styled from 'styled-components';

export type CardMiniOptions = {
  refQty?: number;
  refIcon?: string;
  refFeature?: string;
  appearance?: string;
  orientation?: string;
  useCase?: string;
};

enum ORIENTATIONS {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

enum USECASES {
  LANDLORD = 'landlord',
  B2B = 'b2b',
}

const handleContainerWidth = (useCase: string) => {
  switch (useCase) {
    case USECASES.B2B:
      return '104px';
    case USECASES.LANDLORD:
    default:
      return '72px';
  }
};

export const Container = styled.div<CardMiniOptions>`
  height: 120px;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  text-align-last: center;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  background: ${props => props.theme.colors.bgSecondaryBtn};
  transition: all ease 0.5s;

  ${props =>
    props.orientation === ORIENTATIONS.VERTICAL &&
    `
    width: ${handleContainerWidth(props.useCase)};
    height: 120px !important;
    flex-direction: column !important;

  `};

  ${props =>
    props.orientation === ORIENTATIONS.HORIZONTAL &&
    `
    width: fit-content;
    align-items: center;
    height: 72px;
    display: flex;
    gap: 8px;
    border-radius: 16px;
    flex-direction: row;
    padding: 16px;
  `};

  @media screen and (min-width: 600px) {
    ${props =>
      props.orientation === ORIENTATIONS.HORIZONTAL &&
      `
      width: fit-content;
      align-items: center;
      height: 72px;
      display: flex;
      gap: 8px;
      border-radius: 16px;
      flex-direction: row;
      padding: 16px;
    `};
  }
`;

export const Item = styled.div``;

export const ContainerIcon = styled.div<CardMiniOptions>`
  margin: ${props => (props.useCase === USECASES.B2B ? '16px 32px 4px 32px' : '16px 0 0 16px')};

  ${props =>
    props.orientation === ORIENTATIONS.HORIZONTAL &&
    `
    margin: 0px !important;
    padding-top: 0 !important;
  `}
`;

export const Quantity = styled.div<{ isDisabled?: boolean }>`
  height: 24px;
  margin: auto;
  font-weight: 900;
  line-height: 24px;
  font-style: normal;
  width: fit-content;
  letter-spacing: -0.008em;
  color: ${props => (props.isDisabled ? props.theme.colors.textDisable : props.theme.colors.textSecondaryBtn)};
  font-size: ${props => props.theme.typography.size.sm4}px;

  @media screen and (min-width: 600px) {
    margin-top: 0;
  }
`;

export const Feature = styled.div<CardMiniOptions>`
  height: 20px;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  line-height: ${props => props.theme.typography.lineHeight.sm5}px;
  margin: auto;
  color: ${props => props.theme.colors.textSecondaryTab};
  font-size: ${props => props.theme.typography.size.sm2}px;
  margin-right: auto;

  ${props =>
    props.orientation === ORIENTATIONS.VERTICAL &&
    `
    width: auto;
    margin-right: 0 !important;
  `}

  ${props => props.appearance === 'disabled' && `color: #9B9B9B`};

  @media screen and (min-width: 600px) {
    height: 24px;
    font-size: ${props => props.theme.typography.size.sm3}px;
    line-height: ${props => props.theme.typography.lineHeight.md2}px;
    margin-right: 24px;
  }
`;
