import React from 'react';
import { MainItemCardStyle, BadgetWrapper, Badget, MainTitle, MainDescription } from './ItemCard.styled';

const MainItemCard = ({ badgeLeft, badgetRight, bgtColor, bgtTextColor, title, description, onClick }) => {
  return (
    <MainItemCardStyle onClick={onClick}>
      <BadgetWrapper>
        <Badget>{badgeLeft}</Badget>
        <Badget color={bgtColor} textColor={bgtTextColor}>
          {badgetRight}
        </Badget>
      </BadgetWrapper>
      <MainTitle>{title}</MainTitle>
      <MainDescription>{description}</MainDescription>
    </MainItemCardStyle>
  );
};

export { MainItemCard };
