import styled from 'styled-components';

const EmptyCardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export { EmptyCardWrapper };
