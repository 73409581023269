import React from "react";

import { Icon } from "../../../Icon";
import { Card } from "./MenuCard.styled";
import { IconContainer } from "../CardsCommon.styled";

const MenuCard = (
  { onClick, icon, iconColor, iconBackground, title, description, primary },
  ...rest
) => {
  return (
    <Card onClick={onClick} primary={primary}>
      <IconContainer color={iconColor} backgroundColor={iconBackground}>
        <Icon icon={icon} />
      </IconContainer>
      <aside>
        <h6>{title}</h6>
        <p>{description}</p>
      </aside>
    </Card>
  );
};

export { MenuCard };
