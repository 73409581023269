import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const MODAL_MAX_WIDTH_SMALL = 400;
export const MODAL_MAX_WIDTH_MEDIUM = 600;
export const MODAL_MAX_WIDTH_LARGE = 800;

export const modalSlide = keyframes`
  from {
    transform: translateY(150%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const modalSlideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(150%);
    opacity: 0;
  }
`;

type ModalWrapperProps = {
  isActive: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  animation: ${modalSlide} 0.3s ease-out forwards;
  transform: translateY(50%);
  + .content {
    animation: slideDownLarge 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: #0000008f;
  }
`;

const modalBottom = css`
  width: 100%;
  border-radius: 24px;
  height: fit-content;
  margin-bottom: 0px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  bottom: 0;
`;

// todo: add correct types
const ModalContent = styled.div<any>`
  transition: top 0.3s 0.2s;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  margin: auto;
  border-radius: 0px;
  transition: top 3s 2s;
  overflow: auto;

  ${({ isModalBottom }) => (isModalBottom ? modalBottom : null)}

  @media screen and (min-width: 768px) {
    position: relative;
    ${({ size }) => (size ? `width:${size}px` : null)};
    ${({ width }) => (width ? `width:${width}` : null)};
    height: ${({ height }) => (height ? height : 'fit-content')};
    background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
    border-radius: 24px;
    margin: auto;
    ${({ isModalBottom }) => (isModalBottom ? modalBottom : null)}
  }
`;

// todo: add correct types
const ModalHead = styled.div<any>`
  position: relative;
  box-shadow: ${({ withoutShadow }) => (withoutShadow ? 'none' : '0px 10px 25px #6c757d0f')};
  padding: 15px;
  text-align: center;
  font-weight: bold;
  padding: 16px;
  min-height: 54px;
  border-radius: 24px;
  ${({ isModalBottom }) => (isModalBottom ? `padding-top: 0px` : null)}
`;

const ModalBody = styled.div`
  padding: 16px;
  height: fit-content;
`;

const ModalFooter = styled.div`
  display: flex;
  padding: 16px;
`;

const CloseModal = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
  width: 18px;
  height: 18px;
  opacity: 0.5;
  cursor: pointer;

  :before,
  :after {
    position: absolute;
    left: 8px;
    top: 0px;
    content: ' ';
    height: 18px;
    width: 2.8px;
    background-color: #9b9b9b;
    border-radius: 4px;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
  :hover {
    opacity: 1;
  }
`;

export { ModalWrapper, ModalContent, ModalHead, ModalFooter, CloseModal, ModalBody };
