import styled from 'styled-components';

type SidebarWrapperProps = {
  open: boolean;
}
const SidebarWrapper = styled.div<SidebarWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  max-width: 270px;
  width: 80%;
  left: 0;
  background: ${({ theme }) => theme.colors.bgSideBarPrimary};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-120%)')};
  color: #fff;
  max-width: 18.25rem;
  position: fixed;
  z-index: 99;
  padding-bottom: 30px;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  outline: none;
  top: 0;
  padding: 0;
`;

const SidebarMenu = styled.ul`
  display: flex;
  align-items: left;
  flex-direction: column;
  list-style: none;
  width: 100%;
  overflow: auto;
  outline: none;
  padding: 0 1rem;
  color: black;
`;

const SidebarMenuItem = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  outline: none;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
  font-size: ${({ theme }) => theme.typography.size.sm4}px;
  font-weight: ${({ theme }) => theme.typography.weight.bold};

  & p:hover,
  a:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textNavbarPrimaryActive};
    border-radius: 0.267rem;
    box-shadow: none;
    text-decoration: underline;
  }
`;

const ActionsWrapper = styled.div`
  min-height: 100px;
  padding: 1.25rem 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SidebarBrandWrapper = styled.div`
  width: 100%;
  padding: 1rem;
`;

export {
  SidebarBrandWrapper,
  SidebarWrapper,
  ActionsWrapper,
  SidebarMenu,
  SidebarMenuItem
}