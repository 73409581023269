import React, { useState } from 'react';
import { ItemTab } from '../ItemTab/ItemTab';
import { TabSize, TabType } from '../Shared/types';
import { Container, DetailsTabOptions } from './DetailsTab.styled';

const DetailsTab = ({
  activeTabId,
  items,
  onClickFn,
  tabType = TabType.SEPARATE,
  tabSize = TabSize.NORMAL,
}: DetailsTabOptions) => {
  const [currentTab, setCurrentTab] = useState(activeTabId || '');

  return (
    <Container activeTabId={activeTabId} items={items} tabType={tabType} tabSize={tabSize}>
      {items.map(tab => {
        return (
          <ItemTab
            key={`${tab.id}_tab`}
            active={currentTab == tab.id ? true : false}
            onClicked={() => {
              setCurrentTab(tab.id);
              onClickFn(tab);
            }}
            tabType={tabType}
            tabSize={tabSize}
          >
            {tab.text}
          </ItemTab>
        );
      })}
    </Container>
  );
};

export { DetailsTab };
