import React from 'react';
import Lottie from 'react-lottie';
import error404 from '../../lotties/error404.json';
import { Container, Title, Description, LinkRecommendation, Button, Animation } from './Error404.styled';
import { LinkRelevantError404 } from './Error404.data';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: error404,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'lotties',
  },
};

function Error404({ countryCode }) {
  const goToHome = () => {
    if (typeof window !== 'undefined') window.location.href = '/';
  };
  const OpenLink = destiny => (window.location.href = destiny);

  return (
    <Container>
      <Animation>
        <Lottie options={defaultOptions} height={324} width={324} speed={0.86} />
      </Animation>
      <Title>No encontramos esa página...</Title>
      <Description>Sin embargo, te invitamos a revisar otras opciones que pueden ser útiles:</Description>
      <LinkRecommendation>
        {LinkRelevantError404(countryCode).map((element, index) => {
          return (
            <li key={`link_error_page_${index}`} onClick={() => OpenLink(element.href)}>
              {element.title}
            </li>
          );
        })}
      </LinkRecommendation>
      <Button onClick={goToHome}>Volver a la página principal</Button>
    </Container>
  );
}

export { Error404 };
