import styled, { keyframes } from 'styled-components';

//STEPPER DEFAULT
const progress = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

const StepperWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StepIndicator = styled.div`
  max-width: 20%;
  justify-content: space-around;
`;

const PercentLabel = styled.text`
  font-size: 12px;
`;

const StepLabel = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0px 16px;
`;

const StepTitle = styled.h2`
  margin: 0px;
  font-family: 'Satoshi';
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.18px;
  color: #000000;
`;

const StepDescription = styled.h3`
  margin: 0px;
  font-family: 'Satoshi';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.18px;
  line-height: 12px;
  color: #9b9b9b;
`;

const CircularChart = styled.svg`
  display: block;
  margin: 5px auto;
  max-width: 80%;
  max-height: 45px;
  width: 80px;
`;

type CircleProps = {
  percent: number;
  color: string;
};

const Circle = styled.path<CircleProps>`
  stroke: ${props => (props.color ? props.color : '#4CC790')};
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke-dasharray: ${props => (props.percent ? props.percent + ' 100' : '0')};
  animation: ${progress} 1s ease-out forwards;
`;

const CircleBg = styled.path`
  stroke: #dee2e6;
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke-dasharray: 100;
`;

// STEPPER 40PX_40PX
const CircularChart4040 = styled.svg`
  display: block;
  width: 40px;
  height: 40px;
`;

const StepTitle4040 = styled.div`
  height: 24px;
  left: 72px;
  top: 16px;
  font-family: 'Satoshi';
  font-weight: 700;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #000000;
`;

const StepDescription4040 = styled.div`
  font-weight: 400;
  height: 20px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  transition: all 0.3s ease;
  width: max-content;

  @media screen and (max-width: 300px) {
    font-size: 10px;
  }
`;

export {
  StepperWrapper,
  StepLabel,
  StepIndicator,
  PercentLabel,
  StepTitle,
  StepDescription,
  CircularChart,
  Circle,
  CircleBg,
  CircularChart4040,
  StepTitle4040,
  StepDescription4040,
};
