import React from 'react';
import {
  CarouselMobileContainer,
  FifthImage,
  FourthImage,
  GalleryWrapper,
  MainImage,
  SecondImage,
  ThirdImage,
} from './GalleryImages.styled';
import { Fab, HorizontalPosition, VerticalPosition } from '../../components/Fab/Fab';
import { Icon } from '../../Icon';
import { VARIANTS } from '../../shared/types';
import { useResponsive } from '../../hooks/useResponsive';
import { Carousel } from '../Carousel/Carousel';

export type GalleryImagesProps = {
  children: React.ReactNode;
  marginMobile?: string;
  disabled?: boolean;
  onExpand: () => void;
};

function GalleryImages({ disabled = false, onExpand, children, ...props }: GalleryImagesProps) {
  const { isMobile } = useResponsive();
  const childrenArray = children ? React.Children.toArray(children): [];

  return (
    <>
      {!isMobile && (
        <GalleryWrapper disabled={disabled} {...props}>
          {children && React.Children.count(children) >= 5 && (
            <>
              <MainImage key={'gallery-image-desktop-1'}>{childrenArray[0]}</MainImage>
              <SecondImage key={'gallery-image-desktop-2'}>{childrenArray[1]}</SecondImage>
              <ThirdImage key={'gallery-image-desktop-3'}>{childrenArray[2]}</ThirdImage>
              <FourthImage key={'gallery-image-desktop-4'}>{childrenArray[3]}</FourthImage>
              <FifthImage key={'gallery-image-desktop-5'}>{childrenArray[4]}</FifthImage>
              <Fab
                appearance={VARIANTS.SECONDARY}
                isTransparentDark
                withoutBorder
                fitHeight
                fitWidth
                onClick={() => onExpand()}
                horizontalPosition={HorizontalPosition.veryRight}
                verticalPosition={VerticalPosition.veryBottom}
              >
                Expandir
                <Icon icon="expand" />
              </Fab>
            </>
          )}
        </GalleryWrapper>
      )}
      {isMobile && (
        <CarouselMobileContainer margin={props.marginMobile} disabled={disabled}>
          <Carousel isOpen={true} slidesToShow={1} gap={0} maxHeight={240}>
            {React.Children.map(children, (childItem, index) => {
              if (!React.isValidElement(childItem)) {
                return;
              }
              const itemId = `gallery-image-mobile-${index}`;
              return <React.Fragment key={itemId}>{childItem}</React.Fragment>;
            })}
          </Carousel>
        </CarouselMobileContainer>
      )}
    </>
  );
}

export { GalleryImages };
