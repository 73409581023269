import styled, { css } from 'styled-components';
import { Size } from './EstateCard';

type WithSizeProps = {
  size: Size;
} & React.HTMLAttributes<HTMLDivElement>;

type EstateCardPropsWrapper = {
  disabled?: boolean;
} & WithSizeProps &
  React.HTMLAttributes<HTMLDivElement>;

const EstateCardWrapper = styled.div<EstateCardPropsWrapper>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border: none;
  justify-content: flex-start;
  font-size: 5em;
  background: ${props => props.theme.colors.bgCardDefault};
  border-radius: 16px;
  color: ${props => props.color};
  min-width: ${props => (props.size === Size.COMPACT ? '288px' : '288px')};
  max-width: ${props => (props.size === Size.COMPACT ? '288px' : '400px')};
  min-height: ${props => (props.size === Size.COMPACT ? '281px' : '370px')};
  max-height: ${props => (props.size === Size.COMPACT ? '281px' : '500px')};
  cursor: pointer;
  border-color: ${props => props.theme.colors.borderBrand};
  overflow: hidden;
  box-shadow: 0px 15px 9px rgba(0, 0, 0, 0.01), 0px 7px 7px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02),
    0px 0px 0px rgba(0, 0, 0, 0.02);

  :hover {
    box-shadow: ${props => props.theme.shadows['1-drop']};
    transition: 0.25s ease;
  }

  ${props =>
    props.disabled &&
    css`
      ${Title} {
        color: ${props => props.theme.colors.textDisabled};
      }
    `}
`;

const CardContent = styled.div<WithSizeProps>`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 16px 16px;
  width: 100%;
  min-height: ${props => (props.size === Size.COMPACT ? '130px' : '149px')};
  margin-top: 0px;
  position: relative;
`;

const Title = styled.h2<WithSizeProps>`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: ${props => props.theme.colors.textSecondary};
  text-align: left;
  width: 100%;
  margin-top: 16px;
  margin-bottom: ${props => (props.size === Size.COMPACT ? '2px' : '20px')};
`;

const CharacteristicsSection = styled.div<WithSizeProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
`;

const CharacteristicsCol = styled.div<WithSizeProps>`
  display: flex;
  flex-direction: ${props => (props.size === Size.COMPACT ? 'row' : 'column')};
  gap: ${props => (props.size === Size.COMPACT ? '10px' : 'none')};
`;

const Characteristic = styled.div<WithSizeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => (props.size === Size.COMPACT ? '5px' : '16px')};
  flex-wrap: nowrap;
  font-size: ${props => props.theme.typography.size.sm1}px;
  line-height: ${props => props.theme.typography.lineHeight.lg1}px;
  color: ${props => props.theme.colors.textSecondaryCard};
  background-color: ${props => (props.size === Size.COMPACT ? '#F8F8F8  ' : 'none')};
  padding: ${props => (props.size === Size.COMPACT ? '8px' : '0px')};
`;

const PriceSection = styled.div<WithSizeProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${props => props.theme.colors.bgBrand};
  font-size: ${props => props.theme.typography.size.sm1}px;
  margin-top: ${props => (props.size === Size.COMPACT ? '2px' : '16px')};
  align-items: center;
`;

const Price = styled.h3`
  font-size: ${props => props.theme.typography.size.sm4}px;
  line-height: ${props => props.theme.typography.lineHeight.lg1}px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-right: 10px;
  font-weight: ${props => props.theme.typography.weight.black};
`;

const CardImageBox = styled.div<WithSizeProps>`
  display: block;
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;

  & img:hover {
    transition: all 400ms ease-in;
    transform: scale(1.08);
    cursor: crosshair;
  }
`;

const SingleImg = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: auto;
    height: 100%;
  }
`;

export {
  EstateCardWrapper,
  CardContent,
  CharacteristicsSection,
  Title,
  CharacteristicsCol,
  Characteristic,
  PriceSection,
  Price,
  CardImageBox,
  SingleImg,
};
