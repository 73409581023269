import styled from 'styled-components';

export type BubbleInformativeProps = {
  backgroundColor?: string;
  textColor?: string;
  textFontFamily?: string;
  textFontSize?: number;
  alignArrow?: 'left' | 'right';
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = styled.div`
  margin: auto;
  position: absolute;
  z-index: 100;
`;

export const Bubble = styled.div<BubbleInformativeProps>`
  height: 72px;
  width: 248px;
  min-width: 200px;
  background: ${props => props.backgroundColor || '#000000'};
  opacity: 0.88;
  border-radius: 8px;
  display: block;
  color: ${props => props.textColor || 'white'};
  padding: 16px;
  font-weight: 600;
  font-size: ${props => props.textFontSize || '14px'};
  line-height: 20px;
  text-align: left;
`;

export const Pointer = styled.div<BubbleInformativeProps>`
  width: 0;
  height: 0;
  border-bottom: 18px solid ${props => props.backgroundColor || '#000000'};
  border-left: 22px solid transparent;
  opacity: 0.88;
  margin-left: 137px;
`;
