import styled, { keyframes } from 'styled-components';

export type StepperSimpleProps = {
  refIcon?: string;
  steps?;
  activeStep?;
  appearance?: string;
  percent?;
  linearColorPrimary?: string;
  linearColorSecondary?: string;
};

enum APPEARANCES {
  primary = 'primary',
  secondary = 'secondary',
  purple = 'purple',
  orange = 'orange',
  linear = 'linear',
}

const progress = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

export const Svg = styled.svg`
  display: 'inline-block';
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;

export const Path = styled.path<StepperSimpleProps>`
  ${props => props.appearance === APPEARANCES.linear && `fill: url(#gradient)`};
  ${props => props.appearance === APPEARANCES.purple && `fill: currentColor`};
  ${props => props.appearance === APPEARANCES.orange && `fill: currentColor`};
`;

const StepperWrapper = styled.div`
  width: min-content;
`;

const CircularChart = styled.svg`
  display: block;
  height: 64px;
`;

const Circle = styled.path<StepperSimpleProps>`
  ${props => props.appearance === APPEARANCES.purple && `stroke: ${props?.theme?.colors?.bgStepperSimpleFilling}`};
  ${props => props.appearance === APPEARANCES.orange && `stroke: ${props?.theme?.colors?.bgStepperSimpleFilled}`};
  ${props => props.appearance === APPEARANCES.linear && `stroke: url(#gradient)`};
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke-dasharray: ${props => (props.percent ? props.percent + ' 100' : '0')};
  animation: ${progress} 1s ease-out forwards;
`;

const CircleBg = styled.path`
  stroke: ${props => props?.theme?.colors?.bgCircleStepperSimple};
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  stroke-dasharray: 100;
`;

export const ContainerIcon = styled.div<StepperSimpleProps>`
  position: absolute;
  svg {
    position: absolute;
    width: 26px;
    height: auto;
    left: 19px;
    top: 19px;
    ${props => props.appearance === APPEARANCES.purple && `color: ${props?.theme?.colors?.bgStepperSimpleFilling}`}
    ${props => props.appearance === APPEARANCES.orange && `color: ${props?.theme?.colors?.bgStepperSimpleFilling}`};
    ${props => props.appearance === APPEARANCES.linear && `color: ${props?.theme?.colors?.bgStepperSimpleAux}`}
  }
`;

export { StepperWrapper, CircularChart, Circle, CircleBg };
