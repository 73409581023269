import { AptunoButton } from './../Buttons';
import styled from 'styled-components';

export const FileUploaderButtonRoot = styled(AptunoButton)``;

export const FileUploaderInputFileField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  height: auto;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: none;

  &:focus {
    outline: none;
  }
`;
