import styled from 'styled-components';

export type HeaderOptions = {
  onButtonBack?: () => void;
  maxWidth?: string;
  height?: string;
  children: React.ReactNode;
  isFixed?: boolean;
  withBorderRadius?: boolean;
};

export const Container = styled.div<HeaderOptions>`
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: ${({ height }) => (height ? height : '56px')};
  box-shadow: 0px 10px 25px rgba(108, 117, 125, 0.059);
  background: ${props => props.theme.colors.bgSecondaryBtn};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  width: 100%;
  justify-content: center;

  ${props =>
    props.isFixed &&
    `
    position: fixed;
    top: 0;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    max-width:100% !important;
    width: 100% !important;
  `};

  @media screen and (min-width: 600px) {
    ${props => props.withBorderRadius && `border-radius: 0px 0px 16px 16px;`}
    box-shadow: 0 0 0 0;
  }
`;

export const LeftButtons = styled.div`
  cursor: pointer;
  margin: 19px 10px 22px 18px;

  svg {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    color: ${props => props.theme.colors.textDisabledBtn};

    @media screen and (min-width: 600px) {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  left: 40px;
  max-width: 992px !important;
  height: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  line-height: 20px;
  font-style: normal;
  transition: 0.3s all ease;
  color: ${props => props.theme.colors.textSecondaryBtn};
  font-size: ${props => props.theme.typography.size.sm2}px;
  font-weight: ${props => props.theme.typography.weight.regular};
  justify-content: space-between;

  @media screen and (min-width: 600px) {
    font-size: ${props => props.theme.typography.size.sm3}px;
  }
  @media (min-width: 1800px) {
    max-width: 1120px !important;
  }
`;
