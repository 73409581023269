import React from 'react';
import { Content, CopyOutputTextProps, TextToCopy } from './CopyOutputText.styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '../../../Icon';
import toast, { Toaster } from 'react-hot-toast';

const CopyOutputText = ({
  textToCopy,
  heightContainer,
  widthContainer,
  borderRadiusContainer,
  backgroundContainer,
  borderContainer,
  onClickCustom,
}: CopyOutputTextProps) => {
  const handleCopy = () => {
    onClickCustom && onClickCustom();
    toast.success('Copiado!', {
      position: 'bottom-center',
    });
  };

  return (
    <CopyToClipboard text={textToCopy} onCopy={() => handleCopy()}>
      <Content
        heightContainer={heightContainer}
        widthContainer={widthContainer}
        borderRadiusContainer={borderRadiusContainer}
        backgroundContainer={backgroundContainer}
        borderContainer={borderContainer}
      >
        <Toaster />
        <TextToCopy>{textToCopy}</TextToCopy>
        <Icon width={'24px'} height={'24px'} icon="copy2" color="#D250E6" />
      </Content>
    </CopyToClipboard>
  );
};

export { CopyOutputText };
