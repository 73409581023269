import styled from 'styled-components';
import { TabSize, TabType } from '../Shared/types';

export type TabOptions = {
  active?: boolean;
  onClicked?: () => void;
  children: React.ReactNode;
  tabType: TabType;
  tabSize: TabSize;
};

export const Container = styled.div<TabOptions>`
  text-align: center;
  width: fit-content;
  min-width: fit-content;
  height: 32px;
  display: inline-flex;
  border-radius: ${props => (props.tabType === TabType.SEPARATE ? '24px' : '0px2')};
  -webkit-border-radius: ${props => (props.tabType === TabType.SEPARATE ? '24px' : '0px2')};
  -moz-border-radius: ${props => (props.tabType === TabType.SEPARATE ? '24px' : '0px2')};
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  transition: all 0.15s ease;
  cursor: pointer;
  border: ${props =>
    props.active && props.tabType === TabType.SEPARATE ? `3px solid ${props.theme.colors.bgPrimaryBtn}` : ''};
  background-color: ${props => {
    if (props.active) {
      if (props.tabType === TabType.SEPARATE) return props.theme.colors.bgSecondaryTab;
      else return props.theme.colors.bgSecondaryBtn;
    } else {
      if (props.tabType === TabType.SEPARATE) return props.theme.colors.bgSecondaryBtn;
      else return props.theme.colors.bgDefaultLabel;
    }
  }};

  span {
    color: ${props => (props.active ? props.theme.colors.bgPrimaryBtn : props.theme.colors.textSecondaryTab)};
  }

  &:first-child {
    border-top-left-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
  }

  &:last-child {
    ${props => (props.tabType === TabType.TOGETHER ? 'border-top-right-radius: 24px !important;' : '')}
    ${props => (props.tabType === TabType.TOGETHER ? 'border-bottom-right-radius: 24px !important;' : '')}
  }

  &:hover {
    border: ${props =>
      props.active && props.tabType === TabType.SEPARATE ? `3px solid ${props.theme.colors.bgPrimaryBtn}` : ''};
    background-color: ${props => (props.tabType === TabType.SEPARATE ? props.theme.colors.bgSecondaryTab : '')};

    span {
      color: ${props => props.theme.colors.bgPrimaryBtn};
    }
  }

  @media screen and (min-width: 600px) {
    height: 42px;
  }
`;

export const Text = styled.span<TabOptions>`
  font-style: normal;
  margin: auto;
  font-weight: 700;
  line-height: ${props => props.theme.typography.lineHeight.md2}px;
  font-size: ${props => props.theme.typography.size.sm2}px;
  padding: ${props => (props.tabSize === TabSize.NORMAL ? '6px 16px 6px 16px' : '8px 45px 8px 45px')};

  @media screen and (min-width: 600px) {
    font-size: ${props => props.theme.typography.size.sm3}px;
    padding: ${props => (props.tabSize === TabSize.NORMAL ? '8px 16px 10px 16px' : '8px 45px 8px 45px')};
  }
`;
