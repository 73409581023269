export type Hexcode = string;

export type ColorModes = 'dark' | 'light';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: Record<string, Hexcode>;
    typography: {
      type: {
        primary: string;
        code: string;
      };
      weight: {
        ligth: string;
        book: string;
        regular: string;
        bold: string;
        black: string;
      };
      size: {
        sm1: string;
        sm2: string;
        sm3: string;
        sm4: string;
        sm5: string;
        md1: string;
        md2: string;
        md3: string;
        md4: string;
        lg1: string;
        lg2: string;
        lg3: string;
        lg4: string;
        lg5: string;
        code: string;
      };
      lineHeight: {
        sm1: string;
        sm2: string;
        sm3: string;
        sm4: string;
        md1: string;
        md2: string;
        md3: string;
        md4: string;
        sm5: string;
        lg1: string;
        lg2: string;
        lg3: string;
        lg4: string;
        lg5: string;
        code: string;
      };
    };
    shadows: {
      '1-drop': string;
      '2-drop': string;
    };
    responsive: {
      large: string;
      medium: string;
      small: string;
      xsmall: string;
    };
    spacings: {
      XS: number;
      SM: number;
      MD: number;
      LG: number;
      XL: number;
      HUGE: number;
    };
    breakpoints: {
      [name: string]: number;
    };
  }
}

export type ThemeProps = {
  children: React.ReactNode;
  colorModes: ColorModes;
};

export enum VARIANTS {
  PRIMARY = 'primary',
  PRIMARY_GRADIENT = 'primaryGradient',
  SECONDARY = 'secondary',
  SECONDARY_LIGHT = 'secondaryLight',
  DANGER = 'danger',
  WARNING = 'warning',
  WARNING_LIGHT = 'warningLight',
  SUCCESS = 'success',
  INFO = 'info',
  DARK = 'dark',
  INFORMATIVE = 'informative',
  GREEN = 'green',
  PURPLE = 'purple',
  YELLOW = 'yellow',
}

export enum SIZES {
  EXTRA_SMALL = 'extra_small',
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  BIGGEST = 'biggest',
}
