import styled, { keyframes } from 'styled-components';

const FileUploaderViewerContainer = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${({ align }) => align || 'center'};
  align-contetn: center;
  justify-content: ${({ align }) => align || 'space-between'};
  flex-wrap: wrap;
  margin: 0 0 10px 0;
  gap: 16px;
  width: ${({ align }) => align && '100%'};
`;

const FileUploaderViewerPreview = styled.div`
  width: auto;
  margin: 10px auto 10px auto;
  padding: 10px 30px 10px 30px;
  background: #fff9eb;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.15);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  align-contetn: center;
`;

type FileUploaderViewerImagePreviewProps = {
  width?: number;
  height?: number;
  withWarningBorder?: boolean;
};

const FileUploaderViewerImagePreviewContainer = styled.div<FileUploaderViewerImagePreviewProps>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.15);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
`;

const FileUploaderViewerImagePreview = styled.img<FileUploaderViewerImagePreviewProps>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  ${({ withWarningBorder }) => withWarningBorder && 'border: 2px solid red'};
`;

const FileUploaderViewerPreviewButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
`;

const FileUploaderViewerDescription = styled.div`
  margin-left: 10px;
`;

const SpinnerAnimation = keyframes`
0%,
100% {
  box-shadow: 0em -2.6em 0em 0em #6C757D, 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2), 2.5em 0em 0 0em rgba(108, 117, 125, 0.2), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.2), 0em 2.5em 0 0em rgba(108, 117, 125, 0.2), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.2), -2.6em 0em 0 0em rgba(108, 117, 125, 0.5), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.7);
}
12.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.7), 1.8em -1.8em 0 0em #6C757D, 2.5em 0em 0 0em rgba(108, 117, 125, 0.2), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.2), 0em 2.5em 0 0em rgba(108, 117, 125, 0.2), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.2), -2.6em 0em 0 0em rgba(108, 117, 125, 0.2), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.5);
}
25% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.5), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.7), 2.5em 0em 0 0em #6C757D, 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.2), 0em 2.5em 0 0em rgba(108, 117, 125, 0.2), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.2), -2.6em 0em 0 0em rgba(108, 117, 125, 0.2), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2);
}
37.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.2), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.5), 2.5em 0em 0 0em rgba(108, 117, 125, 0.7), 1.75em 1.75em 0 0em #6C757D, 0em 2.5em 0 0em rgba(108, 117, 125, 0.2), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.2), -2.6em 0em 0 0em rgba(108, 117, 125, 0.2), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2);
}
50% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.2), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2), 2.5em 0em 0 0em rgba(108, 117, 125, 0.5), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.7), 0em 2.5em 0 0em #6C757D, -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.2), -2.6em 0em 0 0em rgba(108, 117, 125, 0.2), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2);
}
62.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.2), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2), 2.5em 0em 0 0em rgba(108, 117, 125, 0.2), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.5), 0em 2.5em 0 0em rgba(108, 117, 125, 0.7), -1.8em 1.8em 0 0em #6C757D, -2.6em 0em 0 0em rgba(108, 117, 125, 0.2), -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2);
}
75% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.2), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2), 2.5em 0em 0 0em rgba(108, 117, 125, 0.2), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.2), 0em 2.5em 0 0em rgba(108, 117, 125, 0.5), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.7), -2.6em 0em 0 0em #6C757D, -1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2);
}
87.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(108, 117, 125, 0.2), 1.8em -1.8em 0 0em rgba(108, 117, 125, 0.2), 2.5em 0em 0 0em rgba(108, 117, 125, 0.2), 1.75em 1.75em 0 0em rgba(108, 117, 125, 0.2), 0em 2.5em 0 0em rgba(108, 117, 125, 0.2), -1.8em 1.8em 0 0em rgba(108, 117, 125, 0.5), -2.6em 0em 0 0em rgba(108, 117, 125, 0.7), -1.8em -1.8em 0 0em #6C757D;
}`;

const Spinner = styled.div<{ size: number }>`
  color: #6c757d;
  margin: 100px auto;
  font-size: ${props => (props.size ? `${props.size / 4}px` : '4px')};
  width: ${props => (props.size ? `${props.size / 3}px` : '5px')};
  height: ${props => (props.size ? `${props.size / 3}px` : '5px')};
  margin: 0px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: ${SpinnerAnimation} 1.5s infinite;
`;

const LoadingViewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  FileUploaderViewerContainer,
  FileUploaderViewerPreview,
  FileUploaderViewerPreviewButton,
  FileUploaderViewerDescription,
  FileUploaderViewerImagePreviewContainer,
  FileUploaderViewerImagePreview,
  Spinner,
  LoadingViewContainer,
};
