import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { icons } from './shared/icons';

const Svg = styled.svg`
  display: 'inline-block';
  vertical-align: middle;

  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
  ${props => props.width && `width: ${props.width}!important`}};
  ${props => props.height && `height: ${props.height}!important`}};
`;

const Path = styled.path`
  fill: currentColor;
`;

/**
 * An Icon is a piece of visual element, but we must ensure its accessibility while using it.
 * It can have 2 purposes:
 *
 * - *decorative only*: for example, it illustrates a label next to it. We must ensure that it is ignored by screen readers, by setting `aria-hidden` attribute (ex: `<Icon icon="check" aria-hidden />`)
 * - *non-decorative*: it means that it delivers information. For example, an icon as only child in a button. The meaning can be obvious visually, but it must have a proper text alternative via `aria-label` for screen readers. (ex: `<Icon icon="print" aria-label="Print this document" />`)
 */
export function Icon({ icon, width="20px", height="20px", viewBoxIcon = '0 0 1024 1024', ...props }) {
  return (
    <Svg
      viewBox={viewBoxIcon}
      width={width}
      height={height}
      {...props}
    >
      <Path d={icons[icon]} />
    </Svg>
  );
}

export function BrandIcon() {
  return (
    <img src="/images/aptuno_logo_2colors.svg" alt="Logo Aptuno" title="Logo Oficial Aptuno" width="125" height="29" />
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  block: PropTypes.bool,
};

Icon.defaultProps = {
  block: false,
};
