import { Portal } from '../Portal';
import React, { useEffect, useRef } from 'react';
import {
  CloseModalContainer,
  ModalBody,
  ModalContent,
  ModalHead,
  ModalWrapper,
} from './ModalBottomSheet.styled';
import { ESCAPE } from '../../shared/keys';
import { toggleBodyScroll } from '../../shared/coolFunctions';
import { useResponsive } from '../../hooks/useResponsive';
import { Icon } from '../../Icon';

export type ModalProps = {
  header?: React.ReactNode;
  isActive?: boolean;
  size?: number;
  bgColor?: string;
  width?: any;
  height?: any;
  onClose?: (event) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const ModalBottomSheet = ({
  header,
  children,
  isActive,
  onClose,
  size,
  bgColor,
  width,
  height,
  ...props
}: ModalProps) => {
  const { isMobile } = useResponsive();

  useEffect(() => {
    toggleBodyScroll(!isActive);
    return () => toggleBodyScroll(true);
  }, [isActive]);

  const wrapperRef = useRef();

  const handleClose = event => {
    onClose(event);
  };

  const handleKeyUp = event => {
    if (event.key === ESCAPE) {
      handleClose(event);
    }
  };

  const handleClick = event => {
    if (event.target === wrapperRef.current) {
      handleClose(event);
    }
  };

  const onCloseModalButtonClick = e => {
    handleClose(e);
    toggleBodyScroll(true);
  };

  return (
    <Portal>
      <ModalWrapper ref={wrapperRef} onKeyUp={handleKeyUp} onClick={handleClick} {...props} isActive={isActive}>
        <ModalContent
          size={size}
          bgColor={bgColor}
          width={width}
          height={height}
          isActive={isActive}
          isMobile={isMobile}
        >
          <ModalHead isMobile={isMobile}>
            <CloseModalContainer onClick={onCloseModalButtonClick}>
              <Icon
                icon="minus"
                height="12px"
                width="40px"
                color="#DEE2E6"
                preserveAspectRatio="none"
                viewBoxIcon="0 0 1024 624"
              />
            </CloseModalContainer>
            {header}
          </ModalHead>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ModalWrapper>
    </Portal>
  );
};
