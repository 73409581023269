import React from 'react';
import { Container, LabelTagProps } from './LabelTag.styled';

const LabelTag = ({ appearance, size, children }: LabelTagProps) => {
  return (
    <Container appearance={appearance} size={size}>
      {children}
    </Container>
  );
};

export { LabelTag };
