import React from 'react';
import { CircleIcon } from '../../CircleIcons/CircleIcon/CircleIcon';
import { CardMiniOptions, Container, ContainerIcon, Feature, Item, Quantity } from './CardMini.styled';

const CardMini = ({ refIcon, appearance, refQty, refFeature, orientation, useCase }: CardMiniOptions) => {
  return (
    <Container orientation={orientation} useCase={useCase}>
      <ContainerIcon orientation={orientation} useCase={useCase}>
        <CircleIcon refIcon={refIcon} appearance={appearance} />
      </ContainerIcon>
      <Item>
        <Quantity isDisabled={appearance === 'disabled'}>{refQty}</Quantity>
      </Item>
      <Item>
        <Feature appearance={appearance} orientation={orientation}>
          {refFeature}
        </Feature>
      </Item>
    </Container>
  );
};

export { CardMini };
