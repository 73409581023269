import React from "react";
import {
  BackBtn,
  Span,
  BackBtn2,
  Span2,
} from "./Buttons.styled";

import { icons } from "../../shared/icons";
import { Icon } from "../../Icon";

const BackButton = ({ onClick, children }) => {
  return (
    <BackBtn onClick={onClick}>
      <Icon icon="arrowleftalt"></Icon>
      <Span>{children}</Span>
    </BackBtn>
  );
};

const BackButton2 = ({ onClick, children, color, height, width, minWidth, maxWidth, background, borderRadius }) => {
  return (
    <BackBtn2 onClick={onClick} height={height} width={width} minWidth={minWidth} maxWidth={maxWidth} background={background} borderRadius={borderRadius} >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={icons['leftarrow']} fill="#6C757D" />
      </svg>
      <Span2 color={color}>{children}</Span2>
    </BackBtn2>
  );
};

export { BackButton, BackButton2 }
