import AptunoButton from '../Buttons/Button';
import React, { createRef, ReactNode } from 'react';
import { SIZES, VARIANTS } from '../../shared/types';
import { DropdownContent, DropdownItem, DropdownWrapper, LogoutButton } from './DropdownMenu.styled';
import { Icon } from '../../Icon';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const Menu = (props: any) => {
  const { id, children, alwaysVisible, hidden, ...rest } = props;

  return (
    <DropdownContent id={id} hidden={!alwaysVisible && hidden} {...rest}>
      {React.Children.map(children, (menuItem, index) => {
        if (!React.isValidElement(menuItem)) {
          return;
        }
        const itemId = `${id}-${index}`;
        return <React.Fragment key={itemId}>{menuItem}</React.Fragment>;
      })}
    </DropdownContent>
  );
};

export type MenuItem = {
  name: string;
  icon: string;
  onClick: () => void;
};

type DropDownMenuProps = {
  title: string;
  items: Array<MenuItem>;
  loginItem?: MenuItem;
  right?: boolean;
  icon?: string;
  iconRight?: boolean;
  size?: SIZES;
  variant?: VARIANTS;
  authenticated?: boolean;
  openOnHover?: boolean;
  fitWidth?: boolean;
  fitHeight?: boolean;
  children?: ReactNode;
  withoutBorder?: boolean;
  backgroundColor?: string;
  onHoverBgColor?: string;
  onHoverColor?: string;
  itemBackgroundColor?: string;
  itemOnHoverBgColor?: string;
  itemOnHoverColor?: string;
  itemFontSize?: string;
};

function DropdownMenu({
  title = '',
  icon,
  iconRight = false,
  size = SIZES.MEDIUM,
  variant = VARIANTS.PRIMARY,
  authenticated = false,
  loginItem,
  items = [],
  openOnHover = false,
  children,
  withoutBorder = false,
  itemBackgroundColor,
  itemOnHoverBgColor,
  itemOnHoverColor,
  itemFontSize,
  ...rest
}: DropDownMenuProps) {
  const menuRef = createRef();
  const { isListOpen, setIsListOpen } = useOutsideClick(menuRef, false);

  return (
    <DropdownWrapper ref={menuRef as React.RefObject<HTMLDivElement>} {...rest}>
      <AptunoButton
        fitWidth={rest.fitWidth}
        fitHeight={rest.fitHeight}
        appearance={variant}
        size={size}
        onClick={() => setIsListOpen(!isListOpen)}
        withoutBorder={withoutBorder}
        {...rest}
      >
        {icon && !iconRight && <Icon icon={icon} />}
        {title}
        {icon && iconRight && <Icon icon={icon} />}
      </AptunoButton>
      <Menu hidden={!isListOpen} {...rest}>
        {items.map((item, index) => (
          <DropdownItem
            key={index}
            variant={variant}
            size={size}
            itemBackgroundColor={itemBackgroundColor}
            itemOnHoverBgColor={itemOnHoverBgColor}
            itemOnHoverColor={itemOnHoverColor}
            itemFontSize={itemFontSize}
            onClick={() => {
              item.onClick();
              setIsListOpen(!isListOpen);
            }}
          >
            {item.icon && <Icon icon={item.icon} />}
            {item.name}
          </DropdownItem>
        ))}

        {authenticated && (
          <LogoutButton>
            <DropdownItem
              variant={variant}
              size={size}
              itemBackgroundColor={itemBackgroundColor}
              itemOnHoverBgColor={itemOnHoverBgColor}
              itemOnHoverColor={itemOnHoverColor}
              itemFontSize={itemFontSize}
              onClick={() => {
                loginItem.onClick();
                setIsListOpen(!isListOpen);
              }}
            >
              {loginItem.icon && <Icon icon={loginItem.icon} />}
              {loginItem.name}
            </DropdownItem>
          </LogoutButton>
        )}
      </Menu>
    </DropdownWrapper>
  );
};

export { DropdownMenu };
