import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: auto;
  width: fit-content;

  @media screen and (max-width: 312px) {
    max-width: 312px;
  }
`;

const Animation = styled.div`
  max-height: 30vh !important;
  margin: auto;
  text-align-last: center;

  svg {
    max-height: 30vh !important;
    max-width: min-content;
  }
`;

const Title = styled.div`
  height: auto;
  font-family: Satoshi;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  letter-spacing: -0.024em;
  color: #000000;
  line-height: 50px;
  max-width: 390px;
  z-index: 2;
  transition: 0.3s all ease-out;

  @media screen and (max-width: 450px) {
    font-size: 30px;
    max-width: 259px;
    line-height: 34px;
  }
`;

const Description = styled.span`
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #6c757d;
  margin-top: 16px;
  margin-bottom: 24px;

  @media screen and (min-width: 500px) {
    width: 390px;
  }
`;

const LinkRecommendation = styled.span`
  font-family: Satoshi;
  font-style: normal;
  font-weight: bold;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0055fb;
  margin-bottom: 32px;
  cursor: pointer;
  text-decoration: underline;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding-left: 1.3em;
    list-style: none;
    margin-left: 0.3rem;
    transition: 0.3s all ease-out;

    &:hover {
      transform: scale(1.001);
    }
  }

  li:before {
    content: '●';
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -1.3em;
    width: 1.1em;
  }

  @media screen and (max-height: 700px) {
    font-size: 2.5vh;
  }
`;

const Button = styled.button`
  width: 100%;
  max-width: 550px;
  height: 48px;
  padding: 0px 10px;
  background: #0055fb;
  border-radius: 20px;
  border-width: 0px;
  border-style: none;
  color: #ffffff;
  font-weight: normal;
  line-height: 24px;
  font-style: normal;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-height: 926px) and (max-width: 500px) {
    position: fixed;
    bottom: 0;
    align-self: center;
    width: 90%;
    margin-bottom: 3px;
  }
`;

export { Container, Title, Description, LinkRecommendation, Button, Animation };
