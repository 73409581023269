import React, { useEffect, useRef, useState } from 'react';
import { AptunoButton } from '../../components/Buttons/Button';
import { Carousel, SliderHandle } from '../../components/Carousel/Carousel';
import { useResponsive } from '../../hooks/useResponsive';
import { Icon } from '../../Icon';
import { VARIANTS } from '../../shared/types';
import {
  CarouselCardsContent,
  CarouselCardsControls,
  CarouselCardsHeader,
  CarouselCardsTitle,
  CarouselCardsWrapper,
  SlideItem,
} from './CarouselCards.styled';

type CarouselCardsProps = {
  children: React.ReactNode;
  title: string;
};

function CarouselCards({ title, children, ...props }: CarouselCardsProps) {
  const { isMobile, isDesktop, isMediumSize } = useResponsive();
  const childRef = useRef<SliderHandle>(null);
  const [slidesToShow, setSlidesToShow] = useState<number>(null);

  useEffect(() => {
    if (isDesktop) {
      if (children && React.Children.count(children) < 2) {
        setSlidesToShow(1);
      } else if (children && React.Children.count(children) === 2) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    } else if (isMediumSize) {
      if (children && React.Children.count(children) >= 2) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    } else if (isMobile) {
      setSlidesToShow(1);
    }
  }, [isMobile, isDesktop, isMediumSize]);

  return (
    <CarouselCardsWrapper isMobile={isMobile} isMediumSize={isMediumSize} {...props}>
      <CarouselCardsHeader>
        <CarouselCardsTitle>{title}</CarouselCardsTitle>
        {!isMobile && (
          <CarouselCardsControls>
            <AptunoButton
              appearance={VARIANTS.SECONDARY}
              isCircular
              onClick={() => childRef.current && childRef.current.prevSlide()}
            >
              <Icon icon="arrowleft" />
            </AptunoButton>
            <AptunoButton
              appearance={VARIANTS.SECONDARY}
              isCircular
              onClick={() => childRef.current && childRef.current.nextSlide()}
            >
              <Icon icon="arrowright" />
            </AptunoButton>
          </CarouselCardsControls>
        )}
      </CarouselCardsHeader>
      <CarouselCardsContent>
        {slidesToShow && (
          <Carousel isOpen={true} ref={childRef} slidesToShow={slidesToShow} gap={8} infinite showArrows={isMobile} withoutCounter>
            {React.Children.map(children, (menuItem, index) => {
              if (!React.isValidElement(menuItem)) {
                return;
              }
              const itemId = `card-${index}`;
              return <SlideItem key={itemId}>{menuItem}</SlideItem>;
            })}
          </Carousel>
        )}
      </CarouselCardsContent>
    </CarouselCardsWrapper>
  );
}

export { CarouselCards };
