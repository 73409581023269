import { createGlobalStyle, css } from 'styled-components';
import { colorPrimitives, typography, typographyHierarchy } from './designTokens';

export const bodyStyles = css`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.sm3}px;
  color: ${colorPrimitives.main.BLACK};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${typography.weight.regular};
    margin: 0;
    padding: 0;
  }
  h1 ${typographyHierarchy.h1}
  h2 ${typographyHierarchy.h2}
  h3 ${typographyHierarchy.h3}
  h4 ${typographyHierarchy.h4}
  h5 ${typographyHierarchy.h5}
  h6 ${typographyHierarchy.h6}
  span ${typographyHierarchy.span}
  p ${typographyHierarchy['p-md']}
  button,
  input,
  textarea,
  select {
    font-family: ${typography.type.primary};
  }

  sub,
  sup {
    font-size: 0.8em;
  }

  sub {
    bottom: -0.2em;
  }

  sup {
    top: -0.2em;
  }

  b,
  em {
    font-weight: ${typography.weight.bold};
  }

  hr {
    border: none;
    border-top: 1px solid ${colorPrimitives.grays.GRAY};
    clear: both;
    margin-bottom: 1.25rem;
  }

  code,
  pre {
    font-family: ${typography.type.code};
    font-size: ${Number(typography.size.sm2) - 1}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    vertical-align: baseline;

    color: ${colorPrimitives.grays.GRAY};
  }

  pre {
    line-height: 18px;
    padding: 11px 1rem;
    white-space: pre-wrap;

    background: rgba(0, 0, 0, 0.05);
    color: ${colorPrimitives.main.BLACK};
    border-radius: 3px;
    margin: 1rem 0;
  }

  &.ReactModal__Body--open {
    overflow: hidden;
    &.hide-intercom #intercom-container {
      display: none;
    }
  }

  .ReactModalPortal > div {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    transition: all 200ms ease-in;

    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Satoshi-Regular.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/Satoshi-Medium.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-weight: 500, bold;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2") format("woff2"), url("/fonts/Satoshi-Bold.woff") format("woff");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("/fonts/Satoshi-Black.woff2");
  }

  @font-face {
    font-family: "icomoon";
    src: url("/fonts/icomoon.eot?hbfemh");
    src: url("/fonts/icomoon.eot?hbfemh#iefix") format("embedded-opentype"),
      url("/fonts/icomoon.ttf?hbfemh") format("truetype"), url("/fonts/icomoon.woff?hbfemh") format("woff"),
      url("/fonts/icomoon.svg?hbfemh#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  white-space: pre-line;

  body {
    ${bodyStyles}
  }
`;
