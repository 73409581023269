import React from 'react';
import { Fab } from '../../components/Fab/Fab';
import { HorizontalPosition, VerticalPosition } from '../../components/Fab/Fab.styled';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared/types';
import { Carousel } from '../Carousel/Carousel';
import {
  CardContent,
  CardImageBox,
  Characteristic,
  CharacteristicsCol,
  CharacteristicsSection,
  EstateCardWrapper,
  Price,
  PriceSection,
  SingleImg,
  Title
} from './EstateCard.styled';

export enum Size {
  COMPACT = 'COMPACT',
  GENERAL = 'GENERAL',
}

export type EsatateCardProps = {
  title: string;
  type: string;
  price: string;
  bathrooms: string;
  bedrooms: string;
  area: string;
  isFav?: boolean;
  children: React.ReactNode;
  withAdmin?: string;
  size?: Size;
  onClickFav?: (event) => void;
  onClick?: (event) => void;
};

function EstateCard({
  title,
  type,
  price,
  bathrooms,
  bedrooms,
  area,
  children,
  withAdmin,
  size = Size.GENERAL,
  onClickFav,
  onClick,
  ...props
}: EsatateCardProps) {
  return (
    <EstateCardWrapper size={size} {...props} onClick={event => onClick(event)}>
      <Fab
        isCircular
        appearance={VARIANTS.PRIMARY}
        inverted
        verticalPosition={VerticalPosition.veryTop}
        horizontalPosition={HorizontalPosition.veryRight}
        isTransparentBlue
        withoutBorder
        onClick={event => onClickFav(event)}
        active={props.isFav}
        size={size === Size.COMPACT ? SIZES.SMALL : SIZES.MEDIUM}
      >
        <Icon icon="heart" />
      </Fab>
      <CardImageBox size={size} {...props}>
        {React.Children.count(children) > 1 ? (
          <Carousel gap={0} maxHeight={300}>
            {children}
          </Carousel>
        ) : (
          <SingleImg>{children}</SingleImg>
        )}
      </CardImageBox>
      <CardContent size={size}>
        <Title size={size}>{title}</Title>
        {size === Size.COMPACT && (
          <PriceSection size={size}>
            <Price>{price}</Price>
            {withAdmin}
          </PriceSection>
        )}
        <CharacteristicsSection size={size}>
          {size === Size.GENERAL && (
            <>
              <CharacteristicsCol size={size}>
                <Characteristic size={size}>
                  <Icon icon="aptunohome" /> {type}
                </Characteristic>
                <Characteristic size={size}>
                  <Icon icon="bed" /> {bedrooms}
                </Characteristic>
              </CharacteristicsCol>
              <CharacteristicsCol size={size}>
                <Characteristic size={size}>
                  <Icon icon="bath" /> {bathrooms}
                </Characteristic>
                <Characteristic size={size}>
                  <Icon icon="squarem" /> {area}
                </Characteristic>
              </CharacteristicsCol>
            </>
          )}
          {size === Size.COMPACT && (
            <CharacteristicsCol size={size}>
              <Characteristic size={size}>
                <Icon icon="bed" /> {bedrooms}
              </Characteristic>
              <Characteristic size={size}>
                <Icon icon="bath" /> {bathrooms}
              </Characteristic>
              <Characteristic size={size}>
                <Icon icon="squarem" /> {area}
              </Characteristic>
            </CharacteristicsCol>
          )}
        </CharacteristicsSection>
        {size === Size.GENERAL && (
          <PriceSection size={size}>
            <Price>{price}</Price>
            {withAdmin}
          </PriceSection>
        )}
      </CardContent>
    </EstateCardWrapper>
  );
}

export { EstateCard };
