import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border-radius: 24px 8px 8px 24px;
  border: 1px solid #dee2e6;
  color: grey;
  width: 90px;
  height: 48px;

  &:hover {
    cursor: pointer;
    color: #0055fb;
  }

  @media screen and (max-width: 450px) {
    transform: scale(0.8);
  }
`;

const FlagSelected = styled.div<{ border }>`
  box-shadow: 0 10px 25px 0 rgb(108 117 125 / 10%);
  text-align-last: center;
  border-radius: 16px;
  padding: 12px 18px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  border-radius: 24px 8px 8px 24px;
  border: ${({ border }) => (border == 'active' ? '1px solid #0055fb' : '')};

  @media screen and (max-width: 450px) {
    border-radius: 0.5rem;
    text-align-last: right;
  }
`;

const FlagOptions = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;
  background: #ffffff;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 77px;
  margin-top: 5px;
  position: absolute;
  box-shadow: 0 0 4px #d2d2d2;
  text-align: left;
  padding-left: 14px;
  padding-top: 6px;
  margin-left: 5px;

  &:target {
    display: inline;
  }

  &:first-child {
    padding-top: 0.1em;
  }

  img {
    transition: all 0.2s ease-in;

    &:hover {
      transform: translate(2px);
    }
  }
`;

const FlagItem = styled.li`
  list-style: none;
  margin-bottom: 0.5em;
`;

const FlagIcon = styled.img`
  padding-bottom: 2px;
  border-radius: 4.8px;
`;

const ArrowIcon = styled.span<{ animation }>`
  transform: rotate(180deg);

  svg {
    transition: all 0.15s ease-out;
    transform-origin: 65% 48%;
    transform: ${({ animation }) => (animation == 'true' ? ' translateX(-4px) rotate(180deg)' : '')};
    width: 11px !important;
    height: 11px !important;
  }
`;

export { Container, FlagSelected, FlagOptions, FlagItem, FlagIcon, ArrowIcon };
