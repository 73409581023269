import styled, { css } from 'styled-components';

//-----------------------------
// Button type text, number
const InputGroup =styled.div`
  position: relative;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  ${props =>
    props.width &&
    `
    width: ${props.width};
  `};
  ${props =>
    props.height &&
    `
    height: ${props.height};
  `};
`;

const InputLabel = styled.label`
  display: inline-block;
  position: absolute;
  color: #808080;
  font-weight: 400;
  font-family: 'Satoshi';
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 5px;
  transform: translate(12px, 12px);
  transition: all 150ms ease-in;
`;

const InputBase = css`
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.borderGray};
  overflow: hidden;
`;

const InputField = styled.input`
  ${InputBase}
  height: 48px;
  padding: 8px;
  padding-left: 16px;
  padding-top: 15px;
  width: 100%;

  &:disabled {
    background-color: #ececec;
  }
  &:hover {
    border: 1px solid #0055fb;
  }
  &:focus {
    border: 1px solid #0055fb;
  }
  &:focus + ${InputLabel} {
    transform: translate(12px, 3px);
    font-size: 0.7em;
    color: #6c757d;
    padding-right: 0;
  }
  &:not(:placeholder-shown) + ${InputLabel} {
    transform: translate(12px, 3px);
    font-size: 0.7em;
    color: #6c757d;
    padding-right: 0;
  }

  ${props =>
    props.invalid &&
    `
    background-color: ${props.theme.colors.bgErrorInput} !important;
    border-color: ${props.theme.colors.boderErrorInput} !important;
    
    outline: none;
    
    &:hover,
    &:focus,
    
    &:active {
      border: 2px solid ${props.theme.colors.boderErrorInput};
    }
  `};
`;

//-----------------------
// Radio Button - CheckBox

const CheckLabel = styled.label`
  margin-top: auto;
  margin-bottom: auto;
`;

const CheckContainer = styled.div`
  line-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const Check = styled.input`
  --active: #275efe;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #808080;
  --border-hover: #275efe;
  --background: #fff;
  --disabled: #fff;
  --disabled-inner: #cdcdcd;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  flex-shrink: 0;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + ${CheckLabel} {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(.disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + ${CheckLabel} {
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 8px;
  }
  &:not(.switch) {
    border-radius: 7px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }
  &.switch {
    width: 38px;
    border-radius: 11px;
    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

const CheckRadio = styled(Check).attrs({
  type: 'radio',
})`
  border-radius: 50%;
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    --s: 0.5;
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + ${CheckLabel} {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(.disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 21px;
    min-width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + ${CheckLabel} {
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 8px;
  }
  &:not(.switch) {
    border-radius: 50%;
    &:after {
      width: 7px;
      height: 7px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 6px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 1deg;
    }
  }
  &.switch {
    width: 38px;
    border-radius: 11px;
    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

// TextArea
const TextArea = styled.textarea`
  ${InputBase}
  resize: none;
  width: 100%;
  height: 250px;
  padding: 10px 20px;
`;

// Number Input with buttons

const MinusPlusButton = styled.button`
  ${InputBase}
  width: 48px;
  height: 48px;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  user-select: none;

  &:disabled {
    background-color: #ececec;
  }

  &:after,
  &:before {
    display: inline-block;
    position: absolute;
    content: '';
    width: 1rem;
    height: 2px;
    background-color: #212121;
    transform: translate(-50%, -50%);
  }

  &.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const NumberInput = styled.input.attrs({
  type: 'number',
  min: 1,
})`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  max-width: 4rem;
  padding: 0.5rem;
  border: none;
  border-width: 0 2px;
  font-size: 1.5em;
  height: 48px;
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

const NumberInputWrapper = styled.div`
  border: 0;
  display: inline-flex;
  box-sizing: border-box;
`;

export {
  InputField,
  InputLabel,
  InputGroup,
  Check,
  CheckContainer,
  CheckLabel,
  CheckRadio,
  TextArea,
  MinusPlusButton,
  NumberInputWrapper,
  NumberInput,
};
