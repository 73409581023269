import React, { useEffect, useState } from 'react';
import {
  Circle,
  CircleBg,
  PercentLabel,
  StepIndicator,
  StepLabel,
  StepperWrapper,
  CircularChart4040,
  StepTitle4040,
  StepDescription4040,
} from './Stepper.styled';

type Step = {
  title: string;
  description: string;
};

type Stepper4040 = {
  steps: Step[];
  activeStep: number;
};

// STEPPER 40PX_40PX
function Stepper4040({ steps, activeStep = 0 }: Stepper4040) {
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [currentPercent, setPercent] = useState(0);

  useEffect(() => {
    if (activeStep >= 0 && activeStep <= steps.length) {
      setCurrentStep(steps[activeStep]);
      setPercent(getProgressPercent());
    }
  }, [activeStep, steps]);

  const getProgressPercent = () => {
    return ((activeStep + 1) * 100) / steps.length;
  };

  return (
    <StepperWrapper>
      <StepIndicator>
        <CircularChart4040 viewBox="0 0 36 36">
          <CircleBg
            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <Circle
            color={'#0055FB'}
            percent={currentPercent}
            d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <PercentLabel x="9" y="22.35">
            {activeStep + 1}/{steps.length}
          </PercentLabel>
        </CircularChart4040>
      </StepIndicator>
      <StepLabel>
        {currentStep.title && <StepTitle4040>{currentStep.title}</StepTitle4040>}
        {currentStep.description && <StepDescription4040>{currentStep.description}</StepDescription4040>}
      </StepLabel>
    </StepperWrapper>
  );
}

export { Stepper4040 };
