import React from 'react';
import { Portal } from '../../Portal';
import { StyledBurger } from './Burger.styled';


type BurgerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
}
const Burger = ({ open, setOpen, ...props }) => {
  
  const isExpanded = open ? true : false;
  
  return (<Portal>
    <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
      <span />
      <span />
      <span />
    </StyledBurger>
  </Portal>
  )
}

export default Burger;
