import styled, { css } from 'styled-components';

const TextInputGroup = styled.div`
  position: relative;
  box-sizing: content-box;
  display: flex;
`;
const TextInputLabel = styled.label`
  display: inline-block;
  position: absolute;
  font-family: "Satoshi";
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  left: 5px;
  transform: translate(12px, 12px);
  transition: all 150ms ease-in;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: ${({ fontSizeLabel }) => (fontSizeLabel ? fontSizeLabel : '16px')};
  color: ${({ colorLabel }) => (colorLabel ? colorLabel : '#cdcdcd')};
`;

const TextInputBase = css`
  background-color: white;
  border-radius: 8px;
  border: ${({ colorBorder }) => (colorBorder ? colorBorder : 'none')};
  overflow: hidden;
  padding: 12px 16px;
  left: 0px;
  top: 32px;
  color: ${({ color }) => (color ? color : '#000000')};
`;

const TextInputField = styled.input`
  ${TextInputBase}
  height: 48px;
  padding: 8px;
  padding-left: 16px;
  padding-top: 15px;
  width: ${({ width }) => (width ? width : '100%')};
  font-size: ${({ fontSizeText }) => (fontSizeText ? fontSizeText : '13.3333px')};

  &:disabled {
    background-color: #ececec;
  }
  &:hover {
    border: ${({ colorBorder }) => (colorBorder ? colorBorder : 'none')};
  }
  &:focus {
    border: ${({ colorBorder }) => (colorBorder ? colorBorder : '1px solid #0055fb')};
  }
  &:focus + ${TextInputLabel} {
    transform: translate(12px, 0px);
    font-size: 0.7em;
    color: #cdcdcd;
    padding-right: 0;
  }
  &:not(:placeholder-shown) + ${TextInputLabel} {
    transform: translate(12px, 0px);
    font-size: 0.7em;
    color: #cdcdcd;
    padding-right: 0;
  }
`;

export { TextInputGroup, TextInputLabel, TextInputBase, TextInputField };
