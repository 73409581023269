import { modalSlide } from '../../components/Modal/Modal.styled';
import styled, { css } from 'styled-components';
import { fadeOut, fadeIn } from '../../shared/animation'; 

type ZoomGalleryWrapperProps = {
  isOpen: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ImagesContainer = styled.div`
  max-height: 100%;
  height: 100%;
  padding: 4.5rem 0;

  @media (min-width: 600px) and (orientation: landscape) {   
    padding-top: 3.5rem;
    padding-bottom: 1rem;
  }
`;

const ZoomGalleryWrapper = styled.div<ZoomGalleryWrapperProps>`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.bgZoomGallery};
  display: block;
  animation: ${fadeOut} .9s forwards;
  z-index: -1;

  ${props => props.isOpen &&  css`
    animation: ${modalSlide} .3s ease-in forwards;
    display: block;
    z-index: 1;
  `}

  ${ImagesContainer} {
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    animation: ${props => props.isOpen ? fadeIn : fadeOut} .6s linear;
    transition: visibility .3s linear;    
  }
`;

type ActionsSectionProps = {
  right: string;
  top: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ActionsSection = styled.div<ActionsSectionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  position: absolute;
  top: ${ props => props.top};
  right: ${props => props.right};
  z-index: 1;
`;

type ZoomImageWrapperProps = {
  imageWidth: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ZoomImageWrapper = styled.div<ZoomImageWrapperProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & img {
    height: auto;
    width: auto;
    max-height:  100%;
    max-width:  ${props => props.imageWidth};
  }
`;

export { ZoomGalleryWrapper, ZoomImageWrapper, ImagesContainer, ActionsSection };
