import React from 'react';
import { TabSize, TabType } from '../Shared/types';
import { Container, TabOptions, Text } from './ItemTab.styled';

const ItemTab = ({ active, onClicked, children, tabType = TabType.SEPARATE, tabSize = TabSize.NORMAL }: TabOptions) => {
  return (
    <Container active={active} onClick={onClicked} tabType={tabType} tabSize={tabSize}>
      <Text tabType={tabType} tabSize={tabSize}>
        {children}
      </Text>
    </Container>
  );
};

export { ItemTab };
