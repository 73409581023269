import { GalleryImagesProps } from './GalleryImages';
import styled from 'styled-components';

type GalleryWrappersProps = {
  disabled?: boolean;
};
const GalleryWrapper = styled.div<GalleryWrappersProps>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 28px);
  max-width: 794px;
  max-height: 424px;
  gap: 0.5rem;
  position: relative;
  ${props => props.disabled && `opacity: 0.48;`}

  img {
    height: 100%;
    width: auto;
    object-fit: cover;
    cursor: pointer;

    :hover {
      transition: all 400ms ease-in;
      transform: scale(1.08);
      cursor: crosshair;
    }
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  @media (min-width: 1200px) {
    max-width: 1120px;
    max-height: 606px;
    grid-template-rows: repeat(12, 43px);
  }
`;

const MainImage = styled.div`
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 9;
  border-radius: 1rem 0 0 0;
`;

const SecondImage = styled.div`
  grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 9;
  border-radius: 0 1rem 0 0;
  @media (min-width: 1200px) {
    img {
      width: auto;
      height: 100%;
    }
  }
`;

const ThirdImage = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 9;
  grid-row-end: 13;
  border-radius: 0 0 0 1rem;
  img {
    width: 100%;
    height: auto;
  }
`;
const FourthImage = styled.div`
  grid-column-start: 6;
  grid-column-end: 10;
  grid-row-start: 9;
  grid-row-end: 13;
`;
const FifthImage = styled.div`
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 9;
  grid-row-end: 13;
  border-radius: 0 0 1rem 0;
`;

type CarouselMobileContainerProps = {
  margin?: string;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CarouselMobileContainer = styled.div<CarouselMobileContainerProps>`
  width: 100vw;
  height: auto;
  max-height: 240px;
  ${props => props.disabled && `opacity: 48%`};
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
  `};

  ol {
    height: 240px;
  }
`;

export { GalleryWrapper, SecondImage, MainImage, ThirdImage, FourthImage, FifthImage, CarouselMobileContainer };
