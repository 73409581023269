import styled from 'styled-components';

export type CircleIconOptions = {
  refIcon?: string;
  appearance?: string;
  widthIcon?: string;
  heightIcon?: string;
  viewBoxIcon?: string;
  marginIcon?: string;
};

enum APPEARANCES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  PRIMARYBLUE = 'primaryBlue',
  ORANGE = 'orange',
  DISABLED = 'disabled',
  DANGER = 'danger',
  SUCCESS = 'success'
}

export const Container = styled.div<CircleIconOptions>`
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: 2px;
  background-color: ${props => props.theme.colors.bgSecondaryBtn};

  svg {
    margin-top: 9px;
  }

  ${props =>
    props.marginIcon &&
    `
    svg {
      margin: ${props.marginIcon};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.PRIMARY &&
    `
    background-color: ${props.theme.colors.bgSecondaryBtn} !important;
    
    svg {
      color: ${props.theme.colors.bgPrimaryBtn};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.SECONDARY &&
    `
    background-color: ${props.theme.colors.bgSecondaryBtn} !important;
    svg {
      color: ${props.theme.colors.textSecondaryBtn};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.DANGER &&
    `
    background-color: ${props.theme.colors.bgLabelAdvertency} !important;
    svg {
      color: ${props.theme.colors.bgWarning};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.SUCCESS &&
    `
    background-color: ${props.theme.colors.bgLabelSuccess} !important;
    svg {
      color: ${props.theme.colors.bgSuccess};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.PRIMARYBLUE &&
    `
    background-color: ${props.theme.colors.bgSecondaryTab} !important;
    svg {
      color: ${props.theme.colors.bgPrimaryBtn};
    }
  `};

  ${props =>
    props.appearance === APPEARANCES.ORANGE &&
    `
      background-color: ${props.theme.colors.bgCircleIconPhoto} !important;
      svg {
        color: ${props.theme.colors.iconCircleIconPhoto}
      }
    `};

  ${props =>
    props.appearance === APPEARANCES.DISABLED &&
    `
      background-color: ${props.theme.colors.bgDefaultLabel} !important;
      svg {
        color: ${props.theme.colors.textDisable};
      }
    `};
`;
