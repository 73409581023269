import React from "react";
import { typography } from "./../../shared/designTokens";
import styled from "styled-components";

type ChipBackgroundProps = {
  borderColor: string;
  backgroundColor: string;
  fontColor: string;
  fontSize: number;
  fontWeight: number;
  lineHeight?: number;
  withBorder?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const ChipBackground = styled.div<ChipBackgroundProps>`
  ${({ withBorder }) => withBorder && 'border: 1px solid'};
  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`};
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
  border-radius: 16px;
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `${typography.size.sm1}px`)};
  height: fit-content;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px`};
  padding: 6px 16px;
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  width: fit-content;
`;
