import { ButtonProps, StyledButton } from '../../components/Buttons/Button.styled';
import styled from 'styled-components';
import { hex2rgba } from '../../shared/utils';

export enum HorizontalPosition {
  left = 'left',
  veryLeft = 'veryLeft',
  right = 'right',
  veryRight = 'veryRight',
  center = 'center'
}
export enum VerticalPosition {
  top = 'top',
  veryTop = 'veryTop',
  bottom = 'bottom',
  veryBottom = 'veryBottom',
  center = 'center',
}

export type FabProps = {
  horizontalPosition?: HorizontalPosition;
  verticalPosition?: VerticalPosition;
  isTransparent?: boolean;
  isTransparentDark?: boolean;
  marginX?: number;
  marginY?: number;
  isTransparentBlue?: boolean;
  active?: boolean;
} & ButtonProps;

const StyledFav = styled(StyledButton)<FabProps>`
  position: absolute;
  z-index: 1;
  border-radius: 24px;

  ${props =>
    props.horizontalPosition === HorizontalPosition.center &&
    `
      left: 50%;
      transform: translateX(-50%);
    `};

  ${props =>
    props.horizontalPosition === HorizontalPosition.left &&
    `
      left: ${props.marginX ? `${props.marginX}px` : '2rem'};
    `};

  ${props =>
    props.horizontalPosition === HorizontalPosition.veryLeft &&
    `
      left: ${props.marginX ? `${props.marginX}px` : '1rem'};
    `};

  ${props =>
    props.horizontalPosition === HorizontalPosition.right &&
    `
      right: ${props.marginX ? `${props.marginX}px` : '2rem'};
    `};

  ${props =>
    props.horizontalPosition === HorizontalPosition.veryRight &&
    `
      right: ${props.marginX ? `${props.marginX}px` : '1rem'};
    `};

  ${props =>
    props.verticalPosition === VerticalPosition.top &&
    `
      top: ${props.marginY ? `${props.marginY}px` : '2rem'};
    `};

  ${props =>
    props.verticalPosition === VerticalPosition.veryTop &&
    `
      top: ${props.marginY ? `${props.marginY}px` : '1rem'};
    `};

  ${props =>
    props.verticalPosition === VerticalPosition.bottom &&
    `
      bottom: ${props.marginY ? `${props.marginY}px` : '2rem'};
    `};

  ${props =>
    props.verticalPosition === VerticalPosition.veryBottom &&
    `
      bottom: ${props.marginY ? `${props.marginY}px` : '1rem'};
    `};

  ${props =>
    props.verticalPosition === VerticalPosition.center &&
    `
      top: 50%;
      transform: translateY(-50%);
    `};
  ${props =>
    props.withoutBorder &&
    `
      border: none !important;
      `};
  ${props =>
    props.isTransparent &&
    `
      background-color: ${hex2rgba(props.theme.colors.bgGrayBtn, 0.6)} !important;
      color: ${props.theme.colors.textPrimaryBtn} !important;
      `};
  ${props =>
    props.isTransparentDark &&
    `
      background-color: ${hex2rgba(props.theme.colors.textSecondaryBtn, 0.6)} !important;
      color: ${props.theme.colors.textPrimaryBtn} !important;
      `};
  ${props =>
    props.isTransparentBlue &&
    `
      background-color: ${hex2rgba(props.theme.colors.textSecondaryBtn, 0.6)} !important;
      color: ${props.theme.colors.textPrimaryBtn} !important;

      :hover, :active {
        background-color: ${hex2rgba(props.theme.colors.bgSecondaryBtn, 0.6)} !important;
        color: ${props.theme.colors.textBrand} !important;
      }
      ${
        props.active &&
        `
        background-color: ${hex2rgba(props.theme.colors.bgSecondaryBtn, 0.6)} !important;
        color: ${props.theme.colors.textBrand} !important;
        `
      }
    `};

  ${props => props.fontColor && `color: ${props.fontColor} !important;`};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor} !important;`};
  ${props => props.fontSize && `font-size: ${props.fontSize} !important;`};
  ${props => props.fontWeight && `font-weight: ${props.fontWeight} !important;`};
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow} !important;`};
`;

export { StyledFav };
