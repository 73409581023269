import React, { useEffect, useState } from 'react';
import { Icon } from '../../../Icon';
import { icons } from '../../../shared/icons';
import {
  StepperSimpleProps,
  Circle,
  CircleBg,
  CircularChart,
  StepperWrapper,
  ContainerIcon,
  Svg,
  Path,
} from './StepperSimple.styled';

export function StepperSimple({
  refIcon,
  steps,
  activeStep,
  appearance,
  linearColorPrimary,
  linearColorSecondary,
}: StepperSimpleProps) {
  const getProgressPercent = () => {
    return ((activeStep + 1) * 100) / steps.length;
  };

  return (
    <StepperWrapper>
      <ContainerIcon appearance={appearance}>
        <Svg viewBox="0 0 1024 1024" width="26px" height="26px">
          <Path appearance={appearance} d={icons[refIcon]} />
        </Svg>
      </ContainerIcon>
      <CircularChart viewBox="0 0 36 36">
        <linearGradient id="gradient" x1="41%" y1="-31%" x2="111%" y2="79%">
          <stop offset="-3.21%" stop-color={linearColorPrimary || '#D250E6'} />
          <stop offset="93.93%" stop-color={linearColorSecondary || '#FFC130'} />
        </linearGradient>
        <CircleBg d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
        <Circle
          appearance={appearance}
          percent={getProgressPercent()}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </CircularChart>
    </StepperWrapper>
  );
}
