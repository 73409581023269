import { SIZES } from './../../../shared/types';
import styled from 'styled-components';

export enum APPEARANCES {
  DEFAULT = 'default',
  GRAY = 'gray',
  GREEN = 'green',
  ORANGE = 'orange',
  VIOLET = 'violet',
  YELLOW = 'yellow',
  GRAYE6 = 'grayE6',
}

export type LabelTagProps = {
  size?: SIZES;
  appearance?: APPEARANCES;
  children: React.ReactNode;
};

export const Container = styled.div<LabelTagProps>`
  height: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  width: fit-content;
  border-radius: 8px;
  padding: 6px 10px 22px 10px;
  box-shadow: 0px 10px 25px rgba(108, 117, 125, 0.059);
  font-size: ${props => props?.theme?.typography?.size?.sm1}px;
  background: ${props => props?.theme?.colors?.bgDefaultLabel};
  color: ${props => props?.theme?.colors?.textSecondaryTab};

  //SIZES
  ${props =>
    props.size === SIZES.SMALL &&
    `
      font-size: ${props.theme.typography.size.sm2}px;
  `}

  ${props =>
    props.size === SIZES.MEDIUM &&
    `
     font-size: ${props.theme.typography.size.sm3}px;
  `}

  ${props =>
    props.size === SIZES.BIG &&
    `
     font-size: ${props.theme.typography.size.sm4}px;
  `}

  //VARIANTS
  ${props =>
    props.appearance === APPEARANCES.DEFAULT &&
    `
    background: ${props?.theme?.colors?.bgDefaultLabel};
    color: ${props?.theme?.colors?.textSecondaryTab};
  `}

  ${props =>
    props.appearance === APPEARANCES.GREEN &&
    `
    background: ${props?.theme?.colors?.bgLabelSuccess};
    color: ${props?.theme?.colors?.textLabelSuccess};
    `}

  ${props =>
    props.appearance === APPEARANCES.YELLOW &&
    `
    background: ${props?.theme?.colors?.bgLabelInformative};
    color: ${props?.theme?.colors?.textLabelInformative};
    `}
    
  ${props =>
    props.appearance === APPEARANCES.VIOLET &&
    `
    background: ${props?.theme?.colors?.bgLabelManifest};
    color: ${props?.theme?.colors?.textLabelManifest};
    `}

  ${props =>
    props.appearance === APPEARANCES.ORANGE &&
    `
    background: ${props?.theme?.colors?.bgLabelAdvertency};
    color: ${props?.theme?.colors?.textLabelAdvertency};
    `}
    
  ${props =>
    props.appearance === APPEARANCES.GRAY &&
    `
    background: ${props?.theme?.colors?.textSecondaryTab};
     color: ${props?.theme?.colors?.bgSecondaryBtn};
    `}

    ${props =>
    props.appearance === APPEARANCES.GRAYE6 &&
    `
    background: ${props?.theme?.colors?.bgSecondaryActiveBtn};
     color: ${props?.theme?.colors?.textSecondaryTab};
    `}
`;
