import styled from 'styled-components';

export type AccordionContainerProps = {
  width?: string;
} & React.HTMLAttributes<HTMLDivElement>;


export const Container = styled.div<AccordionContainerProps>`
  overflow: hidden;
  width: ${({ width }) => (width ? width : '100%')};
`;

export type AccordionLongDescriptionProps = {
  isExpanded: boolean;
  visibleHeight: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Description = styled.div<AccordionLongDescriptionProps>`
  margin: 0;
  font-weight: 500;
  transition: all 0.3s ease-out;
  color: ${props => props.theme.colors.textSecondaryTab};
  font-size: ${props => props.theme.typography.size.sm2}px;
  line-height: ${props => props.theme.typography.size.sm5}px;
  transform-origin: top;
  margin-bottom: 16px;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  max-height: ${({ isExpanded: isDisplay, visibleHeight }) => (isDisplay ? '50rem' : visibleHeight)};
  
  @media(min-width: 600px) {
    font-size: ${props => props.theme.typography.size.sm3}px;
    line-height: ${props => props.theme.typography.size.md2}px;
  }
`;


export type AccordionButtonProps = {
  isDisplay: boolean;
};

export const Button = styled.span`
  padding: 4px 12px;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 16px;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.typography.size.sm2}px;
  line-height: ${props => props.theme.typography.lineHeight.sm5}px;
  
  
  svg {
    height: 16px;
    width: 16px;
    transition: all 0.25s ease-out;
  }

  @media(min-width: 600px) {
    line-height: ${props => props.theme.typography.size.md1}px;
  }
`;
