import React, { useState } from 'react';
import { Container, DateSlot, DayOfWeek, DayNum, DateSelectorSimpleProps } from './DateSelector.styled';

const DateSelectorSimple = ({ maxRangeDaysAvailable = 7, onSelectedDay }: DateSelectorSimpleProps) => {
  const [selectedDay, setSelectedDay] = useState({ name: null, number: null });

  var dateToday = new Date();

  let daysWeekAvailable = new Array(maxRangeDaysAvailable)
    .fill(null)
    .map(() => dateToday.setDate(dateToday.getDate() + 1));

  function buildDayOfWeekName(dayUnformatted: number) {
    const dayOfWeekName = new Date(dayUnformatted).toLocaleDateString('es-ES', { weekday: 'short' });
    return dayOfWeekName && dayOfWeekName[0].toUpperCase() + dayOfWeekName.slice(1);
  }

  function buildDayOfWeekNumber(dayUnformatted: number) {
    return new Date(dayUnformatted).toLocaleDateString('es-ES', { day: '2-digit' });
  }

  function buildDateCompleteFormatted(dateUnformatted: number) {
    return new Date(dateUnformatted).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  return (
    <Container>
      {daysWeekAvailable.map(day => {
        const dayOfWeekNameDisplay = buildDayOfWeekName(day);
        const dayOfWeekNumberDisplay = buildDayOfWeekNumber(day);
        const checkedDaySelected =
          dayOfWeekNameDisplay === selectedDay.name && dayOfWeekNumberDisplay === selectedDay.number;

        return (
          <DateSlot
            key={day}
            onClick={() => {
              setSelectedDay({
                name: dayOfWeekNameDisplay,
                number: dayOfWeekNumberDisplay,
              });
              onSelectedDay(buildDateCompleteFormatted(day));
            }}
            active={checkedDaySelected}
          >
            <DayOfWeek>{dayOfWeekNameDisplay}</DayOfWeek>
            <DayNum>{dayOfWeekNumberDisplay}</DayNum>
          </DateSlot>
        );
      })}
    </Container>
  );
};

export { DateSelectorSimple };
