import styled from 'styled-components';

export type DateSlotOptions = {
  key: number;
  active: Object;
};

export type DateSelectorSimpleProps = {
  maxRangeDaysAvailable?: number;
  onSelectedDay?: any;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  gap: 8px;
`;

export const DateSlot = styled.div<DateSlotOptions>`
  min-width: 72px;
  height: 92px;
  background: ${props => (props.active ? '#0055FB' : '#FFFFFF')};
  box-shadow: 0px 10px 25px ${props => (props.active ? '#5F676E66' : '#6C757D26')};
  border-radius: 16px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  text-align: center;

  :hover {
    box-shadow: 0px 10px 25px #5f676e66;
  }

  div {
    color: ${props => props.active && '#FFFFFF'};
  }
`;

export const DayOfWeek = styled.div`
  letter-spacing: -0.008em;
  margin: 4px 0px;
  font-size: ${({ theme }) => theme.typography.size.sm4}px;
  line-height: ${({ theme }) => theme.typography.lineHeight.md2}px;
  font-weight: ${({ theme }) => theme.typography.weight.black};
  color: ${({ theme }) => theme.colors.textNoteDescription};
`;

export const DayNum = styled.div`
  letter-spacing: -0.024em;
  font-size: ${({ theme }) => theme.typography.size.md4}px;
  line-height: ${({ theme }) => theme.typography.lineHeight.lg1}px;
  font-weight: ${({ theme }) => theme.typography.weight.black};
  color: ${({ theme }) => theme.colors.textColorDate};
`;
