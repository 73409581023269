import React, { FC } from 'react';
import { colorPrimitives, typography } from './../../shared/designTokens';
import { ChipBackground } from './Chip.styled';

type BasicChipProps = {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeigh?: number;
  textColor?: string;
  withBorder?: boolean;
};

const BasicChip: FC<BasicChipProps> = ({
  backgroundColor,
  borderColor,
  children,
  color,
  fontSize,
  fontWeight,
  lineHeigh,
  textColor,
  withBorder,
}) => {
  return (
    <ChipBackground
      borderColor={borderColor || color}
      backgroundColor={backgroundColor || color}
      fontColor={textColor || color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeigh}
      withBorder={withBorder}
    >
      {children}
    </ChipBackground>
  );
};

export { BasicChip };
