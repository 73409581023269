import React from 'react';
import { Icon } from '../../../Icon';
import { Container, CircleIconOptions } from './CircleIcon.styled';

const CircleIcon = ({ refIcon, appearance, widthIcon, heightIcon, viewBoxIcon, marginIcon }: CircleIconOptions) => {
  return (
    <Container appearance={appearance} marginIcon={marginIcon}>
      <Icon
        icon={refIcon}
        block={false}
        width={widthIcon || '20px'}
        height={heightIcon || '20px'}
        viewBox={viewBoxIcon || '0 0 1024 1024'}
      />
    </Container>
  );
};

export { CircleIcon };
