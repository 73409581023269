import styled from 'styled-components';

type DataTemplate = {
  color: string;
  value: number;
  key?: string | number;
  title?: string | number;
  [key: string]: any;
}[];

export type PieChartSimpleProps = {
  data: DataTemplate;
  lineWidth?: number; // %
  startAngle?: number;
  lengthAngle?: number;
  totalValue?: number;
  paddingAngle?: number;
  rounded?: boolean;
  segmentsShift?: number; // numberor:(segmentIndex) => number
  segmentsStyle?; // CSSObjector:(segmentIndex) => CSSObject
  segmentsTabIndex?: number;
  label?; // (labelRenderProps) => string | number | ReactElement
  labelPosition?: number; // %
  labelStyle?; // CSSObjector:(segmentIndex) => CSSObject
  animate?: boolean;
  animationDuration?: number;
  animationEasing?: string;
  reveal?: number; // %
  background?: string;
  children?; //	ReactElement (svg)
  radius?: number; // (user units)
  center?; // number, number]
  viewBoxSize?; //	[number, number]
  onBlur?; //	(e, segmentIndex) => void
  onClick?; //	(e, segmentIndex) => void
  onFocus?; //	(e, segmentIndex) => void
  onKeyDown?; //	(e, segmentIndex) => void
  onMouseOut?; //	(e, segmentIndex) => void
  onMouseOver?; //	(e, segmentIndex) => void
};

export const Container = styled.div`
height: 186px;
`;
