import React from 'react';
import { BoxActionSimpleProps, BubbleContainer, Container, Subtitle, Title } from './BoxActionSimple.styled';
import { AptunoButton } from '../../../components/Buttons';
import { SIZES, VARIANTS } from '../../../shared/types';
import { Icon } from '../../../Icon';
import { CopyOutputText } from '../../../components/Outputs/CopyOutputText/CopyOutputText';

const BoxActionSimple = ({
  title,
  iconBubbleSrc,
  subtitle,
  widthContainer,
  heightContainer,
  textButton,
  iconButton,
  textToCopy,
  titleFontSize,
  titleLineHeight,
  titleMaxWidth,
  onClickAptunoButton,
  onClickCustom,
}: BoxActionSimpleProps) => {
  return (
    <Container widthContainer={widthContainer} heightContainer={heightContainer}>
      <Title titleFontSize={titleFontSize} titleLineHeight={titleLineHeight} titleMaxWidth={titleMaxWidth}>
        {title}
      </Title>
      <BubbleContainer>
        <img src={iconBubbleSrc} alt="Plataforma de arriendo | Aptuno" />
      </BubbleContainer>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {textButton && (
        <AptunoButton
          appearance={VARIANTS.SECONDARY}
          size={SIZES.MEDIUM}
          onClick={() => onClickAptunoButton()}
          fitWidth={true}
        >
          <Icon width={'12px'} height={'12px'} icon={iconButton} />
          {textButton}
        </AptunoButton>
      )}

      {textToCopy && (
        <CopyOutputText
          textToCopy={textToCopy}
          heightContainer="auto"
          widthContainer={'100%'}
          onClickCustom={onClickCustom}
        />
      )}
    </Container>
  );
};

export { BoxActionSimple };
