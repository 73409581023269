import { VARIANTS } from './../../shared/types';
import styled from 'styled-components';

export type NavbarWrapperProps = {
  variant?: VARIANTS;
} & React.HTMLAttributes<HTMLDivElement>;

const NavbarWrapper = styled.nav<NavbarWrapperProps>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: 'center';
  box-sizing: 'border-box';
  -webkit-font-smoothing: 'antialiased';
  -moz-osx-font-smoothing: 'grayscale';
  position: 'relative';
  height: 4.375rem;
  width: 100%;
  background-color: ${props => props.theme.colors.bgNavbarPrimary};
  color: ${props => props.theme.colors.textNavbarPrimary};
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.navBarBorder};
  z-index: 60;
`;

type NavbarBrandWrapperProps = {
  isMobile: boolean;
};
const NavbarBrandWrapper = styled.a<NavbarBrandWrapperProps>`
  padding: 5px 0;
  text-decoration: none;
  cursor: pointer;
  margin-left: ${({ isMobile }) => (isMobile ? '41px' : '1rem')};
  display: flex;

  ${props =>
    props.isMobile &&
    `
  img {
    width: 110px;
  }
  `}
`;
const NavbarLinksWrapper = styled.ul`
  background: transparent;
  display: flex;
  gap: 20px;
  padding-left: 0;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.sm2}px;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.textNavbarPrimary};
  }
  a:hover {
    color: ${props => props.theme.colors.textNavbarPrimaryActive};
  }
`;
const NavbarLink = styled.a`
  text-decoration: none;
`;
const NavbarActionsWrapper = styled.ul`
  right: 0;
  position: absolute;
  right: 1rem;
`;

const MainLink = styled.div`
  background: #e6edff;
  border-radius: 24px;
  align-items: center;
  padding: 8px 12px;

  a {
    color: #0055fb;
  }
`;

export { NavbarWrapper, NavbarLinksWrapper, NavbarBrandWrapper, NavbarActionsWrapper, NavbarLink, MainLink };


