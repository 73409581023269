import styled from 'styled-components';

const FileUploaderContainer = styled.div<{ backgroundColor?: string, minHeight?: string }>`
  width: 100%;
  height: 100%;
  min-height: ${({ minHeight }) => minHeight};
  padding: 15px;
  border: 2px dashed #dee2e6;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FileUploaderInputFileField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  height: auto;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: none;

  &:focus {
    outline: none;
  }
`;

const FileUploaderComment = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
  margin: 8px 0px;
`;

const FileUploaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

export { FileUploaderContainer, FileUploaderInputFileField, FileUploaderComment, FileUploaderButtonsContainer };
