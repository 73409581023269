import React, { useState } from 'react';
import { Container, Description, Button } from './AccordionLong.styled';
import { Icon } from '../../../Icon';

type AccordionLongProps = {
  width: string, 
  height: string,
  children: React.ReactNode, 
  buttonTextPlus: string,
  buttonTextMinor: string,
  refIcon: string 
};

function AccordionLong({ width, height, buttonTextPlus, buttonTextMinor, refIcon, children }: AccordionLongProps) {
  const [isExpanded, setExpanded] = useState(false);

  const toggle = (event: React.MouseEvent) => {
    event.preventDefault();
    setExpanded(!isExpanded);
  };

  const styledDescription = {
    maxHeight: isExpanded ? '50rem' : height
  };

  const styledSvg = {
    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
    'margin-left': isExpanded ? '9px' : '12px',
  };

  return (
    <Container width={width}>
      <Description isExpanded={isExpanded} visibleHeight={height}>{children}</Description>
      <Button onClick={event => toggle(event)}>
        {isExpanded ? buttonTextMinor : buttonTextPlus }
        {refIcon ? <Icon icon={refIcon} block={false} style={styledSvg} /> : ''}
      </Button>
    </Container>
  );
}

export { AccordionLong };
