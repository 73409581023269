import styled from 'styled-components';

export type AccordionShortProps = {
  data?: any;
  width?: string;
  height?: string;
};

export const Container = styled.section<AccordionShortProps>`
  width: ${({ width }) => (width ? width : '100%')};
  position: relative;
  height: auto;
  border-radius: 16px;
`;

export const Wrap = styled.div<AccordionShortProps>`
  line-height: 14px;
  color: ${props => props.theme.colors.textSecondaryTab};
  font-size: ${props => props.theme.typography.size.sm2}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 8px;
  min-height: ${({ height }) => (height ? height : '100%')};
`;

export const SimbolIcon = styled.span`
  svg {
    height: 8px;
    margin-right: 18px;
    transition: all 0.25s ease-out;
    transform-origin: center;
  }
`;

export const Label = styled.h3`
  padding: 8px 8px 8px 16px;
  text-align: left;
  transition: all 0.3s ease;
  line-height: 18px !important;
  font-size: ${props => props.theme.typography.size.sm2}px !important;
  font-weight: 500 !important;

  @media screen and (min-width: 700px) {
    font-size: ${props => props.theme.typography.size.sm3}px !important;
    padding: 16px 16px 16px 16px;
  }
`;

export const Answer = styled.p`
  line-height: 16px;
  font-size: ${props => props.theme.typography.size.sm2}px;

  @media screen and (min-width: 600px) {
    font-size: ${props => props.theme.typography.size.sm3}px;
  }
`;

export const Subanswer = styled.div`
  font-size: ${props => props.theme.typography.size.sm1}px;
`;

export type AccordionDropdownProps = {
  isDisplay: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const AccordionDropdown = styled.div<AccordionDropdownProps>`
  color: #6c757d;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 8px !important;
  margin-left: 16px !important;
  line-height: 16px !important;
  font-size: ${props => props.theme.typography.size.sm2}px;
  height: auto;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
  max-height: ${({ isDisplay }) => (isDisplay ? '50rem' : '0')};
`;
