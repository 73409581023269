import styled, { css } from 'styled-components';
import { SIZES, VARIANTS } from '../../shared/types';

const getColor = (variant: VARIANTS, colors: Record<string, string>, type: 'font' | 'background'): string => {
  if (type === 'font') {
    switch (variant) {
      case VARIANTS.PRIMARY:
        return colors.bgPrimary;
      case VARIANTS.SECONDARY:
        return colors.bgSecondary;
      case VARIANTS.DANGER:
        return colors.bgDanger;
      case VARIANTS.SUCCESS:
        return colors.bgSuccess;
      case VARIANTS.WARNING:
        return colors.bgWarning;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgWarning;
      case VARIANTS.INFO:
        return colors.bgInfo;
      default:
        return colors.textSecondary;
    }
  } else {
    return colors.bgSecondary;
  }
};

const getPadding = (size: SIZES, paddings: Record<string, number>): string => {
  return '8px 16px';
};

export type DropdownListContentProps = {
  size?: SIZES;
  variant?: VARIANTS;
  openOnHover?: boolean;
  onlyLowerCase: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const DropdownListTitle = styled.span<DropdownListContentProps>`
  display: flex;
  gap: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  ${props => (props.onlyLowerCase ? 'text-transform: lowercase' : '')};
  line-height: 21px;
  color: ${({ variant, theme }) => {
    return getColor(variant, theme.colors, 'font');
  }};
  cursor: pointer;
  padding: 0;
`;

export const DropdownListContent = styled.div<DropdownListContentProps>`
  position: absolute;
  top: 100%;
  z-index: 9;
  float: right;
  font-size: ${props => getFontSize(props.size, props.theme.typography.size)};
  color: ${props => getColor(props.variant, props.theme.colors, 'font')};
  text-align: left;
  list-style: none;
  background-color: ${props => getColor(props.variant, props.theme.colors, 'background')};
  background-clip: padding-box;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1), 0 3px 8px rgba(0, 0, 0, 0.16);
  margin-top: ${props => (!!props.openOnHover ? '0px' : '4px')};

  ${props => dropdownMenuHidden(props)};
  ${props => isLeftOrRight(props)};
  ${props => isFullWidth(props)};
`;

const getFontSize = (size: SIZES, fontSizes: Record<string, string>): string => {
  return '12px';
};

const isLeftOrRight = props =>
  props.right
    ? css`
        right: 0;
      `
    : css`
        left: 0;
      `;

const dropdownMenuHidden = props =>
  props.hidden
    ? css`
        display: none;
      `
    : css`
        display: block;
      `;

const isFullWidth = props =>
  props.fullWidth
    ? css`
        min-width: 100%;
      `
    : null;

export const DropdownListItem = styled.li<DropdownListContentProps>`
  padding: ${({ size, theme }) => getPadding(size, theme.spacings)};
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  color: #555a64;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  &:hover,
  &:focus,
  &:active {
    ${props => dropdownItemActive(props)};
  }
  ${props => dropdownItemDisabled(props)};
`;

const dropdownItemActive = props =>
  css`
    text-decoration: none;
    color: ${getActiveColor(props.variant, props.theme.colors, 'font')};
    background-color: ${getActiveColor(props.variant, props.theme.colors, 'background')};
    opacity: ${props.variant == VARIANTS.WARNING_LIGHT ? 1 : 0.5};
  `;

const dropdownItemDisabled = props =>
  props.disabled &&
  css`
    text-decoration: none;
    color: ${props.theme.colors.bgDisabled};
    background-color: transparent;
  `;

const getActiveColor = (variant: VARIANTS, colors: Record<string, string>, type: 'font' | 'background'): string => {
  if (type === 'background') {
    switch (variant) {
      case VARIANTS.PRIMARY:
        return colors.bgPrimaryActiveBtn;
      case VARIANTS.SECONDARY:
        return colors.bgSecondaryActiveBtn;
      case VARIANTS.DANGER:
        return colors.bgDangerActiveBtn;
      case VARIANTS.SUCCESS:
        return colors.bgSuccess;
      case VARIANTS.WARNING:
        return colors.bgWarning;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgLabelAdvertency;
      case VARIANTS.INFO:
        return colors.bgInfo;
      default:
        return colors.textSecondary;
    }
  } else {
    switch (variant) {
      case VARIANTS.SECONDARY:
        return colors.textSecondary;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgWarning;
      default:
        return colors.textPrimary;
    }
  }
};

export const ArrowIcon = styled.span<{ animation }>`
  transform: rotate(180deg);
  display: inline-flex;
  align-items: center;
  align-content: center;

  svg {
    transition: all 0.15s ease-out;
    transform-origin: 65% 48%;
    transform: ${({ animation }) => (animation == 'true' ? ' translateX(-4px) rotate(180deg)' : '')};
    width: 11px !important;
    height: 11px !important;
  }
`;
