enum GeoLocales {
  CO = 'CO',
  MX = 'MX',
}

export const LinkRelevantError404 = countryCode => {
  switch (countryCode) {
    case GeoLocales.CO:
      return [
        {
          title: 'Descubre inmuebles para alquilar',
          href: 'https://www.aptuno.com/inmuebles-arriendo',
        },
        {
          title: 'Publica tu inmueble',
          href: 'https://www.aptuno.com/publicar-inmueble-en-arriendo/',
        },
        {
          title: 'Refiere propiedades y gana dinero',
          href: 'https://www.refiereygana.aptuno.com/',
        },
        {
          title: 'Conoce Aptuno, su equipo y misión',
          href: 'https://www.aptuno.com/quienes-somos',
        },
      ];
    case GeoLocales.MX:
      return [
        {
          title: 'Descubre inmuebles para alquilar',
          href: 'https://www.aptuno.mx/inmuebles-arriendo',
        },
        {
          title: 'Publica tu inmueble',
          href: 'https://www.aptuno.com.mx/anunciar-casa-o-departamento-en-renta/',
        },
        {
          title: 'Refiere inmuebles y gana dinero',
          href: 'https://www.refiereygana.aptuno.mx/',
        },
        {
          title: 'Conoce Aptuno, su equipo y misión',
          href: 'https://www.aptuno.mx/quienes-somos',
        },
      ];
    default:
      return [
        {
          title: 'Descubre inmuebles para alquilar',
          href: 'https://www.aptuno.com/inmuebles-arriendo',
        },
        {
          title: 'Publica tu inmueble',
          href: 'https://www.aptuno.com/publicar-inmueble-en-arriendo/',
        },
        {
          title: 'Refiere propiedades y gana dinero',
          href: 'https://www.refiereygana.aptuno.com/',
        },
        {
          title: 'Conoce Aptuno, su equipo y misión',
          href: 'https://www.aptuno.com/quienes-somos',
        },
      ];
  }
};
