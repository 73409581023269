import styled from 'styled-components';

export enum APPEARANCES {
  DEFAULT = 'default',
  INFORMATIVE = 'informative',
  INFORMATIVE_PRIMARY = 'informative_primary',
  INFORMATIVE_SECONDARY = 'informative_secondary',
  INFORMATIVE_TERTIARY = 'informative_tertiary',
  WARNING = 'warning',
}

export enum ALIGNMENT {
  INVERTED = 'inverted',
}

export type BoxInformativeProps = {
  appearance?: string;
  refIcon?: string;
  responsive?: boolean;
  alignment?: string;
  children?: React.ReactNode;
};

export const Container = styled.div<BoxInformativeProps>`
  display: flex;
  padding: 16px;
  width: 325px;

  background: #f0f0f0;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;

  ${props =>
    props.responsive &&
    `
    max-width: 325px;
    height: auto

    div {
      font-size: 14px;
    }
`}

  ${props =>
    props.alignment === ALIGNMENT.INVERTED &&
    `
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-right:26px;
  `}

  ${props =>
    props.appearance == APPEARANCES.DEFAULT &&
    `


    background-color:${props.theme.colors.bgDefaultLabel};
    color: ${props.theme.colors.textSecondaryLightBtn};

    svg {
      color: ${props.theme.colors.textBrand};
    }
`}

  ${props =>
    props.appearance == APPEARANCES.INFORMATIVE &&
    `
    background-color:${props.theme.colors.bgLabelInformative};
    color: ${props.theme.colors.textSecondaryBtn};

    svg {
      color: ${props.theme.colors.textLabelInformative};
    }
`}

${props =>
    props.appearance == APPEARANCES.INFORMATIVE_PRIMARY &&
    `
    background-color:${props.theme.colors.bgSecondaryTab};
    color: ${props.theme.colors.textSecondaryLightBtn};

    svg {
      color: ${props.theme.colors.bgBrand};
    }
`}

${props =>
    props.appearance == APPEARANCES.INFORMATIVE_SECONDARY &&
    `
    background-color:${props.theme.colors.bgGrayContainer};
    color: ${props.theme.colors.textSecondaryLightBtn};

    svg {
      color: ${props.theme.colors.bgBrand};
    }
`}

${props =>
    props.appearance == APPEARANCES.INFORMATIVE_TERTIARY &&
    `
  background-color: #555A64;
  color: #FFFFFF;

  svg {
    color: #FFFFFF;
  }
`}

${props =>
    props.appearance == APPEARANCES.WARNING &&
    `
    background-color:${props.theme.colors.bgWarningBox};
    color: ${props.theme.colors.bgDangerBtn};

    svg {
      color: ${props.theme.colors.bgDangerBtn};
    }
`}
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Right = styled.div<BoxInformativeProps>`
  margin-left: 10px;
  min-height: 40px;
  margin-right: 16px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${props =>
    props.responsive &&
    `
    max-width: 261px;
    margin-top:0;

`}
`;
