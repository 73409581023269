import { Hexcode } from './types';

const baseColors = {
  BLACK: '#000000',
  WHITE: '#ffffff',
  BLUE: '#0055FB',
};

const secondaryColors = {
  ORANGE: '#FF784B',
  YELLOW: '#FACD23',
  GREEN: '#3CD278',
  RED: '#F0323C',
  PURPLE: '#D250E6',
};

const grayColors = {
  GRAY: '#6C757D',
  'GRAY-78': '#787878 ',
  'GRAY-E6': '#DEE2E6',
  'GRAY-9B': '#9B9B9B',
  'GRAY-CD': '#CDCDCD',
  'GRAY-DC': '#DCDCDC',
  'GRAY-F0': '#F0F0F0',
  'GRAY-F8': '#F8F8F8',
  'GRAY-64': '#555a64',
};

const extendedPalette = {
  'BLUE-110': '#0048D5',
  'BLUE-60': '#6F9AFF',
  'BLUE-40': '#A1BDFF',
  'BLUE-20': '#D0DEFF',
  'BLUE-10': '#E6EDFF',
  'ORANGE-110': '#FF5B25',
  'ORANGE-80': '#FD8D5D',
  'ORANGE-60': '#FFAE93',
  'ORANGE-40': '#FFC9B7',
  'ORANGE-20': '#FFE4DB',
  'ORANGE-10': '#FFF1ED',
  'YELLOW-110': '#FFBD11',
  'YELLOW-60': '#FFDE87',
  'YELLOW-40': '#FFE9AF',
  'YELLOW-20': '#FFF4D7',
  'YELLOW-10': '#FFF9EB',
  'GREEN-110': '#21AF5A',
  'GREEN-60': '#8AE4AE',
  'GREEN-40': '#B1EDC9',
  'GREEN-20': '#D8F6E4',
  'GREEN-10': '#EBFAF1',
  'RED-110': '#CC222B',
  'RED-60': '#F6848A',
  'RED-40': '#F9ADB1',
  'RED-20': '#FCD6D8',
  'RED-10': '#FDEAEB',
  'PURPLE-110': '#A22BB5',
  'PURPLE-60': '#E496F0',
  'PURPLE-40': '#EDB9F5',
  'PURPLE-20': '#F6DCFA',
  'PURPLE-10': '#FAEDFC',
};

export const breakpoints = {
  xsmall: 767,
  small: 980,
  medium: 1280,
  large: 1690,
};

export const mediaQueries = {
  xsmall: `@media screen and (min-width: ${breakpoints.xsmall})`,
  small: `@media screen and (min-width: ${breakpoints.small})`,
  medium: `@media screen and (min-width: ${breakpoints.medium})`,
  large: `@media screen and (min-width: ${breakpoints.large})`,
};

export const colorPrimitives = {
  main: { ...baseColors },
  secondary: { ...secondaryColors },
  grays: { ...grayColors },
  extendedPalette,
};

export const lightColorAliases: Record<string, Hexcode> = {
  // Aliases for buttons
  textBrand: colorPrimitives.main.BLUE,
  bgBrand: colorPrimitives.main.BLUE,
  bgPrimaryBtn: colorPrimitives.main.BLUE,
  bgSecondaryBtn: colorPrimitives.main.WHITE,
  bgPrimaryActiveBtn: colorPrimitives.extendedPalette['BLUE-110'],
  bgSecondaryActiveBtn: colorPrimitives.grays['GRAY-E6'],
  bgDangerBtn: colorPrimitives.secondary.RED,
  bgDangerActiveBtn: colorPrimitives.extendedPalette['RED-110'],
  bgDisabledBtn: colorPrimitives.grays['GRAY-DC'],
  textPrimaryBtn: colorPrimitives.main.WHITE,
  textSecondaryBtn: colorPrimitives.main.BLACK,
  textSecondaryLightBtn: colorPrimitives.grays.GRAY,
  textNoteDescription: colorPrimitives.grays.GRAY,
  textDisabledBtn: colorPrimitives.grays['GRAY-9B'],
  textDangerBtn: colorPrimitives.main.WHITE,
  borderPrimaryBtn: colorPrimitives.main.BLUE,
  borderSecondaryBtn: colorPrimitives.grays['GRAY-E6'],
  borderDangerBtn: colorPrimitives.extendedPalette['RED-110'],

  bgDisabled: colorPrimitives.grays['GRAY-DC'],
  bgWarning: colorPrimitives.secondary.ORANGE,
  bgWarningActive: colorPrimitives.extendedPalette['ORANGE-110'],
  bgWarningLightActive: colorPrimitives.extendedPalette['ORANGE-20'],
  bgWarningLight: colorPrimitives.extendedPalette['ORANGE-80'],
  textWarningLightActive: colorPrimitives.extendedPalette['ORANGE-110'],
  bgSuccess: colorPrimitives.secondary.GREEN,
  bgSuccessActive: colorPrimitives.extendedPalette['GREEN-110'],
  bgInfo: colorPrimitives.extendedPalette['BLUE-60'],
  bgInfoActive: colorPrimitives.main.BLUE,
  bgPrimary: colorPrimitives.main.BLUE,
  bgPrimaryActive: colorPrimitives.extendedPalette['BLUE-110'],
  bgSecondary: colorPrimitives.main.WHITE,
  bgSecondaryActive: colorPrimitives.grays['GRAY-E6'],
  bgDanger: colorPrimitives.secondary.RED,
  bgDangerActive: colorPrimitives.extendedPalette['RED-110'],
  bgDarken: colorPrimitives.main.BLACK,
  textPrimary: colorPrimitives.main.WHITE,
  textSecondary: colorPrimitives.main.BLACK,
  textGray: colorPrimitives.grays.GRAY,
  textDisable: colorPrimitives.grays['GRAY-9B'],
  textLight: colorPrimitives.grays['GRAY-CD'],
  bgSecondaryTab: colorPrimitives.extendedPalette['BLUE-10'],
  bgWhiteSmoke: colorPrimitives.extendedPalette['WHITE-10'],
  textSecondaryTab: colorPrimitives.grays['GRAY'],
  bgDefaultLabel: colorPrimitives.grays['GRAY-F0'],
  bgLabelSuccess: colorPrimitives.extendedPalette['GREEN-10'],
  textLabelSuccess: colorPrimitives.extendedPalette['GREEN-110'],
  bgLabelManifest: colorPrimitives.extendedPalette['PURPLE-10'],
  textLabelManifest: colorPrimitives.extendedPalette['PURPLE-110'],
  bgLabelInformative: colorPrimitives.extendedPalette['YELLOW-10'],
  textLabelInformative: colorPrimitives.extendedPalette['YELLOW-110'],
  bgLabelAdvertency: colorPrimitives.extendedPalette['ORANGE-10'],
  textLabelAdvertency: colorPrimitives.extendedPalette['ORANGE-110'],

  bgGreenBtn: colorPrimitives.extendedPalette['GREEN-110'],
  bgPurpleBtn: colorPrimitives.extendedPalette['PURPLE-110'],
  bgYellowBtn: colorPrimitives.secondary.YELLOW,

  textNavbarPrimary: colorPrimitives.main.BLACK,
  textNavbarPrimaryActive: colorPrimitives.main.BLUE,
  bgNavbarPrimary: colorPrimitives.main.WHITE,
  bgSideBarPrimary: colorPrimitives.main.WHITE,
  bgBurguerMenu: colorPrimitives.main.BLACK,
  navBarBorder: colorPrimitives.grays['GRAY-E6'],
  bgGrayBtn: colorPrimitives.grays['GRAY-78'],
  bgGrayContainer: colorPrimitives.grays['GRAY-F8'],
  bgCarouselBadge: colorPrimitives.main.BLACK,
  bgZoomGallery: colorPrimitives.main.BLACK,
  bgCardDefault: colorPrimitives.main.WHITE,
  borderBrand: colorPrimitives.main.BLUE,
  borderGray: colorPrimitives.grays['GRAY-E6'],
  textSecondaryCard: colorPrimitives.grays['GRAY-78'],

  bgStepperSimpleFilling: colorPrimitives.secondary['PURPLE'],
  bgStepperSimpleFilled: colorPrimitives.secondary['YELLOW'],
  bgStepperSimpleaUX: colorPrimitives.secondary['GREEN'],
  bgCircleStepperSimple: colorPrimitives.grays['GRAY-E6'],

  bgCircleIconPhoto: colorPrimitives.extendedPalette['YELLOW-10'],
  iconCircleIconPhoto: colorPrimitives.extendedPalette['YELLOW-110'],
  bgInformativeBtn: colorPrimitives.extendedPalette['YELLOW-40'],
  bgInformativeBtnActive: colorPrimitives.extendedPalette['YELLOW-60'],
  bgWarningBox: colorPrimitives.extendedPalette['RED-10'],
  bgErrorInput: colorPrimitives.extendedPalette['RED-10'],
  boderErrorInput: colorPrimitives.extendedPalette['RED-20'],

  textColorDate: colorPrimitives.grays['GRAY-64'],
};

export const shadows = {
  '1-drop': '0px 10px 25px rgba(120, 120, 120, 0.06)',
  '2-drop': '0px 10px 25px rgba(108, 117, 125, 0.059)',
};

export const typography = {
  type: {
    primary: '"Satoshi", "Helvetica Neue", Helvetica, Arial, sans-serif',
    code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  weight: {
    ligth: '200',
    book: '300',
    regular: '400',
    bold: '500',
    black: '600',
  },
  size: {
    sm1: '12',
    sm2: '14',
    sm3: '16',
    sm4: '18',
    sm5: '20',
    md1: '22',
    md2: '24',
    md3: '28',
    md4: '30',
    lg1: '32',
    lg2: '34',
    lg3: '40',
    lg4: '44',
    lg5: '56',
    code: '90',
  },
  lineHeight: {
    sm1: '12',
    sm2: '14',
    sm3: '16',
    sm4: '18',
    sm5: '20',
    md1: '22',
    md2: '24',
    md3: '28',
    md4: '30',
    lg1: '32',
    lg2: '36',
    lg3: '40',
    lg4: '44',
    lg5: '56',
    code: '90',
  },
};

export const typographyHierarchy = {
  h1: {
    'font-size': typography.size.lg4,
    'line-height': '64px',
    'letter-spacing': '-0.026em',
  },
  h2: {
    'font-size': typography.size.lg3,
    'line-height': '50px',
    'letter-spacing': '-0.024em',
  },
  h3: {
    'font-size': typography.size.md2,
    'line-height': '36px',
    'letter-spacing': '-0.024em',
  },
  h4: {
    'font-size': typography.size.md1,
    'line-height': '24px',
    'letter-spacing': '-0.008em',
  },
  h5: {
    'font-size': typography.size.sm3,
    'line-height': '24px',
    'letter-spacing': '-0.008em',
  },

  h6: {
    'font-size': typography.size.sm2,
    'line-height': '22px',
    'letter-spacing': '-0.008em',
  },
  'p-lg': {
    'font-size': typography.size.md1,
    'line-height': '27px',
    'letter-spacing': '-0.008em',
  },
  'p-md': {
    'font-size': typography.size.sm3,
    'line-height': '24px',
    'letter-spacing': '-0.008em',
  },
  'p-sm': {
    'font-size': typography.size.sm2,
    'line-height': '22px',
    'letter-spacing': '-0.008em',
  },
  span: {
    'font-size': typography.size.sm1,
    'line-height': '16px',
    'letter-spacing': '-0.008em',
  },
};
