import React, { useState, useEffect, useRef } from 'react';
import { Container, FlagSelected, FlagOptions, FlagItem, FlagIcon, ArrowIcon } from './DropDownFlag.styled';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Icon } from '../../Icon';

export const CountriesList = [
  { uuid: 0, id: 'CO', name: 'Colombia', flag: '/images/col_flag.svg', url: 'https://www.aptuno.com' },
  { uuid: 1, id: 'MX', name: 'México', flag: '/images/mex_flag.svg', url: 'https://www.aptuno.mx' },
];

const DropDownFlag = ({ defaultCountryCode }) => {
  const [FlagActive, setFlagActive] = useState(CountriesList[0].flag);
  const wrapperRef = useRef(null);
  const { isListOpen, setIsListOpen } = useOutsideClick(wrapperRef);

  useEffect(() => {
    const flagDefault = CountriesList.filter(e => e.id === defaultCountryCode);
    if (flagDefault.length == 1) setFlagActive(flagDefault[0].flag);
  }, []);

  const toggling = index => {
    if (typeof index == 'number') window.location.href = CountriesList[index].url;
    setIsListOpen(!isListOpen);
  };

  return (
    <Container ref={wrapperRef} onClick={index => toggling(index)}>
      <FlagSelected border={isListOpen ? 'active' : ''}>
        <FlagIcon src={FlagActive} alt="Icono de Bandera | Aptuno" title="Icono de Bandera | Aptuno" />
        <ArrowIcon animation={isListOpen ? 'true' : 'false'}>
          <Icon icon={'caret'} />
        </ArrowIcon>
      </FlagSelected>
      {isListOpen && (
        <FlagOptions>
          {CountriesList.map((countryData, index) => {
            return (
              <FlagItem key={index}>
                <FlagIcon
                  src={countryData.flag}
                  onClick={() => toggling(index)}
                  alt="Icono de Bandera | Aptuno"
                  title="Icono de Bandera | Aptuno"
                />
              </FlagItem>
            );
          })}
        </FlagOptions>
      )}
    </Container>
  );
};

export { DropDownFlag };
