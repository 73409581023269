import styled from 'styled-components';

export interface CopyOutputTextProps {
  heightContainer?: string;
  widthContainer?: string;
  borderRadiusContainer?: string;
  backgroundContainer?: string;
  borderContainer?: string;
  textToCopy?: string;
  onClickCustom?: () => void;
}

export const Content = styled.div<CopyOutputTextProps>`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 20px;
  border-radius: ${({ borderRadiusContainer }) => (borderRadiusContainer ? borderRadiusContainer : '16px')};
  width: ${({ widthContainer }) => (widthContainer ? widthContainer : '280px')};
  height: ${({ heightContainer }) => (heightContainer ? heightContainer : '100%')};
  background: ${({ backgroundContainer }) => (backgroundContainer ? backgroundContainer : '#ffffff')};
  border: ${({ borderContainer }) => (borderContainer ? borderContainer : '2px solid #f6dcfa')};
  padding: 18px 15px 18px 24px;
  gap: 4px;

  svg {
    margin-top: -2px;
  }
`;

export const TextToCopy = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
`;
