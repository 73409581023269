import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Icon } from '../../../Icon';
import { CountryCode, formatMoney } from '../../../shared/formatMoney';
import {
  ButtonExpander,
  ChartRow,
  CircleColor,
  ChartContainer,
  InfoDetailed,
  ChartItem,
  Left,
  ChartList,
  PieChartSimpleProps,
  PierChartBox,
  Right,
  SummaryValues,
  TextSum,
  TextSummary,
  TextTitle,
  TextValue
} from './PieChartDetail.styled';

function PieChartDetail({
  data,
  lineWidth,
  startAngle,
  lengthAngle,
  totalValue,
  paddingAngle,
  rounded,
  segmentsShift,
  segmentsStyle,
  segmentsTabIndex,
  animate,
  animationDuration,
  animationEasing,
  reveal,
  background,
  radius,
  center,
  viewBoxSize,
  countryCode = CountryCode.CO,
  onClick,
  onMouseOut,
  onMouseOver,
}: PieChartSimpleProps) {
  const sumOfValues = data.reduce((a, b) => a + b.value, 0);
  const [expanded, setExpanded] = useState(false);

  const Toggle = () => {
    setExpanded(!expanded);
  };

  const styledSvg = {
    transform: expanded ? 'rotate(0deg)' : 'rotate(-180deg)',
    'margin-left': expanded ? '9px' : '12px',
    transition: 'all 0.2s ease-in-out',
    'transform-origin': 'center',
  };

  return (
    <ChartContainer>
      <ChartRow>
        <PierChartBox>
          <SummaryValues>
            <TextSummary>valor aprox. mes</TextSummary>
            <TextSum>{`$${formatMoney(sumOfValues, countryCode)}`}</TextSum>
          </SummaryValues>
          <PieChart
            data={data}
            lineWidth={lineWidth}
            startAngle={startAngle}
            lengthAngle={lengthAngle}
            totalValue={totalValue}
            paddingAngle={paddingAngle}
            rounded={rounded}
            segmentsShift={segmentsShift}
            segmentsStyle={segmentsStyle}
            segmentsTabIndex={segmentsTabIndex}
            animate={animate}
            animationDuration={animationDuration}
            animationEasing={animationEasing}
            reveal={reveal}
            background={background}
            radius={radius}
            center={center}
            viewBoxSize={viewBoxSize}
            onClick={onClick}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
          />
        </PierChartBox>
        <ButtonExpander display={expanded} onClick={Toggle}>
          {expanded ? 'Ver más' : 'Ver menos'}
          <Icon icon={'chevrondown'} block={false} style={styledSvg} />
        </ButtonExpander>
      </ChartRow>
      <InfoDetailed display={expanded}>
        <ChartList>
          {data.map((el, index) => {
            return (
              <ChartItem key={`chart-item-${index}`}>
                <Left>
                  <CircleColor backgroundColor={el.color} />
                </Left>
                <Right>
                  <TextTitle>{el.title}</TextTitle>
                  <TextValue>{`$${formatMoney(el.value, countryCode)}`}</TextValue>
                </Right>
              </ChartItem>
            );
          })}
        </ChartList>
      </InfoDetailed>
    </ChartContainer>
  );
}

export { PieChartDetail };
