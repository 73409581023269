import styled from 'styled-components';

// ***** V1 *****
const PrimaryButton = styled.button`
  width: 100%;
  max-width: 550px;
  height: 48px;
  padding: 0px 10px;
  background: #0055fb 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-width: 0px;
  border-style: none;
  color: #ffffff;
  line-height: normal;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed !important' : 'pointer')};

  @media screen and (max-width: 750px) {
    max-width: 77%;
  }
`;

const BorderButon = styled(PrimaryButton)`
  padding: 2px 1.5%;
  border: 2px solid #0055fb;
  background-color: #ffffff;
  color: #0055fb;
  font-weight: 700;
  position: relative;
  align-items: center;
  user-select: none;
`;

const BackBtn = styled(BorderButon)`
  display: inline-flex;
  justify-content: center;
  gap: 10px;
  min-width: 60px;
  max-width: 12%;
  position: relative;
  align-items: center;
  user-select: none;
`;

const Span = styled.span`
  text-align: center;
  @media screen and (max-width: 740px) {
    display: none;
  }
`;

const ErrorButton = styled(PrimaryButton)`
  background-color: #f0323c;
`;

// ***** V2 *****
const PrimaryButton2 = styled.button`
  width: ${({ width }) => (width ? width : '100%')};
  max-width: 383px;
  height: ${({ height }) => (height ? height : '48px')};
  background: ${({ background }) => (background ? background : '#0055fb 0% 0% no-repeat padding-box')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
  border-width: 0px;
  border-style: none;
  color: ${({ color }) => (color ? color : '#ffffff')};
  line-height: normal;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed !important' : 'pointer')};

  @media screen and (max-width: 750px) {
    max-width: 77%;
  }
`;

const BorderButon2 = styled(PrimaryButton2)`
  padding: 2px 1.5%;
  border: 2px solid #dee2e6;
  background: ${({ background }) => (background ? background : '#ffffff 0% 0% no-repeat padding-box')};
  font-weight: 700;
  position: relative;
  align-items: center;
  user-select: none;
  color: ${({ color }) => (color ? color : '#6c757d')};
  height: ${({ height }) => (height ? height : '48px')};
  width: ${({ width }) => (width ? width : '100%')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
`;

const BackBtn2 = styled(BorderButon2)`
  display: inline-flex;
  justify-content: center;
  gap: 11px;
  background: ${({ background }) => (background ? background : '#ffffff 0% 0% no-repeat padding-box')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '56px')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '20%')};
  position: relative;
  align-items: center;
  user-select: none;
  height: ${({ height }) => (height ? height : '48px')};
  width: ${({ width }) => (width ? width : '100%')};

  @media screen and (min-width: 750px) {
    max-width: 161px;
  }
`;

const Span2 = styled.span`
  text-align: center;
  color: ${({ color }) => (color ? color : '#6c757d')};

  @media screen and (max-width: 740px) {
    display: none;
  }
`;

const ErrorButton2 = styled(PrimaryButton2)`
  background: ${({ background }) => (background ? background : '#f0323c 0% 0% no-repeat padding-box')};
  color: ${({ color }) => (color ? color : '#ffffff')};
  height: ${({ height }) => (height ? height : '48px')};
  width: ${({ width }) => (width ? width : '100%')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
`;

export {
  PrimaryButton,
  BorderButon,
  ErrorButton,
  BackBtn,
  Span,
  PrimaryButton2,
  BorderButon2,
  ErrorButton2,
  BackBtn2,
  Span2,
};
