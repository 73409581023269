import React from 'react';
import { SIZES } from './../../shared/types';
import { ButtonProps, StyledButton, APPEARANCES, Loading, Load } from './Button.styled';

const defaultProps = {
  isLoading: false,
  isLink: false,
  appearance: APPEARANCES.PRIMARY,
  isDisabled: false,
  isUnclickable: false,
  containsIcon: false,
  size: SIZES.MEDIUM,
  inverted: false,
};

const ButtonLink = StyledButton.withComponent('a');

export function AptunoButton({
  appearance = APPEARANCES.PRIMARY,
  isDisabled = defaultProps.isDisabled,
  isLoading = defaultProps.isLoading,
  isLink = defaultProps.isLink,
  size = defaultProps.size,
  loadingText,
  name,
  children,
  isGrayText = false,
  ...props
}: ButtonProps) {
  const buttonInner = (
    <>
      {children}
      {isLoading && (
        <Loading>
          {loadingText} <Load />
        </Loading>
      )}
    </>
  );

  let SelectedButton: any = StyledButton;
  if (isLink) {
    SelectedButton = ButtonLink;
  }

  return (
    <SelectedButton
      arial-label={name || 'button'}
      size={size}
      appearance={appearance}
      isLoading={isLoading}
      disabled={isDisabled}
      isGrayText={isGrayText}
      {...props}
    >
      {buttonInner}
    </SelectedButton>
  );
}

export default AptunoButton;
