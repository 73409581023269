import React from 'react';
import { useResponsive } from '../../hooks/useResponsive';
import { ESCAPE } from '../../shared/keys';
import { CloseModal, ModalHead } from '../Modal/Modal.styled';
import { Portal } from '../Portal';
import { Backdrop, EaseInWrapper, EaseInWrapperMobile } from './EaseInModal.styled';

export type EaseInModalProps = {
  withBackdrop: boolean;
  isMobile: boolean;
  header: JSX.Element;
  children: JSX.Element;
  onClose: () => void;
};

export function EaseInModal({ withBackdrop = false, header, children, onClose }: EaseInModalProps) {
  const { isMobile } = useResponsive();
  const handleClose = () => {
    onClose();
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === ESCAPE) {
      handleClose();
    }
  };

  return (
    <Portal>
      {withBackdrop && <Backdrop onClick={() => handleClose()} />}
      {!isMobile && (
        <EaseInWrapper onKeyUp={handleKeyUp}>
          <ModalHead withoutShadow>
            <CloseModal
              onClick={() => {
                handleClose();
              }}
            />
            {header}
          </ModalHead>
          {children}
        </EaseInWrapper>
      )}
      {isMobile && (
        <EaseInWrapperMobile onKeyUp={handleKeyUp}>
          <ModalHead withoutShadow>
            <CloseModal
              onClick={() => {
                handleClose();
              }}
            />
            {header}
          </ModalHead>
          {children}
        </EaseInWrapperMobile>
      )}
    </Portal>
  );
}
