import React, { createRef } from 'react';
import { APPEARANCES, SIZES } from '../../components/Buttons/Button.styled';
import { FabProps, HorizontalPosition, StyledFav, VerticalPosition } from './Fab.styled';

const defaultProps: FabProps = {
  appearance: APPEARANCES.PRIMARY,
  containsIcon: false,
  size: SIZES.MEDIUM,
  horizontalPosition: HorizontalPosition.right,
  verticalPosition: VerticalPosition.bottom,
  active: false
};

function Fab({
  appearance = APPEARANCES.PRIMARY,
  size = defaultProps.size,
  horizontalPosition = defaultProps.horizontalPosition,
  verticalPosition = defaultProps.verticalPosition,
  loadingText,
  name,
  ...props
}: FabProps) {
  const ref = createRef<HTMLButtonElement>();
  
  return (
    <StyledFav
      ref={ref}
      arial-label={name || 'fab-button'}
      size={size}
      appearance={appearance}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      disabled={props.isDisabled}
      {...props}
    />
  );
};

export { Fab, HorizontalPosition, VerticalPosition };
