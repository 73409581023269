import styled from 'styled-components';

export const Title = styled.span`
  margin: 20px 0 8px;
  padding: 0;
  cursor: text;
  position: relative;
  color: #333333;
  font-size: 20px;
  font-weight: 900;
  vertical-align: super;
`;

export const DescriptionPrimary = styled.div`
  color: #6c757d;
  margin-top: 18px;
  padding-bottom: 18px;
`;

export const SubTitle = styled.div`
  margin: 20px 0 8px;
  padding: 0;
  cursor: text;
  position: relative;
  color: #333333;
  font-size: 20px;
  font-weight: 900;
  color: #0055fb;
`;

export const Status = styled.span`
  height: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  margin-right: 8px;
  width: fit-content;
  border-radius: 8px;
  padding: 4px 8px;
  box-shadow: 0px 10px 25px rgba(108, 117, 125, 0.059);
  font-size: 14px;
  font-style: italic;

  ${props => props.appearance == 'green' && `background: #EBFAF1; color: #21AF5A;`}
  ${props => props.appearance == 'violet' && `background: #FAEDFC; color: #A22BB5;`}
  ${props => props.appearance == 'yellow' && `background: #FFBD11; color: #FFF9EB;`}
  ${props => props.appearance == 'orange' && `background: #FFF1ED; color: #FF5B25;`}
  ${props => props.appearance == 'red' && `background: #FDEAEB; color: #CC222B;`}
  ${props => props.appearance == 'blue' && `background: #E6EDFF; color: #0048D5;`}
`;
