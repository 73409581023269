import React, { FC, useRef } from 'react';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared';
import { AptunoButton } from '../Buttons';
import {
  FileUploaderButtonsContainer,
  FileUploaderComment,
  FileUploaderContainer,
  FileUploaderInputFileField,
} from './FileUploader.styled';
import { FileUploaderViewer } from './FileUploaderViewer';

type PreviewProps = {
  align: string;
  previewDocSource?: string;
};

type FileUploaderProps = {
  buttonText: string;
  buttonVariant?: VARIANTS;
  accept: string[];
  onAddFile: (files: File) => void;
  comment?: string;
  imagePreview?: boolean;
  previewProps?: PreviewProps;
  backgroundColor?: string;
  twoButtons?: boolean;
  secondButtonText?: string;
  secondButtonIcon?: string;
  secondButtonVariant?: VARIANTS;
  onSecondButtonClick?: () => void;
  onRemoveFile: (remainingFiles: File[], removedFile: File) => void;
  files: File[];
  minHeight?: string;
  onInvalidInput: (file: File) => void;
  disabled: boolean;
  withErrors: { fileName: string }[];
  loadingFiles: string[];
  allowToRemove?: boolean;
};

const FileUploader2: FC<FileUploaderProps> = ({
  buttonText,
  buttonVariant,
  onAddFile,
  accept,
  comment,
  imagePreview,
  previewProps,
  backgroundColor,
  twoButtons,
  secondButtonText,
  secondButtonIcon,
  secondButtonVariant,
  onSecondButtonClick,
  onRemoveFile,
  files,
  minHeight,
  onInvalidInput,
  disabled,
  withErrors,
  loadingFiles,
  allowToRemove = true,
}: FileUploaderProps) => {
  const fileUploadInput = useRef(null);

  const onSelectFile = e => {
    if (!!fileUploadInput && !!fileUploadInput.current && fileUploadInput.current.click) {
      fileUploadInput.current.click();
    }
  };

  const onInputFileChange = e => {
    const fileList = e.target.files as FileList;
    const files = Array.from(Array(fileList.length).keys()).map(idx => fileList.item(idx)!);
    const invalidTypeFile = files.find(file => !accept.includes(file.type));
    if (files.length > 0) {
      if (invalidTypeFile) {
        onInvalidInput(invalidTypeFile);
      } else {
        const newFiles = Array.from(Array(fileList.length).keys()).map(idx => fileList.item(idx)!);
        onAddFile(newFiles.pop());
      }
    }
  };

  const onFileRemoved = (remainingFiles: File[], removedFile: File) => {
    onRemoveFile(remainingFiles, removedFile);
  };

  const secondaryButtonHandler = () => {
    onSecondButtonClick && onSecondButtonClick();
  };

  return (
    <>
      <FileUploaderContainer backgroundColor={backgroundColor} minHeight={minHeight}>
        <FileUploaderViewer
          withErrors={withErrors}
          files={files}
          onRemoveFile={onFileRemoved}
          imagePreview={imagePreview}
          previewProps={previewProps}
          loading={loadingFiles}
          allowToRemove={allowToRemove}
        />
        <FileUploaderButtonsContainer>
          <AptunoButton
            disabled={disabled}
            size={SIZES.SMALL}
            appearance={buttonVariant || VARIANTS.SECONDARY}
            onClick={onSelectFile}
          >
            <Icon icon={'plus'} /> {buttonText}
          </AptunoButton>
          {twoButtons && (
            <AptunoButton
              size={SIZES.SMALL}
              appearance={secondButtonVariant || VARIANTS.SECONDARY}
              onClick={secondaryButtonHandler}
            >
              <Icon icon={secondButtonIcon} /> {secondButtonText}
            </AptunoButton>
          )}
        </FileUploaderButtonsContainer>
        <FileUploaderInputFileField
          type="file"
          ref={fileUploadInput}
          multiple={true}
          accept={accept.join(',')}
          onChange={onInputFileChange}
          onClick={e => (e.currentTarget.value = null)}
        />
      </FileUploaderContainer>
      <FileUploaderComment>{comment}</FileUploaderComment>
    </>
  );
};

export { FileUploader2 };
