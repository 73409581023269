import styled, { css } from 'styled-components';

const InputBase = css`
  background-color: white;
  border-radius: 16px;
  border: none;
  overflow: hidden;
`;

const TextAreaInput = styled.textarea`
  ${InputBase}
  resize: none;
  width: 100%;
  height: 11rem;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px 108px 16px;
  color: #6c757d !important;

  ::placeholder {
    color: #cdcdcd;
  }
`;

export { TextAreaInput };
