import React from 'react';
import { Icon } from '../../Icon';
import { AlertStyle } from './Alert.styled';

type AlertProps = {
  icon: string;
  color: string;
  iconColor: string;
  children: string;
};

const Alert = ({ icon, color, iconColor, children }: AlertProps) => {
  return (
    <AlertStyle color={color} iconColor={iconColor}>
      <Icon icon={icon} block={false} />
      <p>{children}</p>
    </AlertStyle>
  );
};

export { Alert, AlertProps };
