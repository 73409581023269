import React, { useEffect, useState } from 'react';


const useSnapScrollChange = (carouselTrackRef) => {
  const [currentStartSlide, setCurrentStartSlide] = useState({index: 0});

  let timer;

  const getCurrentPositionedSnap = ref => {

    if (ref)
      return [].slice
        .call(ref.children)
        .findIndex(
          (ele, index) =>{
            return Math.abs(ele.getBoundingClientRect().left - ref.getBoundingClientRect().left) < 10 && { ele, index }
        });
    return;
  };

  const handleSnapChange = () => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      const newCurrentIndex = getCurrentPositionedSnap(carouselTrackRef.current);

      typeof newCurrentIndex !== 'undefined' && setCurrentStartSlide({index: newCurrentIndex});
    }, 100);
  };

  useEffect(() => {
    if (carouselTrackRef && carouselTrackRef.current) {
      carouselTrackRef.current.addEventListener('scroll', handleSnapChange);

      return () => {
        carouselTrackRef.current && carouselTrackRef.current.removeEventListener('scroll', handleSnapChange);
      };
    }
  }, [carouselTrackRef]);

  return { currentStartSlide };
}

export { useSnapScrollChange };