import styled from 'styled-components';

export const ItemCardWrapper = styled.div`
  flex: 2 1 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const MainItemCardStyle = styled.button`
  margin: 2%;
  width: 100%;
  min-width: 300px;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #6c757d0f;
  border-radius: 24px;
  border-style: none;
  cursor: pointer;
`;

const MainTitle = styled.h3`
  text-align: left;
  font: normal normal bold 22px/30px "Satoshi";
  letter-spacing: -0.53px;
  color: #000000;
  margin: 0px;
`;

const MainDescription = styled.p`
  text-align: left;
  font: normal normal bold 16px/24px "Satoshi";
  letter-spacing: -0.13px;
  color: #6C757D;
  margin: 0px;
`;

const ItemCardStyle = styled(MainItemCardStyle)`
  flex: 1 2 0;
  display: inline-flex;
  align-items: center;
  padding: 6px;
`;

const Title = styled(MainTitle)`
  font: normal normal bold 16px/20px "Satoshi";
`;

const Description = styled(MainDescription)`
  font: normal normal medium 12px/16px "Satoshi";
`;

const BadgetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Badget = styled.span`
  font: normal normal normal 12px/15px "Satoshi";
  letter-spacing: 0.29px;
  background: #DEE2E6 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px;
  background-color: ${({color}) => color || '#DEE2E6'};
  color: ${({textColor}) => textColor || 'inherit'};
`;

export {
  MainItemCardStyle,
  MainTitle,
  MainDescription,
  ItemCardStyle,
  Title,
  Description,
  BadgetWrapper,
  Badget
}
