import React, { useContext } from 'react';
import { ToastsContainer } from './Toast.styled';
import { ToastMessage } from './ToastMessage';
import { ToastContext } from './ToastProvider';

type Variant = 'top_right' | 'top_left' | 'bottom_right' | 'bottom_left';

type ToastContainerProps = {
  variant?: Variant;
};

const ToastContainer = ({ variant = 'top_right' }: ToastContainerProps) => {
  const context = useContext(ToastContext);

  const position = variant || 'top_rigth';

  function handleRemove(id: string) {
    return context?.remove(id);
  }

  return (
    <ToastsContainer className={position}>
      {context?.data.map(toast => {
        return (
          <ToastMessage
            key={toast.id}
            id={toast.id}
            title={toast.title}
            message={toast.message}
            type={toast.type}
            header={toast.header}
            icon={toast.icon}
            onRemove={handleRemove}
            lifetime={toast.lifetime}
          />
        );
      })}
    </ToastsContainer>
  );
};

export { ToastContainerProps, ToastContainer };
