import styled from 'styled-components';

export const IconContainer = styled.div`
  background: #fff7e9 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || '#FEAD29'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#FFF7E9'};

  & > svg {
    width: 24px;
    height: auto;
  }
`;
