import { Fab } from '../../components/Fab/Fab';
import { HorizontalPosition, VerticalPosition } from '../../components/Fab/Fab.styled';
import React, { createRef, useEffect, useImperativeHandle, useState } from 'react';
import { CarouselItem, CarouselTrack, CarouselWrapper, CounterBadge } from './Carousel.styled';
import { VARIANTS, SIZES } from '../../shared/types';
import { Icon } from '../../Icon';
import { sortByPropertyNameAsc } from '../../shared/utils';
import { useSnapScrollChange } from '../../hooks/useSnapScrollChange';

export type ResponsiveDef = {
  breakPoint: number;
  slidesToShow: number;
};

type SliderCarouselProps = {
  children: React.ReactNode;
  slidesToShow?: number;
  showArrows?: boolean;
  gap?: number;
  margin?: string;
  infinite?: boolean;
  responsive?: Array<ResponsiveDef>;
  maxHeight?: number;
  isZoomMode?: boolean;
  initialSlide?: number;
  withoutCounter?: boolean;
  isOpen?: boolean;
};

type SlidesToShowState = {
  slidesToShow: number;
};

export type SliderHandle = {
  nextSlide: () => void;
  prevSlide: () => void;
};

const Carousel = React.forwardRef<SliderHandle, SliderCarouselProps>((props, ref) => {
  const {
    slidesToShow = 1,
    showArrows = true,
    gap = 16,
    infinite = false,
    isZoomMode = false,
    children,
    responsive,
    initialSlide = 1,
    withoutCounter = false,
    isOpen,
  } = props;

  const numberOfChildren = React.Children.count(children) || 0;

  const [cardsToShow, setSlidesToShow] = useState<SlidesToShowState>({
    slidesToShow: slidesToShow || numberOfChildren,
  });
  const [childWidth, setChildWidth] = useState<number>(0);

  const [scrollReseted, setScrollReseted] = useState<boolean>(false);

  const visibleGap = slidesToShow ? slidesToShow - 1 : 0;
  const gapToRemove = visibleGap * gap;
  const gapToRemoveByCol = gapToRemove / slidesToShow;
  const carouselTrackRef = createRef<HTMLOListElement>();

  const { currentStartSlide } = useSnapScrollChange(carouselTrackRef);

  const getColWidthInPx = (containerWidth: number): number => {
    return containerWidth / cardsToShow.slidesToShow - gapToRemoveByCol;
  };

  const validateResponsive = (): void => {
    let newResponsiveConfig = 1;

    responsive &&
      responsive
        .slice()
        .sort(sortByPropertyNameAsc('breakPoint'))
        .map(({ breakPoint, slidesToShow }) => {
          if (typeof window !== 'undefined') {
            if (breakPoint <= window.innerWidth) newResponsiveConfig = slidesToShow;
          }
        });
    setSlidesToShow({ slidesToShow: newResponsiveConfig });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && responsive) {
      window.addEventListener('resize', validateResponsive);
      validateResponsive();
      return () => {
        window.removeEventListener('resize', validateResponsive);
      };
    }
  }, [responsive]);

  //Reset scroll to position 0
  useEffect(() => {
    if (!isOpen && carouselTrackRef && carouselTrackRef.current) {
      carouselTrackRef.current && carouselTrackRef.current.scrollBy(-carouselTrackRef.current.scrollLeft, 0);
      setScrollReseted(true);
    }
  }, [carouselTrackRef, scrollReseted]);

  useEffect(() => {
    if (isOpen && initialSlide) {
      gotoSlide(1, initialSlide);
    }
  }, [initialSlide, isOpen]);

  useEffect(() => {
    if (typeof window !== 'undefined' && carouselTrackRef) {
      window.addEventListener('keydown', event => handleKeyDownPressed(event));
      return () => {
        window.removeEventListener('keydown', event => handleKeyDownPressed(event));
      };
    }
  }, [carouselTrackRef]);

  useEffect(() => {
    if (carouselTrackRef && slidesToShow) {
      const clientWidth = carouselTrackRef.current?.clientWidth;
      clientWidth && setChildWidth(getColWidthInPx(clientWidth));
    }
  }, [carouselTrackRef]);

  const handleSlickNext = (evt?: React.SyntheticEvent) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    if (carouselTrackRef.current) {
      const diff = carouselTrackRef.current.scrollWidth - carouselTrackRef.current.scrollLeft;
      if (slidesToShow === 1 && diff === childWidth) {
        gotoSlide(numberOfChildren, 1);
        return;
      }
    }
    carouselTrackRef.current && carouselTrackRef.current.scrollBy(childWidth + gap, 0);
  };

  const handleSlickPrev = (evt?: React.SyntheticEvent) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    if (slidesToShow === 1 && carouselTrackRef?.current?.scrollLeft === 0) gotoSlide(1, numberOfChildren);
    carouselTrackRef.current && carouselTrackRef.current.scrollBy(-(childWidth + gap), 0);
  };

  const handleKeyDownPressed = (event: KeyboardEvent) => {
    if (event.code === 'ArrowLeft') handleSlickPrev();
    if (event.code === 'ArrowRight') handleSlickNext();
  };

  useImperativeHandle(ref, () => ({
    nextSlide() {
      handleSlickNext(undefined);
    },
    prevSlide() {
      handleSlickPrev(undefined);
    },
  }));

  const [slidesDisplayed, setSlidesDisplayed] = useState(cardsToShow.slidesToShow);

  useEffect(() => {
    setSlidesDisplayed(currentStartSlide.index + cardsToShow.slidesToShow);
  }, [currentStartSlide, cardsToShow]);

  useEffect(() => {
    if (childWidth && carouselTrackRef.current) gotoSlide(currentStartSlide.index, initialSlide);
  }, [carouselTrackRef.current]);

  const gotoSlide = (currentSlide: number, slideNumber: number) => {
    console.log('goto slide =>', currentSlide, slideNumber);
    const slideDif = slideNumber - currentSlide;
    const scrollTo = (childWidth + gap) * slideDif;

    carouselTrackRef && carouselTrackRef.current.scrollBy(scrollTo, 0);
  };
  return (
    <CarouselWrapper margin={props.margin}>
      {showArrows && (
        <>
          <Fab
            verticalPosition={VerticalPosition.center}
            horizontalPosition={HorizontalPosition.left}
            appearance={VARIANTS.SECONDARY}
            size={isZoomMode ? SIZES.BIGGEST : SIZES.MEDIUM}
            isDisabled={false}
            marginX={16}
            inverted={isZoomMode}
            isCircular
            isTransparentDark
            withoutBorder={!isZoomMode}
            onClick={handleSlickPrev}
          >
            <Icon icon={'arrowleft'} />
          </Fab>
          <Fab
            verticalPosition={VerticalPosition.center}
            horizontalPosition={HorizontalPosition.right}
            appearance={VARIANTS.SECONDARY}
            size={isZoomMode ? SIZES.BIGGEST : SIZES.MEDIUM}
            isDisabled={false}
            marginX={16}
            inverted={isZoomMode}
            isCircular
            isTransparentDark
            withoutBorder={!isZoomMode}
            onClick={handleSlickNext}
          >
            <Icon icon={'arrowright'} />
          </Fab>
        </>
      )}
      {!withoutCounter && <CounterBadge>{`${slidesDisplayed}/${numberOfChildren}`}</CounterBadge>}
      <CarouselTrack
        ref={carouselTrackRef}
        numberOfCols={numberOfChildren}
        colWidth={childWidth}
        gap={gap}
        maxheight={props.maxHeight}
      >
        {React.Children.map(children, slide => {
          if (!React.isValidElement(slide)) {
            return;
          }
          return <CarouselItem>{slide}</CarouselItem>;
        })}
      </CarouselTrack>
    </CarouselWrapper>
  );
});

export { Carousel };
