import React, { FC } from 'react';
import { Backdrop } from '../EaseInModal/EaseInModal.styled';
import { Portal } from '../Portal';
import { ActionsWrapper, SidebarBrandWrapper, SidebarMenu, SidebarMenuItem, SidebarWrapper } from './SideMenu.styled';

type SidebarMenuProps = {
  open: boolean;
  brandIcon?: React.ReactNode;
  actions?: React.ReactNode;
  onClose: () => void;
};

const SideMenu: FC<SidebarMenuProps> = ({ open = false, brandIcon, actions, children, onClose, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <Portal>
      {open && <Backdrop onClick={() => onClose()} />}
      <SidebarWrapper open={open} aria-hidden={!isHidden} {...props}>
        <SidebarBrandWrapper>{brandIcon}</SidebarBrandWrapper>
        <SidebarMenu tabIndex={tabIndex}>
          {React.Children.map(children, menuItem => {
            if (!React.isValidElement(menuItem)) {
              return;
            }
            return <SidebarMenuItem>{menuItem}</SidebarMenuItem>;
          })}
        </SidebarMenu>
        <ActionsWrapper>{actions}</ActionsWrapper>
      </SidebarWrapper>
    </Portal>
  );
};

export { SideMenu };
