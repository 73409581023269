import { rotate360 } from '../../shared/animation';
import styled from 'styled-components';
import { SIZES, VARIANTS } from '../../shared/types';

export type ButtonProps = {
  isLoading?: boolean;
  /**
   When a button is in the loading state you can supply custom text
  */
  loadingText?: string;
  /**
   Buttons that have hrefs should use <a> instead of <button>
  */
  isLink?: boolean;
  children?: React.ReactNode;
  appearance: VARIANTS;
  isDisabled?: boolean;
  /**
   Prevents users from clicking on a button multiple times (for things like payment forms)
  */
  isUnclickable?: Boolean;
  /**
   Buttons with icons by themselves have a circular shape
  */
  fitWidth?: Boolean;
  fitHeight?: Boolean;
  containsIcon?: Boolean;
  isCircular?: Boolean;
  size?: SIZES;
  borderRadius?: string;
  inverted?: boolean;
  name?: string;
  withoutBorder?: boolean;
  isGrayText?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  boxShadow?: string;
  onHoverBgColor?: string;
  onHoverColor?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Loading = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0;
`;

const Load = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${rotate360} 2s linear infinite;
  display: inline-block;
  margin: auto 16px;
  vertical-align: middle;
`;

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border: 0;
  border-width: ${({size}) => size === SIZES.EXTRA_SMALL && '1px !important'};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '16px')};
  padding: 11px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 350ms ease-out;
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  user-select: none;
  opacity: 1;
  margin: 0;
  background: transparent;
  font-size: ${props =>
    props.size === SIZES.EXTRA_SMALL
      ? props.theme.typography.size.sm1
      : props.size === SIZES.SMALL
      ? props.theme.typography.size.sm2
      : props.theme.typography.size.sm3}px;
  font-weight: ${props => props.theme.typography.weight.bold};
  line-height: 1;
  width: 100%;
  max-width: ${props =>
    props.size === SIZES.SMALL
      ? '143'
      : props.size === SIZES.BIG
      ? '552'
      : props.size === SIZES.EXTRA_SMALL
      ? '191'
      : '328'}px;
  height: ${({ size }) => (size === SIZES.EXTRA_SMALL ? '32px' : '48px')};
  gap: 10px;

  s &:focus {
    box-shadow: ${props => props.theme.shadows['1-drop']};
  }
  ${props =>
    props.isUnclickable &&
    `
      cursor: default !important;
      pointer-events: none;
      &:hover {
        transform: none;
      }
    `}

  ${props =>
    props.fitWidth &&
    `
      width: fit-content;
      max-width: 200px;
      min-width: 70px;
    `}

  ${props =>
    props.fitHeight &&
    `
      height: 40px;
      max-height: 40px;
      padding: 8px 10px;
    `}
  
  ${props =>
    props.isCircular &&
    `
      font-weight: ${props => props.theme.typography.weight.black};
      svg {
        display: block;
        margin: 0;
        vertical-align: sub;
      }
      padding: 12px;
      border-radius:  ${
        props.size === SIZES.SMALL ? 24 : props.size === SIZES.BIG ? 48 : props.size === SIZES.BIGGEST ? 56 : 36
      }px;
      width:  ${
        props.size === SIZES.SMALL ? 24 : props.size === SIZES.BIG ? 48 : props.size === SIZES.BIGGEST ? 56 : 36
      }px !important;
      height:  ${
        props.size === SIZES.SMALL ? 24 : props.size === SIZES.BIG ? 48 : props.size === SIZES.BIGGEST ? 56 : 36
      }px !important;
      padding: .5rem;
    `}

  ${props =>
    props.containsIcon &&
    `
      svg {
        display: block;
        margin: 0;
        vertical-align: sub;
      }
      width: fit-content;
      max-width: 200px;
      min-width: 70px;
    `}

  ${props =>
    props.isLoading &&
    `
        cursor: progress !important;
        opacity: 0.7;
  
        ${Loading} {
          transform: translate3d(0, -50%, 0);
          opacity: 1;
        }
  
        ${Text} {
          transform: scale3d(0, 0, 1) translate3d(0, -100%, 0);
          opacity: 0;
        }
  
        &:hover {
          transform: none;
        }
      `}
    
  ${props =>
    props.appearance === VARIANTS.PRIMARY &&
    `
      background: ${!props.inverted ? props.theme.colors.bgPrimaryBtn : props.theme.colors.bgSecondaryBtn};
      color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgPrimaryBtn};
      border: 2px solid ${props.theme.colors.bgPrimaryBtn};

      ${
        !props.isLoading &&
        `
          &:hover, 
          &:active,
          &:focus {
            background: ${!props.inverted ? props.theme.colors.bgPrimaryActiveBtn : props.theme.colors.bgPrimaryBtn};
            color: ${props.theme.colors.textPrimaryBtn};
          }
        `
      }
  `}

  ${props =>
    props.appearance === VARIANTS.GREEN &&
    `
      background: ${!props.inverted ? props.theme.colors.bgGreenBtn : props.theme.colors.bgSecondaryBtn};
      color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgGreenBtn};
      border: 2px solid ${props.theme.colors.bgGreenBtn};

      ${
        !props.isLoading &&
        `
          &:hover, 
          &:active,
          &:focus {
            background: ${!props.inverted ? props.theme.colors.bgGreenBtn : props.theme.colors.bgGreenBtn};
            color: ${props.theme.colors.textPrimaryBtn};
          }
        `
      }
  `}

  ${props =>
    props.appearance === VARIANTS.PURPLE &&
    `
      background: ${!props.inverted ? props.theme.colors.bgPurpleBtn : props.theme.colors.bgSecondaryBtn};
      color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgPurpleBtn};
      border: 2px solid ${props.theme.colors.bgPurpleBtn};

      ${
        !props.isLoading &&
        `
          &:hover, 
          &:active,
          &:focus {
            background: ${!props.inverted ? props.theme.colors.bgPurpleBtn : props.theme.colors.bgPurpleBtn};
            color: ${props.theme.colors.textPrimaryBtn};
          }
        `
      }
  `}

${props =>
    props.appearance === VARIANTS.YELLOW &&
    `
      background: ${!props.inverted ? props.theme.colors.bgYellowBtn : props.theme.colors.bgSecondaryBtn};
      color: ${!props.inverted ? props.theme.colors.textSecondaryBtn : props.theme.colors.bgYellowBtn};
      border: 2px solid ${props.theme.colors.bgYellowBtn};

      ${
        !props.isLoading &&
        `
          &:hover, 
          &:active,
          &:focus {
            background: ${!props.inverted ? props.theme.colors.bgYellowBtn : props.theme.colors.bgYellowBtn};
            color: ${props.theme.colors.textSecondaryBtn};
          }
        `
      }
  `}

  ${props =>
    props.appearance === VARIANTS.PRIMARY_GRADIENT &&
    `
      background: ${!props.inverted ? props.theme.colors.bgPrimaryBtn : props.theme.colors.bgSecondaryBtn};
      background: radial-gradient(144.79% 3600% at 100% 100%, #0055FB 0%, #22C0F1 100%);
      border-radius: 8px;
      color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgPrimaryBtn};
      border: none;

      ${
        !props.isLoading &&
        `
          &:hover, 
          &:active,
          &:focus {
            background: radial-gradient(160% 3600% at 100% 80%, #0055FB 0%, #22C0F1 60%);
            color: ${props.theme.colors.textPrimaryBtn};
          }
        `
      }
  `}

  ${props =>
    props.appearance === VARIANTS.SECONDARY &&
    `
    background:  ${!props.inverted ? props.theme.colors.bgSecondaryBtn : props.theme.colors.bgSecondaryActiveBtn};
    color: ${props.theme.colors.textSecondaryBtn};
    border: 2px solid ${props.theme.colors.bgSecondaryActiveBtn};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${!props.inverted ? props.theme.colors.bgSecondaryActiveBtn : props.theme.colors.bgSecondaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.SECONDARY_LIGHT &&
    `
    background:  ${!props.inverted ? props.theme.colors.bgSecondaryBtn : props.theme.colors.bgSecondaryActiveBtn};
    color: ${props.theme.colors.textSecondaryLightBtn};
    border: 2px solid ${props.theme.colors.bgSecondaryActiveBtn};
    border-radius: 8px;

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${!props.inverted ? props.theme.colors.bgSecondaryActiveBtn : props.theme.colors.bgSecondaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.DANGER &&
    `
    background: ${!props.inverted ? props.theme.colors.bgDangerBtn : props.theme.colors.bgSecondaryBtn};
    color: ${!props.inverted ? props.theme.colors.textDangerBtn : props.theme.colors.bgDangerBtn};
    border: 2px solid ${props.theme.colors.bgDangerBtn};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${!props.inverted ? props.theme.colors.bgDangerActiveBtn : props.theme.colors.bgDangerBtn};
          color: ${props.theme.colors.textPrimaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.SUCCESS &&
    `
    background: ${!props.inverted ? props.theme.colors.bgSuccess : props.theme.colors.bgSecondaryBtn};
    color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgSuccess};
    border: 2px solid ${props.theme.colors.bgSuccess};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${props.theme.colors.bgSuccessActive};
          color: ${props.theme.colors.textPrimaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.WARNING &&
    `
    background: ${!props.inverted ? props.theme.colors.bgWarning : props.theme.colors.bgSecondaryBtn};
    color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgWarning};
    border: 2px solid ${props.theme.colors.bgWarning};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${props.theme.colors.bgWarningActive};
          color: ${props.theme.colors.textPrimaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.WARNING_LIGHT &&
    `
    border-radius: 8px 24px 24px 8px;
    height: 48px !important;

    @media screen and (max-width: 450px) {
      height: 37px !important;
      margin-top: 5px;
    }
    max-height: 48px;
    background-color: ${props.theme.colors.bgLabelAdvertency};
    color: ${!props.inverted ? props.theme.colors.bgWarningActive : props.theme.colors.bgWarning};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: rgb(255, 234, 226);
          color: rgb(253, 141, 93) !important;
        }
      `
    }
  `}

  ${props =>
    props.appearance === VARIANTS.INFO &&
    `
    background: ${!props.inverted ? props.theme.colors.bgInfo : props.theme.colors.bgSecondaryBtn};
    color: ${!props.inverted ? props.theme.colors.textPrimaryBtn : props.theme.colors.bgInfo};
    border: 2px solid ${props.theme.colors.bgInfo};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${props.theme.colors.bgInfoActive};
          color: ${props.theme.colors.textPrimaryBtn};
        }
      `
    }
  `}
  
  ${props =>
    props.appearance === VARIANTS.DARK &&
    `
    background: ${!props.inverted ? props.theme.colors.bgDarken : props.theme.colors.bgSecondary};
    color: ${!props.inverted ? props.theme.colors.textPrimary : props.theme.colors.textSecondary};
    border: 2px solid ${props.theme.colors.bgSecondary};

    ${
      !props.isLoading &&
      `
        &:hover {
          background: ${props.theme.colors.bgDarken};
          color: ${props.theme.colors.textPrimaryBtn};
        }
      `
    }
  `}

    ${props =>
    props.appearance === VARIANTS.INFORMATIVE &&
    `
    background: ${!props.inverted ? props.theme.colors.bgInformativeBtn : props.theme.colors.bgSecondaryBtn};
    color: ${!props.inverted ? props.theme.colors.textSecondaryBtn : props.theme.colors.bgWarning};
    border: 2px solid ${props.theme.colors.bgInformativeBtn};

    ${
      !props.isLoading &&
      `
        &:hover, 
        &:active,
        &:focus {
          background: ${props.theme.colors.bgInformativeBtnActive};
          color: ${props.theme.colors.textSecondaryBtn};
        }
      `
    }
  `}

  ${props =>
    props.disabled &&
    `
      cursor: not-allowed !important;
      background-color: ${props.theme.colors.bgDisabledBtn};
      color: ${props.theme.colors.textDisabledBtn};
      border: none;
      &:hover {
        background-color: ${props.theme.colors.bgDisabledBtn};
        color: ${props.theme.colors.textDisabledBtn};
      }
  `}

  ${props =>
    props.withoutBorder &&
    `
      border: none !important;
    `}
  ${props =>
    props.isGrayText &&
    `
      color: ${props.theme.colors.textDisable}
    `};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`};
  ${props =>
    props.onHoverBgColor &&
    `
    &:hover,
    &:active,
    &:focus {
      background-color: ${props.backgroundColor}
    }
  `};
  ${props =>
    props.onHoverColor &&
    `
    &:hover,
    &:active,
    &:focus {
      color: ${props.onHoverColor}
    }
  `};
`;

export { SIZES, VARIANTS as APPEARANCES, StyledButton, Load };
