import React from 'react';
import { Container, PieChartSimpleProps } from './PieChartSimple.styled';
import { PieChart } from 'react-minimal-pie-chart';

const PieChartSimple = ({
  data,
  lineWidth,
  startAngle,
  lengthAngle,
  totalValue,
  paddingAngle,
  rounded,
  segmentsShift,
  segmentsStyle,
  segmentsTabIndex,
  label,
  labelPosition,
  animate,
  animationDuration,
  animationEasing,
  reveal,
  background,
  radius,
  center,
  viewBoxSize,
  onClick,
  onMouseOut,
  onMouseOver,
}: PieChartSimpleProps) => {
  return (
    <Container>
      <PieChart
        data={data}
        lineWidth={lineWidth}
        startAngle={startAngle}
        lengthAngle={lengthAngle}
        totalValue={totalValue}
        paddingAngle={paddingAngle}
        rounded={rounded}
        segmentsShift={segmentsShift}
        segmentsStyle={segmentsStyle}
        segmentsTabIndex={segmentsTabIndex}
        label={label}
        labelPosition={labelPosition}
        animate={animate}
        animationDuration={animationDuration}
        animationEasing={animationEasing}
        reveal={reveal}
        background={background}
        radius={radius}
        center={center}
        viewBoxSize={viewBoxSize}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      />
    </Container>
  );
};

export { PieChartSimple };
