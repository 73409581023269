import React, { FC } from 'react';
import { Bubble, BubbleInformativeProps, Container, Pointer } from './BubbleInformative.styled';

const BubbleInformative: FC<BubbleInformativeProps> = ({ backgroundColor, textColor, textFontSize, children }) => {
  return (
    <Container>
      <Pointer backgroundColor={backgroundColor}></Pointer>
      <Bubble backgroundColor={backgroundColor} textColor={textColor} textFontSize={textFontSize}>
        {children}
      </Bubble>
    </Container>
  );
};

export {BubbleInformative};
