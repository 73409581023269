import styled, { css, keyframes } from 'styled-components';

export const modalSlide = keyframes`
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0);
  }
`;

export const modalSlideOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(150%);
  }
`;

export const modalBackgroundSlide = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.2;
  }
`;

export const modalBackgroundSlideOut = keyframes`
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0;
  }
`;

type ModalWrapperProps = {
  isActive: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  ${({ isActive }) => !isActive && 'transition: z-index ease-in-out 1s'};
  z-index: ${({ isActive }) => (isActive ? '1' : '-1')};
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: #555a64;
    animation: ${({ isActive }) => (isActive ? modalBackgroundSlide : modalBackgroundSlideOut)} 0.3s ease-in-out
      forwards;
  }
`;

const modalBottom = css`
  width: 100%;
  border-radius: 24px;
  height: fit-content;
  margin-bottom: 0px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  bottom: 0;
`;

const ModalContent = styled.div<any>`
  position: fixed;
  width: 100%;
  height: fit-content;
  background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
  border-radius: 24px;
  overflow: auto;
  bottom: 0;

  animation: ${({ isActive }) => (isActive ? modalSlide : modalSlideOut)}
    ${({ isActive }) => (isActive ? '0.3s' : '0.3s')} ease-in-out forwards;

  ${modalBottom};

  @media screen and (min-width: 768px) {
    position: absolute;
    ${({ size }) => (size ? `width:${size}px` : null)};
    ${({ width }) => (width ? `width:${width}` : null)};
    height: ${({ height }) => (height ? height : 'fit-content')};
    background: ${({ bgColor }) => (bgColor ? bgColor : 'white')};
    border-radius: 24px;
    margin: 0 auto;
    ${modalBottom};
  }
`;

const ModalHead = styled.div<any>`
  position: relative;
  text-align: center;
  font-weight: bold;
  padding: ${({ isMobile }) => (isMobile ? '8px 16px' : '16px')};
  min-height: 54px;
  border-radius: 24px;
  padding-top: 0px;
`;

const ModalBody = styled.div`
  padding: 0px 16px 16px 16px;
  height: fit-content;
`;

const CloseModalContainer = styled.div`
  width: 100%;
  height: 12px;
  opacity: 0.5;
  cursor: pointer;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
`;

export { ModalWrapper, ModalContent, ModalHead, ModalBody, CloseModalContainer };
