import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared';
import { AptunoButton } from '../Buttons';
import {
  FileUploaderButtonsContainer,
  FileUploaderComment,
  FileUploaderContainer,
  FileUploaderInputFileField,
} from './FileUploader.styled';
import { ImagePreviewProps, FileUploaderViewer } from './FileUploaderViewer';

type FileUploaderProps = {
  buttonText: string;
  buttonVariant?: VARIANTS;
  accept: string[];
  multiple: boolean;
  onFilesSelected: (files: File[]) => void;
  comment?: string;
  imagePreview?: boolean;
  imagePreviewProps?: ImagePreviewProps;
  backgroundColor?: string;
  twoButtons?: boolean;
  secondButtonText?: string;
  secondButtonIcon?: string;
  secondButtonVariant?: VARIANTS;
  onClickSecondButton?: () => void;
  getFileOnRemoved?: (removedFile: File) => void;
  preLoadedFiles?: File[];
  minHeight?: string;
};

const FileUploader: FC<FileUploaderProps> = ({
  buttonText,
  buttonVariant,
  accept,
  multiple,
  comment,
  onFilesSelected,
  imagePreview,
  imagePreviewProps,
  backgroundColor,
  twoButtons,
  secondButtonText,
  secondButtonIcon,
  secondButtonVariant,
  onClickSecondButton,
  getFileOnRemoved,
  preLoadedFiles,
  minHeight,
}: FileUploaderProps) => {
  const fileUploadInput = useRef(null);

  const onSelectFile = e => {
    if (!!fileUploadInput && !!fileUploadInput.current && fileUploadInput.current.click) {
      fileUploadInput.current.click();
    }
  };

  const [filesSelected, setFilesSelected] = useState<File[]>([]);

  useEffect(() => {
    if (preLoadedFiles) {
      setFilesSelected(() => preLoadedFiles);
    }
  }, [preLoadedFiles]);

  useEffect(() => emitNewFiles(filesSelected), [filesSelected]);

  const onInputFileChange = e => {
    const fileList = e.target.files as FileList;
    if (fileList.length > 0) {
      const files = Array.from(Array(fileList.length).keys()).map(idx => fileList.item(idx)!);
      setNewFiles(files, false);
    }
  };

  const onFileRemoved = (files: File[], removedFile: File) => {
    setNewFiles(files, true);
    getFileOnRemoved && getFileOnRemoved(removedFile);
  };

  const setNewFiles = (files: File[], override: boolean) => {
    const newFiles = multiple && !override ? [...filesSelected, ...files] : files;
    setFilesSelected(newFiles);
  };

  const emitNewFiles = (files: File[]) => {
    onFilesSelected(files);
  };

  const secondaryButtonHandler = () => {
    onClickSecondButton && onClickSecondButton();
  };

  return (
    <>
      <FileUploaderContainer backgroundColor={backgroundColor} minHeight={minHeight}>
        <FileUploaderViewer
          files={filesSelected}
          onFileRemoved={onFileRemoved}
          imagePreview={imagePreview}
          imagePreviewProps={imagePreviewProps}
        />
        <FileUploaderButtonsContainer>
          <AptunoButton size={SIZES.SMALL} appearance={buttonVariant || VARIANTS.SECONDARY} onClick={onSelectFile}>
            <Icon icon={'plus'} /> {buttonText}
          </AptunoButton>
          {twoButtons && (
            <AptunoButton
              size={SIZES.SMALL}
              appearance={secondButtonVariant || VARIANTS.SECONDARY}
              onClick={secondaryButtonHandler}
            >
              <Icon icon={secondButtonIcon} /> {secondButtonText}
            </AptunoButton>
          )}
        </FileUploaderButtonsContainer>
        <FileUploaderInputFileField
          type="file"
          ref={fileUploadInput}
          multiple={multiple}
          accept={accept.join(',')}
          onChange={onInputFileChange}
          onClick={e => (e.currentTarget.value = null)}
        />
      </FileUploaderContainer>
      <FileUploaderComment>{comment}</FileUploaderComment>
    </>
  );
};

export { FileUploader };
