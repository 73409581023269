import React, { useEffect, useState } from 'react';
import {
  Circle,
  CircleBg,
  CircularChart,
  PercentLabel,
  StepDescription,
  StepIndicator,
  StepLabel,
  StepperWrapper,
  StepTitle,
} from './Stepper.styled';

// STEPPER DEFAULT
export function Stepper({ steps, activeStep = 0 }) {
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [currentPercent, setPercent] = useState(0);

  useEffect(() => {
    if (activeStep >= 0 && activeStep <= steps.length) {
      setCurrentStep(steps[activeStep]);
      setPercent(getProgressPercent());
    }
  }, [activeStep, steps]);

  const getProgressPercent = () => {
    return ((activeStep + 1) * 100) / steps.length;
  };

  return (
    <StepperWrapper>
      <StepIndicator>
        <CircularChart viewBox="0 0 36 36">
          <CircleBg
            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <Circle
            color={'#0055FB'}
            percent={currentPercent}
            d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <PercentLabel x="9" y="22.35">
            {activeStep + 1}/{steps.length}
          </PercentLabel>
        </CircularChart>
      </StepIndicator>
      <StepLabel>
        {currentStep.title && <StepTitle>{currentStep.title}</StepTitle>}
        {currentStep.description && <StepDescription>{currentStep.description}</StepDescription>}
      </StepLabel>
    </StepperWrapper>
  );
}
