import React from 'react';
import { FC } from 'react';
import { colorPrimitives } from './../../shared/designTokens';
import { BasicChip } from './BasicChip';

enum Color {
  BLUE = 'blue',
  GREEN = 'green',
  GRAY = 'gray',
  ORANGE = 'orange',
  PURPLE = 'purple',
  RED = 'red',
  YELLOW = 'yellow',
}

enum Appearence {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type StyledChipProps = {
  appearence: Appearence;
  color: Color;
};

const StyledChip: FC<StyledChipProps> = ({ appearence, color, children }) => {
  const { primary: primaryColor, secondary: secondaryColor, tertiary: tertiaryColor } = getChipColors(color);

  return (
    <BasicChip
      backgroundColor={appearence === Appearence.PRIMARY && tertiaryColor}
      borderColor={secondaryColor}
      fontSize={getFontSize(appearence)}
      fontWeight={getFontWeight(appearence)}
      lineHeigh={getLineHeight(appearence)}
      textColor={appearence === Appearence.PRIMARY ? primaryColor : colorPrimitives.grays.GRAY}
      withBorder={appearence === Appearence.SECONDARY}
    >
      {children}
    </BasicChip>
  );
};

const getFontSize = (appearence: Appearence) => {
  switch (appearence) {
    case Appearence.PRIMARY:
      return 14;
    case Appearence.SECONDARY:
      return 14;
  }
};

const getFontWeight = (appearence: Appearence) => {
  switch (appearence) {
    case Appearence.PRIMARY:
      return 700;
    case Appearence.SECONDARY:
      return 700;
  }
};

const getChipColors = (color: Color) => {
  switch (color) {
    case Color.BLUE:
      return {
        primary: colorPrimitives.main.BLUE,
        secondary: colorPrimitives.extendedPalette['BLUE-20'],
        tertiary: colorPrimitives.extendedPalette['BLUE-10'],
      };
    case Color.RED:
      return {
        primary: colorPrimitives.secondary.RED,
        secondary: colorPrimitives.extendedPalette['RED-10'],
        tertiary: colorPrimitives.extendedPalette['RED-10'],
      };
    case Color.GRAY:
      return {
        primary: colorPrimitives.grays.GRAY,
        secondary: colorPrimitives.extendedPalette['BLUE-10'],
        tertiary: colorPrimitives.grays['GRAY-E6'],
      };
    case Color.GREEN:
      return {
        primary: colorPrimitives.secondary.GREEN,
        secondary: colorPrimitives.extendedPalette['GREEN-10'],
        tertiary: colorPrimitives.extendedPalette['GREEN-10'],
      };
    case Color.ORANGE:
      return {
        primary: colorPrimitives.secondary.ORANGE,
        secondary: colorPrimitives.extendedPalette['ORANGE-10'],
        tertiary: colorPrimitives.extendedPalette['ORANGE-10'],
      };
    case Color.PURPLE:
      return {
        primary: colorPrimitives.secondary.PURPLE,
        secondary: colorPrimitives.extendedPalette['PURPLE-10'],
        tertiary: colorPrimitives.extendedPalette['PURPLE-10'],
      };
    case Color.YELLOW:
      return {
        primary: colorPrimitives.secondary.YELLOW,
        secondary: colorPrimitives.extendedPalette['YELLOW-10'],
        tertiary: colorPrimitives.extendedPalette['YELLOW-10'],
      };
    default:
      return {
        primary: colorPrimitives.main.BLACK,
        secondary: colorPrimitives.main.BLACK,
        tertiary: colorPrimitives.main.WHITE,
      };
  }
};

const getLineHeight = (appearence: Appearence) => {
  switch (appearence) {
    case Appearence.PRIMARY:
      return 20;
    case Appearence.SECONDARY:
      return 20;
  }
};

export { BasicChip, StyledChip, Color, Appearence };
