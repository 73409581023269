import styled from 'styled-components';
import { TabSize, TabType } from '../Shared/types';

interface ItemDetailsTab {
  id: string;
  text: string;
}

export type DetailsTabOptions = {
  activeTabId?: string;
  items: ItemDetailsTab[];
  onClickFn?: (selected: ItemDetailsTab) => void;
  tabType: TabType;
  tabSize: TabSize;
};

export const Container = styled.div<DetailsTabOptions>`
  display: flex;
  gap: ${props => (props.tabType === TabType.TOGETHER ? '0px' : '8px')};
`;
