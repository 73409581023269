import styled from 'styled-components';

type StyledBurgerProps = {
  open: boolean;
  isMobile?: boolean
}
export const StyledBurger = styled.button<StyledBurgerProps>`
  position: fixed;
  z-index: 100;
  top: 1.5rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 1.375rem;
  transform: ${({ open }) => !open ? 'translateX(0)' : 'translateX(200px)'};
  outline: none;
  transition: all .33s cubic-bezier(.685,.0473,.346,1);

  span {
    width: 1.5rem;
    height: 0.225rem;
    background: ${({ theme }) => theme.colors.bgBurguerMenu};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;
