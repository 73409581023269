import styled, { css } from 'styled-components';

const InputBase = css`
  background-color: white;
  border-radius: 16px;
  border: none;
  overflow: hidden;
`;

const MinusPlusButton = styled.button`
  ${InputBase}
  width: 48px;
  height: 48px;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  user-select: none;

  &:disabled {
    background-color: #ececec;
  }

  &:after,
  &:before {
    display: inline-block;
    position: absolute;
    content: '';
    width: 1rem;
    height: 2px;
    background-color: #212121;
    transform: translate(-50%, -50%);
  }

  &.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const NumberInput = styled.input.attrs({
  type: 'number',
  min: 1,
})`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  max-width: 4rem;
  padding: 0.5rem;
  border: none;
  border-width: 0 2px;
  font-size: 1.5em;
  height: 48px;
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

const NumberInputWrapper = styled.div`
  border: 0;
  display: inline-flex;
  box-sizing: border-box;
`;

export { MinusPlusButton, NumberInputWrapper, NumberInput };
