import React, { useState } from 'react';
import {
  AccordionShortProps,
  Container,
  Wrap,
  AccordionDropdown,
  Label,
  Answer,
  Subanswer,
  SimbolIcon,
} from './AccordionShort.styled';
import { Icon } from '../../../Icon';

function AccordionShort({ data, width, height }: AccordionShortProps) {
  const [clicked, setClicked] = useState<number>(-1);

  const toggle = (event: React.MouseEvent, index: number) => {
    event.preventDefault()
    if (clicked === index) return setClicked(-1);
    setClicked(index);
  };

  return (
    <Container width={width}>
      {data.map((item, index) => {
        return (
          <div key={`accordion-short-${index}`}>
            <Wrap height={height} onClick={event => toggle(event, index)} key={`label-wrapper-${index}`}>
              <Label>{item.question}</Label>
              {clicked === index ? (
                <SimbolIcon>
                  <Icon icon={'ChevronDown'} style={{ transform: 'rotate(-180deg)' }} />
                </SimbolIcon>
              ) : (
                <SimbolIcon>
                  <Icon icon={'ChevronDown'} style={{ transform: 'rotate(0deg)' }} />
                </SimbolIcon>
              )}
            </Wrap>
            <AccordionDropdown key={`dropdown-answer-${index}`} isDisplay={clicked === index}>
              <h4>
                <Answer>{item.answer}</Answer>
                <Subanswer>{item.subanswer}</Subanswer>
              </h4>
            </AccordionDropdown>
          </div>
        );
      })}
    </Container>
  );
}

export { AccordionShort };
