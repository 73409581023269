import styled from 'styled-components';

const FileUploaderViewerContainer = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${({ align }) => align || 'center'};
  align-contetn: center;
  justify-content: ${({ align }) => align || 'space-between'};
  flex-wrap: wrap;
  margin: 0 0 10px 0;
  gap: 16px;
  width: ${({ align }) => align && '100%'};
`;

const FileUploaderViewerPreview = styled.div`
  width: auto;
  margin: 10px auto 10px auto;
  padding: 10px 30px 10px 30px;
  background: #fff9eb;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.15);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  align-contetn: center;
`;

type FileUploaderViewerImagePreviewProps = {
  width?: number;
  height?: number;
};

const FileUploaderViewerImagePreviewContainer = styled.div<FileUploaderViewerImagePreviewProps>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.15);
  background: black;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
`;

const FileUploaderViewerImagePreview = styled.img<FileUploaderViewerImagePreviewProps>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const FileUploaderViewerPreviewButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
`;

const FileUploaderViewerDescription = styled.div`
  margin-left: 10px;
`;

export {
  FileUploaderViewerContainer,
  FileUploaderViewerPreview,
  FileUploaderViewerPreviewButton,
  FileUploaderViewerDescription,
  FileUploaderViewerImagePreviewContainer,
  FileUploaderViewerImagePreview,
};
