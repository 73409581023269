import React, { useEffect } from 'react';
import { Toast } from './ToastProvider';
import { Container, IconContainer, CloseIcon, Description, ToastContent, Title } from './Toast.styled';
import { Icon } from '../../Icon';
import { colorPrimitives } from '../../shared/designTokens';

const VARIANTS = {
  Info: {
    color: colorPrimitives.main.BLUE,
    background: colorPrimitives.extendedPalette['BLUE-10'],
    iconstyle: 'text-blue-500 ',
    icon: 'info',
    name: 'Info',
  },

  Error: {
    color: colorPrimitives.main.WHITE,
    background: colorPrimitives.secondary.RED,
    iconstyle: 'text-red-500 ',
    icon: 'closeAlt',
    name: 'Opss',
  },

  Warning: {
    color: colorPrimitives.secondary.ORANGE,
    background: colorPrimitives.extendedPalette['ORANGE-10'],
    iconstyle: 'text-yellow-500 ',
    icon: 'warning',
    name: 'Opss',
  },

  Success: {
    color: colorPrimitives.main.WHITE,
    background: colorPrimitives.secondary.GREEN,
    iconstyle: 'text-green-500 ',
    icon: 'check',
    name: 'Exito',
  },
};

type ToastMessagePayload = {
  id: string;
  title?: string;
  lifetime?: number;
  variant?: keyof typeof VARIANTS | undefined;
  onRemove?: (id: string) => void;
} & Toast;

const ToastMessage = ({ id, header, title, message, lifetime, onRemove, icon, type }: ToastMessagePayload) => {
  const toastInfo = type
    ? VARIANTS[type]
    : {
        color: '#0055FB',
        iconstyle: '',
        icon: icon,
        name: header,
      };

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [lifetime, onRemove, id]);

  return (
    <Container>
      {toastInfo.icon && (
        <IconContainer {...toastInfo}>
          <Icon block={false} icon={toastInfo.icon} aria-hidden />
        </IconContainer>
      )}

      <ToastContent>
        {title && <Title>{title}</Title>}
        <Description>{message}</Description>
      </ToastContent>
      <CloseIcon onClick={() => onRemove && onRemove(id)}>
        <Icon block={false} icon="closeAlt" />
      </CloseIcon>
    </Container>
  );
};

export { ToastMessagePayload, ToastMessage };
