import React, { FC, useEffect } from 'react';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared';
import { AptunoButton } from '../Buttons';
import {
  FileUploaderViewerContainer,
  FileUploaderViewerDescription,
  FileUploaderViewerImagePreview,
  FileUploaderViewerImagePreviewContainer,
  FileUploaderViewerPreview,
  FileUploaderViewerPreviewButton,
  LoadingViewContainer,
  Spinner,
} from './FileUploaderViewer.styled';

type PreviewProps = {
  align: string;
  previewDocSource?: string;
};

type FileUploaderViewerProps = {
  files: File[];
  onRemoveFile: (remainingFiles: File[], removed: File) => void;
  imagePreview?: boolean;
  previewProps?: PreviewProps;
  withErrors: { fileName: string }[];
  loading: string[];
  allowToRemove: boolean;
};

const FileUploaderViewer: FC<FileUploaderViewerProps> = ({
  files,
  onRemoveFile,
  imagePreview,
  previewProps,
  withErrors = [],
  loading,
  allowToRemove = true,
}: FileUploaderViewerProps) => {
  const onFileSeletedToRemove = (index: number) => {
    const remainingFiles = files.filter((_, idx) => idx !== index);
    const removedFile = files[index];
    onRemoveFile(remainingFiles, removedFile);
  };

  const createImageUrl = (file: File): string => URL.createObjectURL(file);

  const isFileDoc = (file: File): boolean => new RegExp('pdf', 'g').test(file.type);

  return (
    <FileUploaderViewerContainer align={previewProps?.align}>
      {files.map((file, idx) =>
        imagePreview ? (
          <FileUploaderViewerImagePreviewContainer key={`image-preview-${idx}`} width={80} height={80}>
            {loading.find(fileName => fileName === file.name)  ? (
              <LoadingViewContainer>
                <Spinner size={24} />
              </LoadingViewContainer>
            ) : (
              <FileUploaderViewerImagePreview
                withWarningBorder={Boolean(withErrors.find(error => file.name === error.fileName))}
                src={
                  isFileDoc(file) ? previewProps.previewDocSource || './images/doc_preview.svg' : createImageUrl(file)
                }
              />
            )}
            <FileUploaderViewerPreviewButton>
              {allowToRemove && (
                <AptunoButton
                  size={SIZES.MEDIUM}
                  appearance={VARIANTS.SECONDARY}
                  onClick={() => onFileSeletedToRemove(idx)}
                  isCircular
                >
                  <Icon icon="closeAlt" />
                </AptunoButton>
              )}
            </FileUploaderViewerPreviewButton>
          </FileUploaderViewerImagePreviewContainer>
        ) : (
          <FileUploaderViewerPreview key={idx}>
            <Icon icon="document" />
            <FileUploaderViewerDescription>{file.name}</FileUploaderViewerDescription>
            <FileUploaderViewerPreviewButton>
              {allowToRemove && (
                <AptunoButton
                  size={SIZES.MEDIUM}
                  appearance={VARIANTS.SECONDARY}
                  onClick={() => onFileSeletedToRemove(idx)}
                  isCircular
                >
                  <Icon icon="closeAlt" />
                </AptunoButton>
              )}
            </FileUploaderViewerPreviewButton>
          </FileUploaderViewerPreview>
        )
      )}
    </FileUploaderViewerContainer>
  );
};

export { FileUploaderViewer, PreviewProps as ImagePreviewProps };
