import React, { useCallback, useContext, useState } from 'react';
import { ToastContainer, ToastContainerProps } from './ToastContainer';

type ToastProviderProps = {
  children: React.ReactNode;
} & ToastContainerProps;

type ToastMessageType = 'Info' | 'Success' | 'Warning' | 'Error';

type Toast = {
  id: string;
  lifetime: number;
  message: string | React.ReactNode;
  title: string | undefined;
  type?: ToastMessageType;
  icon?: string;
  header?: string;
};

type ToastContextType = {
  data: Array<Toast>;
  pushError(message: string, title?: string, lifetime?: number): void;
  pushWarning(message: string, title?: string, lifetime?: number): void;
  pushSuccess(message: string, title?: string, lifetime?: number): void;
  pushInfo(message: string, title?: string, lifetime?: number): void;
  push(message: string, type: ToastMessageType, title?: string, lifetime?: number): void;
  pushCustom(
    message: string | React.ReactNode,
    title: string | undefined,
    lifetime: number,
    icon?: string | React.ReactNode
  ): void;
  remove(id: string): void;
};

/////////////////////////////////////
/// Global and Helpers
/////////////////////////////////////

const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const useToast = () => useContext(ToastContext);

const DEFAULT_INTERVAL = 2500;

const ToastProvider = ({ children, variant }: ToastProviderProps) => {
  const [data, setData] = useState<Array<Toast>>([]);

  const Push = useCallback(
    (message: string, type: ToastMessageType, title?: string, lifetime?: number) => {
      if (message) {
        const newItem: Toast = {
          id: uuidv4(),
          title,
          message: message,
          type: type,
          lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
        };

        setData(prevState => [...prevState, newItem]);
      }
    },
    [setData]
  );

  const PushCustom = useCallback(
    (message: string | React.ReactNode, title?: string, lifetime?: number, icon?: string) => {
      if (message) {
        const new_item: Toast = {
          id: uuidv4(),
          title,
          message: message,
          lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
          icon: icon,
          type: undefined,
        };

        setData(prevState => [...prevState, new_item]);
      }
    },
    [setData]
  );

  const PushError = useCallback(
    (message: string, title?: string, lifetime?: number) => Push(message, 'Error', title, lifetime),
    [Push]
  );
  const PushWarning = useCallback(
    (message: string, title?: string, lifetime?: number) => Push(message, 'Warning', title, lifetime),
    [Push]
  );
  const PushSuccess = useCallback(
    (message: string, title?: string, lifetime?: number) => Push(message, 'Success', title, lifetime),
    [Push]
  );
  const PushInfo = useCallback(
    (message: string, title?: string, lifetime?: number) => Push(message, 'Info', title, lifetime),
    [Push]
  );

  const ToastContexd = useCallback(() => {
    return {
      data: data,
      pushError: PushError,
      pushWarning: PushWarning,
      pushSuccess: PushSuccess,
      pushInfo: PushInfo,
      push: Push,
      pushCustom: PushCustom,

      async remove(id: string) {
        setData(prevState => prevState.filter(e => e.id !== id));
      },
    };
  }, [data, setData, PushError, PushWarning, PushSuccess, PushInfo, Push, PushCustom]);

  return (
    <ToastContext.Provider value={ToastContexd()}>
      <ToastContainer variant={variant} />
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProviderProps, Toast, ToastContextType, ToastContext, useToast, ToastProvider };
