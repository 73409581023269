import { DefaultTheme, ThemeProvider } from 'styled-components';

import React from 'react';
import { ColorModes, ThemeProps } from './types';
import { lightColorAliases, mediaQueries, breakpoints, shadows, typography } from './designTokens';
import { spacings } from './spacing';

export const themeDefs: Record<ColorModes, DefaultTheme> = {
  light: {
    colors: {...lightColorAliases},
    spacings,
    responsive: mediaQueries,
    breakpoints,
    shadows,
    typography,
  },
  dark: {
    colors: {...lightColorAliases}, // Todo: set dark mode tokens
    spacings,
    responsive: mediaQueries,
    breakpoints,
    shadows,
    typography,
  },
};

export function Theme({ children, colorModes = 'light' }: ThemeProps) {
  return <ThemeProvider theme={themeDefs[colorModes]}>{children}</ThemeProvider>;
}
