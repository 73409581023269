import styled from 'styled-components';

const ToastsContainer = styled.div`
  width: auto;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 50;
  &.top_rigth {
    top: 0;
    right: 0;
  }
  &.top_left {
    top: 0;
    left: 0;
  }
  &.bottom_rigth {
    bottom: 0;
    right: 0;
  }
  &.bottom_left {
    bottom: 0;
    left: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;
  max-width: 480px;
  height: auto;
  margin: 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  border: none;
  box-shadow: 5px 5px 15px 8px rgba(108, 117, 125, 0.1);
  cursor: pointer;
`;
const ToastLeftColor = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 7px;
  border: none;
  border: none;
  box-shadow: 5px 5px 15px 8px rgba(108, 117, 125, 0.1);
  width: auto;
  margin: 15px;
  padding-left: 3px;
  background-color: ${({ color }) => color || '#f0323c'};
`;

const ToastContent = styled.section`
  width: 100%;
`;

const IconContainer = styled.div`
  background: #fdeaeb 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || '#FFFFFF'};
  background-color: ${({ background }) => background || '#3CD278'};
  flex-shrink: 0;

  & > svg {
    width: 20px;
    height: auto;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-left: 5px;
  & > svg {
    width: 16px;
    height: auto;
  }
  & > svg:hover {
    width: 18px;
  }
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
  margin: 0px;
`;

export { ToastsContainer, Container, IconContainer, CloseIcon, ToastLeftColor, ToastContent, Description, Title };
