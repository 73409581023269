import currency from './currency';

export enum CountryCode {
  CO = 'CO',
  MX = 'MX',
}
const getThousandsSeparator = (code: CountryCode) => code === 'CO' ? '.' : ',';

export const formatMoney = (amount: number, code: CountryCode) => {
  return new currency(amount, { formatWithSymbol: false, precision: 0, separator: getThousandsSeparator(code) }).format(false);
};
