import React from 'react';
import { Icon } from '../../../Icon';
import { Container, LeftButtons, HeaderOptions, Label } from './HeaderSimple.styled';

const HeaderSimple = ({ withBorderRadius = true, onButtonBack, maxWidth, height, children, isFixed, ...props }: HeaderOptions) => {
  return (
    <Container withBorderRadius={withBorderRadius} maxWidth={maxWidth} height={height} isFixed={isFixed} {...props}>
      {onButtonBack && !isFixed &&(
        <LeftButtons>
          <Icon icon="Arrow_Right" onClick={onButtonBack} block={false} />
        </LeftButtons>
      )}
      <Label>{children}</Label>
    </Container>
  );
};

export { HeaderSimple };
