import React, { createRef, FC } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Icon } from '../../Icon';
import { SIZES, VARIANTS } from '../../shared/types';
import { DropdownWrapper } from '../DropdownMenu/DropdownMenu.styled';
import { ArrowIcon, DropdownListContent, DropdownListItem, DropdownListTitle } from './DropdownSelectList.styled';

const List = (props: any) => {
  const { id, children, alwaysVisible, hidden, openOnHover, ...rest } = props;

  return (
    <DropdownListContent id={id} hidden={!alwaysVisible && hidden} openOnHover={openOnHover} {...rest}>
      {React.Children.map(children, (menuItem, index) => {
        if (!React.isValidElement(menuItem)) {
          return;
        }
        const itemId = `${id}-${index}`;
        return <React.Fragment key={itemId}>{menuItem}</React.Fragment>;
      })}
    </DropdownListContent>
  );
};

export type ListItem = {
  id: string;
  name: string;
  icon?: string;
};

type DropdownListProps = {
  items: Array<ListItem>;
  loginItem?: ListItem;
  size?: SIZES;
  variant?: VARIANTS;
  authenticated?: boolean;
  openOnHover?: boolean;
  fitWidth?: boolean;
  fitHeight?: boolean;
  selectedItem: ListItem;
  onlyLowerCase?: boolean;
  onSelect: (item: ListItem) => void;
};

const DropdownSelectList: FC<DropdownListProps> = ({
  size = SIZES.MEDIUM,
  variant = VARIANTS.PRIMARY,
  items = [],
  openOnHover = false,
  onlyLowerCase = true,
  selectedItem,
  onSelect,
  ...rest
}) => {
  const listRef = createRef();
  const { isListOpen, setIsListOpen } = useOutsideClick(listRef, false);

  return (
    <DropdownWrapper
      ref={listRef as React.RefObject<HTMLDivElement>}
      {...rest}
      onMouseEnter={() => openOnHover && setIsListOpen(true)}
      onMouseLeave={() => openOnHover && setIsListOpen(false)}
    >
      <DropdownListTitle
        onlyLowerCase={onlyLowerCase}
        variant={variant}
        size={size}
        onClick={() => setIsListOpen(!isListOpen)}
      >
        {selectedItem?.name || 'Selecciona una opción'}
        <ArrowIcon animation={isListOpen ? 'true' : 'false'}>
          <Icon icon={'caret'} />
        </ArrowIcon>
      </DropdownListTitle>
      <List openOnHover={openOnHover} hidden={!isListOpen} {...rest}>
        {items
          .filter(item => item.id !== selectedItem.id)
          .map((item, index) => (
            <DropdownListItem
              onlyLowerCase={onlyLowerCase}
              key={index}
              variant={variant}
              size={size}
              onClick={() => {
                onSelect(item);
                setIsListOpen(!isListOpen);
              }}
            >
              {item.icon && <Icon icon={item.icon} />}
              {item.name}
            </DropdownListItem>
          ))}
      </List>
    </DropdownWrapper>
  );
};

export { DropdownSelectList };
