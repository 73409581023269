import { useEffect, useState } from 'react';

type ResponsiveSizes = {
  isMobile: boolean;
  isMediumSize: boolean;
  isDesktop: boolean;
  isSmallDesktop: boolean;
  size: {
    width: number;
    height: number;
  };
};
export function useResponsive() {
  const [sizesValues, setSizesValues] = useState<ResponsiveSizes>({
    isMobile: false,
    isMediumSize: false,
    isDesktop: false,
    isSmallDesktop: false,
    size: {
      width: 0,
      height: 0,
    },
  });

  const handleWindowSizeChange = () => {
    setSizesValues(checkSizes());
  };

  const checkSizes = (): ResponsiveSizes => ({
    isMobile: window.innerWidth < 600,
    isMediumSize: window.innerWidth > 599 && window.innerWidth < 992,
    isDesktop: window.innerWidth > 991,
    isSmallDesktop: window.innerWidth > 991 && window.innerWidth < 1252,
    size: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { ...sizesValues };
}
