import { ButtonProps } from './../Buttons/Button.styled';
import React, { useRef } from 'react';
import { VARIANTS } from '../../shared';
import { FileUploaderButtonRoot, FileUploaderInputFileField } from './FileUploaderButton.styled';

type Props = {
  onFileAdded: (file: File) => void;
  accept: string[];
} & ButtonProps;

export const FileUploaderButton = ({ children, appearance, accept, onFileAdded, ...props }: Props) => {
  const fileUploadInput = useRef(null);

  const onSelectFile = e => {
    if (!!fileUploadInput && !!fileUploadInput.current && fileUploadInput.current.click) {
      fileUploadInput.current.click();
    }
  };

  const onInputFileChange = e => {
    const fileList = e.target.files as FileList;
    if (fileList.length > 0) {
      const files = Array.from(Array(fileList.length).keys()).map(idx => fileList.item(idx)!);
      onFileAdded(files.pop());
    }
  };

  return (
    <>
      <FileUploaderButtonRoot appearance={appearance || VARIANTS.SECONDARY_LIGHT} onClick={onSelectFile} {...props}>
        {children}
      </FileUploaderButtonRoot>
      <FileUploaderInputFileField
        type="file"
        ref={fileUploadInput}
        multiple={false}
        accept={accept.join(',')}
        onChange={onInputFileChange}
        onClick={e => (e.currentTarget.value = null)}
      />
    </>
  );
};
