import React, { useEffect } from "react";
import {
  MinusPlusButton,
  NumberInputWrapper,
  NumberInput
} from "./MinusPlus.styled";

const MinusPlus = ({ min, max, value, onChange, onBlur, ...rest }) => {
  const minValue = Number(min);
  const [val, setValue] = React.useState(minValue);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handlePlus = () => {
    const newVal = val + 1;
    setValue(newVal);
    onChange(newVal);
  }
  const handleMinus = () => {
    const newVal = val - 1;
    setValue(newVal);
    onChange(newVal);
  }

  return (
    <NumberInputWrapper>
      <MinusPlusButton
        onClick={handleMinus}
        tabIndex="-1"
        disabled={val === minValue}
      />
      <NumberInput
        min={min}
        max={max}
        readOnly
        value={val}
        onBlur={onBlur}
        {...rest}
      />
      <MinusPlusButton
        className="plus"
        onClick={handlePlus}
        tabIndex="-1"
        disabled={val === max}
      />
    </NumberInputWrapper>
  )
}

export { MinusPlus }
