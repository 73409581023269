import styled, { css } from 'styled-components';

const mainCardStyle = css`
  background-color: #0055fb;
  & > aside {
    color: white;
    position: absolute;
    bottom: 5px;
    padding: 0px 10px;
  }
  & > aside > p {
    margin-bottom: 4px;
    color: white;
  }
  & > aside > h6 {
    margin-bottom: 12px;
  }
`;

type CardProps = {
  primary?: boolean;
};

const Card = styled.button<CardProps>`
  display: flex;
  position: relative;
  flex: 0 0 auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #6c757d0f;
  border-radius: 16px;
  border-width: 0px;
  text-align: left;
  width: 152px;
  height: 158px;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 15px 30px #6c757d1f;
    width: 154px;
    height: 160px;
  }
  & > aside {
    color: black;
    position: absolute;
    bottom: 5px;
    padding: 0px 10px;
  }
  & > aside > p {
    margin-bottom: 4px;
    text-align: left;
    font: normal normal normal 12px/14px 'Satoshi';
    letter-spacing: 0px;
    color: #6c757d;
  }
  & > aside > h6 {
    margin: 0px;
  }
  ${props => props.primary && mainCardStyle}
`;

const CardWrapper = styled.div`
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  background-color: transparent;
  height: 200px;
  width: 100%;
  margin-bottom: 2%;
  padding: 0px 7%;
  @media screen and (max-width: 540px) {
    padding: 0px 5%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & + ${Card} {
    flex: 0 0 auto;
    margin-right: 3px;
  }
`;

const CardHorizontal = styled(Card)`
  width: 100%;
  max-height: 80px;
  align-items: center;

  & > aside {
    position: relative;
    bottom: 0px;
    align-self: center;
  }

  &:hover {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 248px;

    &:hover {
      width: 248px;
    }
  }
`;

export { Card, CardWrapper, CardHorizontal };
