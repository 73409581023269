import styled from 'styled-components';

type CarouselWrapperProps = {
  isMobile: boolean;
  isMediumSize: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CarouselCardsWrapper = styled.div<CarouselWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: ${({ isMobile }) => (isMobile ? '0' : '2rem')}; */
  padding: 0;
  background: transparent;
  max-width: 1200px;
  width: 100%;

  ${props =>
    props.isMobile &&
    `
      ${CarouselCardsHeader} {
        margin-bottom: 0;
      }
      ${SlideItem} {
        margin: 0;
      }
      ${CarouselCardsContent} {
        max-width: calc(100vw - 32px);
      }
    `}

  ${props =>
    props.isMediumSize &&
    `
      ${SlideItem} {
        margin: 0;        
      }
      ${CarouselCardsContent} {
        max-width: calc(100vw - 96px);
      }
    `}
`;

const CarouselCardsHeader = styled.div`
  font-size: ${({ theme }) => theme.typography.lineHeight.lg2}px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  align-items: center;
`;

const CarouselCardsTitle = styled.h2`
  font-weight: 900 !important;
  font-size: 1.5rem;
  font-size: ${({ theme }) => theme.typography.lineHeight.sm4}px !important;

  @media (min-width: 600px) {
    font-size: ${({ theme }) => theme.typography.lineHeight.md2}px !important;
  }
`;

const CarouselCardsControls = styled.div`
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95px;
`;

const CarouselCardsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SlideItem = styled.div`
  padding: 0.5rem;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export {
  CarouselCardsWrapper,
  CarouselCardsHeader,
  CarouselCardsTitle,
  CarouselCardsControls,
  CarouselCardsContent,
  SlideItem,
};
