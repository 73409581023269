import styled from 'styled-components';

export interface BoxActionSimpleProps {
  title?: string;
  iconBubbleSrc?: string;
  widthContainer?: string;
  heightContainer?: string;
  subtitle?: string;
  textButton?: string;
  iconButton?: string;
  textToCopy?: string;
  titleFontSize?: string;
  titleLineHeight?: string;
  titleMaxWidth?: string;
  onClickAptunoButton?: () => void;
  onClickCustom?: any;
}

export const Container = styled.div<BoxActionSimpleProps>`
  background-color: #ffffff;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06) button {
    width: auto;
    padding: 16px;
  }
  padding: 32px 24px 24px 24px;

  width: ${({ widthContainer }) => (widthContainer ? widthContainer : '280px')};
  height: ${({ heightContainer }) => (heightContainer ? heightContainer : 'auto')};

  button {
    font-size: 14px;
    padding: 12px 16px 12px 18px;
  }

  img {
    border-radius: 0 16px 0 0;
  }
`;

export const Title = styled.div<BoxActionSimpleProps>`
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.024em;
  margin-bottom: 16px;
  color: #000000;
  font-size: ${({ titleFontSize }) => (titleFontSize ? titleFontSize : '22px')};
  line-height: ${({ titleLineHeight }) => (titleLineHeight ? titleLineHeight : '30px')};
  max-width: ${({ titleMaxWidth }) => (titleMaxWidth ? titleMaxWidth : 'auto')};
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  color: #555a64;
`;

export const BubbleContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
`;
