import React from 'react';
import { Icon } from '../../../Icon';
import Select, { components } from 'react-select';
import { listInputSimpleProps } from './ListInputSimple.styled';

const ListInputSimple = ({
  optionsGroup,
  iconName = 'info',
  labelDefault = 'Seleccionar una ópcion',
  onSelectedTime,
  maxHeightMenu = '150px',
  isClearable = false,
  backgroundColor = '#F8F8F8',
  borderRadius = '8px',
  maxHeight = '48px',
  display = 'flex',
  flexDirection = 'row',
  alignItems = 'center',
  padding = '14px 0 12px 18px',
  border = '1px solid #0055FB',
  color = '#0055FB',
}: listInputSimpleProps) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: '1px solid #DEE2E6',
      margin: '0 15px 8px 15px',
      cursor: 'pointer',
      width: 'auto',
      boxSizing: 'border-box',
      borderRadius: '8px',
    }),
    control: (provided, state) => ({
      cursor: 'pointer',
      background: backgroundColor,
      borderRadius: borderRadius,
      maxHeight: maxHeight,
      display: display,
      flexDirection: flexDirection,
      alignItems: alignItems,
      padding: padding,
      border: state.isFocused && border,
      color: state.isFocused && color,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.isFocused ? 'rotate(180deg)' : null,
    }),
    menuList: (provided, state) => ({
      ...provided,
      background: 'transparent',
      maxHeight: maxHeightMenu,
    }),
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <Icon icon={iconName} />
        {children}
      </components.Control>
    );
  };

  return (
    <Select
      isClearable={isClearable}
      onChange={onSelectedTime}
      placeholder={labelDefault}
      options={optionsGroup}
      styles={customStyles}
      components={{
        Control,
      }}
    />
  );
};

export { ListInputSimple };
