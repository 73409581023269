import { CountryCode } from '../../../shared/formatMoney';
import styled from 'styled-components';

type DataTemplate = {
  color: string;
  value: number;
  key?: string | number;
  title?: string | number;
  [key: string]: any;
}[];

export type PieChartSimpleProps = {
  data: DataTemplate;
  lineWidth?: number;
  startAngle?: number;
  lengthAngle?: number;
  totalValue?: number;
  paddingAngle?: number;
  rounded?: boolean;
  segmentsShift?: number;
  segmentsStyle?;
  segmentsTabIndex?: number;
  label?;
  labelPosition?: number;
  labelStyle?;
  animate?: boolean;
  animationDuration?: number;
  animationEasing?: string;
  reveal?: number;
  background?: string;
  children?;
  radius?: number;
  center?;
  viewBoxSize?;
  onBlur?;
  onClick?;
  onFocus?;
  onKeyDown?;
  onMouseOut?;
  onMouseOver?;
  countryCode?: CountryCode;
};

type CircleColorProps = {
  backgroundColor: string;
};

type InfoDetailedProps = {
  display?: boolean;
};

export const ChartContainer = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  width: 100%;

  @media screen and (max-width: 700px) {
    display: inherit;
  }

  svg {
    min-width: 186px;
    max-width: 186px;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 700px) {
      min-width: 160px;
      max-width: 160px;
    }
  }
`;

export const ChartRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
`; 

export const PierChartBox = styled.div`
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
`;

export const SummaryValues = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;

export const ButtonExpander = styled.label<InfoDetailedProps>`
  top: 88px;
  rigth: 0px;
  width: fit-content;
  height: 28px;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 16px;
  text-align: center;
  display: flex;
  padding: 2px 8px 0px 8px;

  svg {
    width: fit-content;
    min-width: 0px;
    margin-left: 0;
    margin-top: 2px;
  }

  @media screen and (min-width: 700px) {
    margin-left: 28px;
    display: ${props => (props.display ? 'inline' : 'none')};
  }
`;

export const TextSummary = styled.div`
  height: 21px;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  width: max-content;
  color: #6c757d;
  margin-left: 12px;

  @media screen and (min-width: 700px) {
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const TextSum = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #000000;
`;

export const InfoDetailed = styled.div<InfoDetailedProps>`
  max-width: 500px;
  margin-left: 40px;
  height: 0px;
  overflow: hidden;
  transition: all 0.25s ease-in;
  height: ${props => (props.display ? '0px' : '200px')};
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 700px) {
    margin: 0;
  }
`;

export const ChartList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 22rem;
  gap: 12px 40px;
  width: 100%;
`;

export const ChartItem = styled.li`
  display: flex;
`;

export const Left = styled.div`
  padding-top: 8px;
`;

export const Right = styled.div`
  margin-left: 8px;
`;

export const CircleColor = styled.div<CircleColorProps>`
  height: 24px;
  width: 24px;
  background-color: ${props => props.backgroundColor};
  border-radius: 40px;
  display: inline-block;
`;

export const TextTitle = styled.h3`
  height: 21px;
  font-style: normal;
  font-size: 12px !important;
  line-height: 21px;
  font-weight: normal !important;
  width: max-content;
  color: #6c757d;

  @media screen and (min-width: 700px) {
    font-size: 14px !important;
  }
`;

export const TextValue = styled.h4`
  height: 21px;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

