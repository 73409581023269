import React, { useRef } from 'react';
import { toggleBodyScroll } from '../../shared/coolFunctions';
import { ESCAPE } from '../../shared/keys';
import { Portal } from '../Portal';
import { CloseModal, ModalBody, ModalContent, ModalFooter, ModalHead, ModalWrapper } from './Modal.styled';

export type ModalProps = {
  type?: any;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  isActive?: boolean;
  size?: number;
  bgColor?: string;
  width?: any;
  height?: any;
  isModalBottom?: boolean;
  onClose?: (event) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function Modal({
  type,
  header,
  children,
  footer,
  title,
  isActive,
  onClose,
  size,
  bgColor,
  width,
  height,
  isModalBottom = false,
  ...props
}: ModalProps) {
  React.useEffect(() => {
    toggleBodyScroll(!isActive);
    return () => toggleBodyScroll(true);
  }, [isActive]);

  const wrapperRef = useRef();

  const handleClose = event => {
    onClose(event);
  };

  const handleKeyUp = event => {
    if (event.key === ESCAPE) {
      handleClose(event);
    }
  };

  const handleClick = event => {
    if (event.target === wrapperRef.current) {
      handleClose(event);
    }
  };

  return (
    <Portal>
      <ModalWrapper ref={wrapperRef} onKeyUp={handleKeyUp} onClick={handleClick} {...props} isActive={isActive}>
        <ModalContent isModalBottom={isModalBottom} size={size} bgColor={bgColor} width={width} height={height}>
          <ModalHead isModalBottom={isModalBottom}>
            {!isModalBottom && (
              <CloseModal
                onClick={e => {
                  handleClose(e);
                  toggleBodyScroll(true);
                }}
              />
            )}
            {header}
          </ModalHead>
          <ModalBody>{children}</ModalBody>
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContent>
      </ModalWrapper>
    </Portal>
  );
}
