import React from 'react';
import { Icon } from '../../Icon';
import { useResponsive } from '../../hooks/useResponsive';
import { BoxInformativeProps, Container, Left, Right } from './BoxInformative.styled';

const BoxInformative = ({ appearance, children, refIcon, alignment }: BoxInformativeProps) => {
  const { isMobile } = useResponsive();

  return (
    <Container appearance={appearance} responsive={isMobile} alignment={alignment}>
      <Left>
        <Icon icon={refIcon} aria-label={refIcon} />
      </Left>
      <Right responsive={isMobile}>{children}</Right>
    </Container>
  );
};

export { BoxInformative };
