import { Icon } from "../../../Icon";
import React from "react";
import { IconContainer } from "../CardsCommon.styled";
import { CardHorizontal } from "./MenuCard.styled";

const MenuHorizotalCard = (
  { onClick, icon, iconColor, iconBackground, title, description, primary },
  ...rest
) => {
  return (
    <CardHorizontal onClick={onClick} primary={primary}>
      <IconContainer color={iconColor} backgroundColor={iconBackground}>
        <Icon icon={icon} />
      </IconContainer>
      <aside>
        <h6>{title}</h6>
        <p>{description}</p>
      </aside>
    </CardHorizontal>
  );
};
export { MenuHorizotalCard };
