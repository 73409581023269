import styled, { keyframes } from 'styled-components';

const modalEaseInRight = keyframes`
  from {
    transform: translateX(-450px);
    opacity: 0;
    
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;
const modalEaseInTop = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0.3;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const EaseInWrapper = styled.div`
  position: fixed;
  min-width: 320px;
  top: 0;
  left: 0;
  z-index: 100;
  width: 488px;
  height: 100%;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#F8F8F8')};
  animation: ${modalEaseInRight} 0.3s ease-in forwards;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
  }
`;

const EaseInWrapperMobile = styled(EaseInWrapper)`
  position: fixed;
  min-width: 320px;
  top: none;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  animation: ${modalEaseInTop} 0.3s ease-in forwards;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width:100vw;
  height: 100vh;
  z-index: 98;
  top:0;
  left:0;
  background: #0000008f;
`;

export { EaseInWrapper, EaseInWrapperMobile, Backdrop };
