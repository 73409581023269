import { Icon } from '../../../Icon';
import React from 'react';
import { ItemCardStyle, Title, Description } from './ItemCard.styled';
import { IconContainer } from '../CardsCommon.styled';

type ItemCardProps = {
  title: string;
  description: string;
  icon: string;
  iconColor?: string;
  iconBackground?: string;
  onClick: () => void;
};

function ItemCard({ title, description, icon, iconColor, iconBackground, onClick }: ItemCardProps) {
  return (
    <ItemCardStyle onClick={onClick}>
      <IconContainer color={iconColor} backgroundColor={iconBackground}>
        <Icon icon={icon} />
      </IconContainer>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
    </ItemCardStyle>
  );
}

export { ItemCard };
