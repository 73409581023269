import React, { useEffect, useState } from 'react';
import { AptunoButton } from '../../components/Buttons/Button';
import { Carousel } from '../../components/Carousel/Carousel';
import { Portal } from '../../components/Portal';
import { useResponsive } from '../../hooks/useResponsive';
import { Icon } from '../../Icon';
import { VARIANTS } from '../../shared/types';
import { ActionsSection, ImagesContainer, ZoomGalleryWrapper, ZoomImageWrapper } from './ZoomGallery.styled';

type ZoomGalleryProps = {
  isOpen: boolean;
  children: React.ReactNode;
  initialSlide?: number;
  onClose: () => void;
};
function ZoomGallery({ initialSlide = 1, isOpen = false, onClose, children, ...props }: ZoomGalleryProps) {
  const { isDesktop } = useResponsive();
  const [imageWidth, setImageWidth] = useState('90%');

  useEffect(() => {
    if (isDesktop) {
      setImageWidth('90%');
    } else {
      setImageWidth('100%');
    }
  }, [isDesktop]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', event => handleKeyDownPressed(event));
      return () => {
        window.removeEventListener('keydown', event => handleKeyDownPressed(event));
      };
    }
  }, []);

  const handleKeyDownPressed = (event: KeyboardEvent) => {
    if (event.code === 'Escape') onClose();
  };

  return (
    <Portal>
      <ZoomGalleryWrapper isOpen={isOpen} {...props}>
        <ActionsSection top={isDesktop ? '1rem' : '.5rem'} right={isDesktop ? '8.5rem' : '1rem'}>
          <AptunoButton withoutBorder fitWidth fitHeight appearance={VARIANTS.DARK} onClick={() => onClose()}>
            <Icon icon={'close'} />
            Cerrar
          </AptunoButton>
        </ActionsSection>
        <ImagesContainer>
          <Carousel isOpen={isOpen} initialSlide={initialSlide} margin={isDesktop ? '0 8.5rem' : '0'} isZoomMode={isDesktop}>
            {React.Children.map(children, slide => {
              if (!React.isValidElement(slide)) {
                return;
              }
              return <ZoomImageWrapper imageWidth={imageWidth}>{slide}</ZoomImageWrapper>;
            })}
          </Carousel>
        </ImagesContainer>
      </ZoomGalleryWrapper>
    </Portal>
  );
}

export { ZoomGallery };
