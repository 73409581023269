import React, { FC, useState } from 'react';
import { NavbarActionsWrapper, NavbarBrandWrapper, NavbarLinksWrapper, NavbarWrapper, MainLink } from './Navbar.styled';
import { useResponsive } from '../../hooks/useResponsive';
import { SideMenu } from './SideMenu';
import { BrandIcon } from '../../Icon';
import Burger from './Burger';

export type NavbarProps = {
  brand: React.ReactNode;
  links: React.ReactNode;
  mainlinks: React.ReactNode;
  actions: React.ReactNode;
  children?: React.ReactChildren;
};

function Navbar({ brand, links, mainlinks, actions, children }: NavbarProps) {
  const { isDesktop } = useResponsive();
  const [isSideMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {!isDesktop && (
        <SideMenu open={isSideMenuOpen} brandIcon={<BrandIcon />} onClose={() => setMenuOpen(false)}>
          {mainlinks}
          {links}
        </SideMenu>
      )}
      {!isDesktop && <Burger open={isSideMenuOpen} setOpen={setMenuOpen} aria-controls={'main-menu'} />}
      <NavbarWrapper>
        <NavbarBrandWrapper href={'/'} isMobile={!isDesktop}>
          {brand}
        </NavbarBrandWrapper>
        {isDesktop && (
          <>
            <NavbarLinksWrapper>
              {mainlinks && <MainLink>{mainlinks}</MainLink>}
              {links}
            </NavbarLinksWrapper>
            {children}
          </>
        )}
        <NavbarActionsWrapper>{actions}</NavbarActionsWrapper>
      </NavbarWrapper>
    </>
  );
};

export { Navbar };
