import React, { HtmlHTMLAttributes, InputHTMLAttributes, useEffect } from "react";
import {
  InputGroup,
  InputField,
  InputLabel,
  Check,
  CheckLabel,
  CheckRadio,
  CheckContainer,
  MinusPlusButton,
  NumberInputWrapper,
  NumberInput
} from "./Input.styled";

export type InputProps = {
  label?: React.ReactNode,
  width?: string, 
  height?: string,
  invalid?: boolean,
  onChange?: (val) => void,
  onBlur?:  (val) => void
} & InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef(
  function input({ width, height, label, id, invalid, ...props }: InputProps, ref) {
    return (
      <InputGroup width={width} height={height}>
        <InputField ref={ref} placeholder="&nbsp;" id={id} invalid={invalid} {...props} />
        <InputLabel htmlFor={id} >{label}</InputLabel>
      </InputGroup>
    );
  }
)

const RadioButton = React.forwardRef(
  function radioButton({ label, id, ...rest }: InputProps, ref) {
    return (
      <InputGroup>
        <CheckRadio ref={ref} id={id} {...rest} />
        <CheckLabel htmlFor={id}>{label}</CheckLabel>
      </InputGroup>
    );
  }
)

const CheckBox = React.forwardRef(
  function checkBox({ label, id, ...rest }: InputProps, ref) {
    return (
      <CheckContainer>
        <Check ref={ref} type="checkbox" id={id} {...rest} />
        <CheckLabel htmlFor={id}>{label}</CheckLabel>
      </CheckContainer>
    );
  }
);

const MinusPlusInput = ({ min, max, value, onChange, onBlur, ...rest }: InputProps) => {
  const minValue = Number(min);
  const [val, setValue] = React.useState(minValue);

  useEffect(() => {
    setValue(Number(value));
  }, [value]);

  const handlePlus = () => {
    const newVal = val + 1;
    setValue(newVal);
    onChange(newVal);
  }
  const handleMinus = () => {
    const newVal = val - 1;
    setValue(newVal);
    onChange(newVal);
  }

  return (
    <NumberInputWrapper>
      <MinusPlusButton
        onClick={handleMinus}
        tabIndex="-1"
        disabled={val === minValue}
      />
      <NumberInput
        type="number"
        min={min}
        max={max}
        readOnly
        value={val}
        onBlur={onBlur}
        {...rest}
      />
      <MinusPlusButton
        className="plus"
        onClick={handlePlus}
        tabIndex="-1"
        disabled={val === max}
      />
    </NumberInputWrapper>
  )
}

export {
  Input,
  RadioButton,
  CheckBox,
  MinusPlusInput
}
