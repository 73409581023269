import styled, { css } from 'styled-components';
import { SIZES, VARIANTS } from '../../shared/types';

enum Variants {
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
  WARNING = 'warning',
  WARNING_LIGHT = 'warningLight',
  DANGER = 'danger',
  SUCCESS = 'success',
  INFO = 'INFO',
}

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export type DropdownContentProps = {
  size?: SIZES;
  variant?: VARIANTS;
  itemBackgroundColor?: string;
  itemOnHoverBgColor?: string;
  itemOnHoverColor?: string;
  itemFontSize?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export type DropdownsProps = {
  openOnHover: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const getActiveColor = (variant: VARIANTS, colors: Record<string, string>, type: 'font' | 'background'): string => {
  if (type === 'background') {
    switch (variant) {
      case VARIANTS.PRIMARY:
        return colors.bgPrimaryActiveBtn;
      case VARIANTS.SECONDARY:
        return colors.bgSecondaryActiveBtn;
      case VARIANTS.DANGER:
        return colors.bgDangerActiveBtn;
      case VARIANTS.SUCCESS:
        return colors.bgSuccess;
      case VARIANTS.WARNING:
        return colors.bgWarning;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgLabelAdvertency;
      case VARIANTS.INFO:
        return colors.bgInfo;
      default:
        return colors.textSecondary;
    }
  } else {
    switch (variant) {
      case VARIANTS.SECONDARY:
        return colors.textSecondary;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgWarning;
      default:
        return colors.textPrimary;
    }
  }
};

const dropdownItemActive = props =>
  css`
    text-decoration: none;
    color: ${getActiveColor(props.variant, props.theme.colors, 'font')};
    background-color: ${getActiveColor(props.variant, props.theme.colors, 'background')};
    opacity: ${props.variant == VARIANTS.WARNING_LIGHT ? 1 : 0.5};
  `;

const dropdownItemDisabled = props =>
  props.disabled &&
  css`
    text-decoration: none;
    color: ${props.theme.colors.bgDisabled};
    background-color: transparent;
  `;

const getColor = (variant: VARIANTS, colors: Record<string, string>, type: 'font' | 'background'): string => {
  if (type === 'font') {
    switch (variant) {
      case VARIANTS.PRIMARY:
        return colors.bgPrimary;
      case VARIANTS.SECONDARY:
        return colors.textSecondaryLightBtn;
      case VARIANTS.DANGER:
        return colors.bgDanger;
      case VARIANTS.SUCCESS:
        return colors.bgSuccess;
      case VARIANTS.WARNING:
        return colors.bgWarning;
      case VARIANTS.WARNING_LIGHT:
        return colors.bgWarning;
      case VARIANTS.INFO:
        return colors.bgInfo;
      default:
        return colors.textSecondary;
    }
  } else {
    return colors.bgSecondary;
  }
};

const getPadding = (size: SIZES, paddings: Record<string, number>): string => {
  return '12px 16px';
};
const getFontWeight = (size: SIZES, fontWeights: Record<string, string>): number => {
  return 600;
};
const getFontSize = (size: SIZES, fontSizes: Record<string, string>): string => {
  return '12px';
};

const isLeftOrRight = props =>
  props.right
    ? css`
        right: 0;
      `
    : css`
        left: 0;
      `;

const dropdownMenuHidden = props =>
  props.hidden
    ? css`
        display: none;
      `
    : css`
        display: block;
      `;

const isFullWidth = props =>
  props.fullWidth
    ? css`
        min-width: 100%;
      `
    : null;

const DropdownItem = styled.div<DropdownContentProps>`
  padding: ${({ size, theme }) => getPadding(size, theme.spacings)};
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  display: block;
  color: ${props => getColor(props.variant, props.theme.colors, 'font')};

  ${props => props.itemFontSize && `font-size: ${props.itemFontSize} !important;`};
  ${props => props.itemBackgroundColor && `background-color: ${props.itemBackgroundColor};!important`};
  ${props =>
    props.itemOnHoverBgColor &&
    `
    &:hover,
    &:focus,
    &:active  { 
      background-color: ${props.itemOnHoverBgColor}!important; 
    }`};
  ${props =>
    props.itemOnHoverColor &&
    `
    &:hover,
    &:focus,
    &:active  { 
      color: ${props.itemOnHoverColor}!important; 
      opacity: 1!important;
    }`};
  svg {
    margin-right: 0.75rem;
  }
  &:hover,
  &:focus,
  &:active {
    ${props => dropdownItemActive(props)};
  }
  ${props => dropdownItemDisabled(props)};
`;

const DropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  top: 100%;
  z-index: 99;
  float: left;
  min-width: 10rem;
  padding: ${props => ''};
  font-size: ${props => getFontSize(props.size, props.theme.typography.size)};
  color: ${props => getColor(props.variant, props.theme.colors, 'font')};
  text-align: left;
  list-style: none;
  background-color: ${props => getColor(props.variant, props.theme.colors, 'background')};
  background-clip: padding-box;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1), 0 3px 8px rgba(0, 0, 0, 0.16);
  z-index: 9;
  margin-top: 1px;

  ${props => dropdownMenuHidden(props)};
  ${props => isLeftOrRight(props)};
  ${props => isFullWidth(props)};
`;

const DropdownWrapper = styled.div`
  padding: 0;
  position: relative;
  display: inline-block;
  border-radius: 8px;
  cursor: pointer;
`;

const LogoutButton = styled.div`
  border-top: 0.5px solid #bbb;
`;

export { Dropdown, DropdownItem, DropdownWrapper, DropdownContent, LogoutButton };
