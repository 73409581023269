import { Hexcode } from '../../shared/types';
import styled from 'styled-components';


type AlertProps = {
  color: Hexcode;
  iconColor: Hexcode;
}
const AlertStyle = styled.div<AlertProps>`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  background: ${props => props.color || '#e6edff'};
  border-radius: 8px;
  width: 100%;

  & > svg {
    color: ${props => props.iconColor || '#0055FB'};
    margin-right: 10px;
    width: 40px;
    height: auto;
  }
`;

export { AlertStyle };
