import React from "react";
import { TextInputGroup, TextInputLabel, TextInputField } from './TextInput.styled'

const TextInput = React.forwardRef(function input(
  { label, id, color, width, fontSizeText, colorLabel, fontSizeLabel, colorBorder, ...attrs },
  ref
) {
  return (
    <TextInputGroup>
      <TextInputField
        ref={ref}
        color={color}
        width={width}
        fontSizeText={fontSizeText}
        placeholder="&nbsp;"
        id={id}
        colorBorder={colorBorder}
        {...attrs}
      />
      <TextInputLabel htmlFor={id} colorLabel={colorLabel} fontSizeLabel={fontSizeLabel}>
        {label}
      </TextInputLabel>
    </TextInputGroup>
  );
});

export { TextInput }
